import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { InfoCard } from 'components'
import useI18n from 'common-hooks/useI18n'
import { GENDERS } from 'components/form-elements/Genders';
import { LANGUAGES } from 'components/form-elements/Languages';
import dayjs from 'dayjs';
import { selectStores } from 'appSlice';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { selectUser } from 'features/login/loginSlice';
import useStore from 'common-hooks/useStore';
import cx from 'classnames'
import styles from './CustomerFields.module.scss'

function CustomerInfoCards({ data, loading }) {
  const { i18n } = useI18n();
  const { name: staffName } = useSelector(selectUser);
  const { label: storeName, city, country } = useStore();
  const { state } = useLocation();
  const stores = useSelector(selectStores);
  const navigate = useNavigate();

  const customer = useMemo(() => {
    return {
      [i18n('Customer Name')]: [data.firstName, data.lastName].filter(Boolean).join(' '),
      [i18n('Email ID')]: data.email,
      [i18n('Mobile')]: data.phone,
      [i18n('Gender')]: GENDERS(i18n).find(x => x.value === data.gender)?.label,
      [i18n('Date of Birth')]: data.dateOfBirth ? dayjs(data.dateOfBirth).format('D MMMM YYYY') : '',
      [i18n('Language')]: LANGUAGES(i18n).find(x => x.value === data.language)?.label,
      'Customer Id': data.id,
      ...state?.appointmentId ? { [i18n('Appointment ID')]: state.appointmentId } : {},
    }
  }, [data, i18n]);

  const defaults = useMemo(() => {
    return {
      [i18n('Store Name')]: storeName,
      [i18n('Staff Name')]: staffName,
      [i18n('City')]: city,
      [i18n('Country')]: country
    }
  }, [storeName, staffName, i18n, stores])

  return (
    <div className={cx(styles.customer)}>
      <InfoCard auto loading={loading} title={i18n('Customer Detail')} data={customer} cta={i18n('Edit')} onCtaClick={() => navigate(`/customers/${data.id}/edit/info`)} />
      <InfoCard auto title={i18n('Store Details')} data={defaults} canBeNull />
    </div>
  )
}

CustomerInfoCards.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool
}

export default CustomerInfoCards
