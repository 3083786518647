import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './FormElements.module.scss'

const TICK = <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M11.8047 0.195262C12.0651 0.455612 12.0651 0.877722 11.8047 1.13807L4.4714 8.47141C4.21106 8.73175 3.78894 8.73175 3.5286 8.47141L0.195262 5.13807C-0.0650874 4.87772 -0.0650874 4.45561 0.195262 4.19526C0.455612 3.93491 0.877722 3.93491 1.13807 4.19526L4 7.05719L10.8619 0.195262C11.1223 -0.0650874 11.5444 -0.0650874 11.8047 0.195262Z" fill="#8698B7"/> </svg>

function Checkbox({ title, label, className, error, name, value, checked, disabled, onChange }) {
  const handleChange = e => {
    onChange(e.target.checked ? e.target.value : '');
  }

  return (
    <div className={cx(styles.field, styles.checkbox,  className, { [styles.error]: error, [styles.disabled]: disabled })}>
      {!!title && <h3 className={cx(styles.label)}>{title}</h3>}
      <label className={cx(styles['checkbox-option'])}>
        <input checked={checked} disabled={disabled} type="checkbox" onChange={handleChange} value={value} className={cx(styles.radio)} name={name} />
        <div className={cx(styles['radio-option-inner'])}>
          <span className={cx(styles['radio-label'])}>{label}</span>
          {TICK}
        </div>
      </label>
      {error && <small className={cx(styles.message)}>{error}</small>}
    </div>
  )
}

Checkbox.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  defaultChecked: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func
}

export default Checkbox
