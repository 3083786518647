import useI18n from 'common-hooks/useI18n';
import { Button, Layout } from 'components';
import React, { useMemo, useState } from 'react';
import styles from './SwtichStore.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, updateLoginData } from 'features/login/loginSlice';
import cx from 'classnames';
import locationIcon from 'components/user-stores/location.png'
import { useLazySwitchStoreQuery } from 'app/service';

function SwitchStore() {
    const { i18n } = useI18n();
    const { warehouses } = useSelector(selectUser);
    const dispatch = useDispatch();
    const [switchStore] = useLazySwitchStoreQuery();
    const [term, setTerm] = useState('');
    const stores = useMemo(() => {
        const termLowerCase = term.toLowerCase();
        return warehouses?.filter?.(x => x.name.toLowerCase().includes(termLowerCase) || x.storeCode.toLowerCase() === termLowerCase);
    }, [term])

    const handleStoreChange = async (store) => {
        const res = await switchStore(store.id);
        dispatch(updateLoginData(res?.data));
        window.location.href = '/';
    }

    return (
        <Layout title={i18n('Select store')}>
            <div className={cx(styles.wrapper)}>
                <h2 className={cx(styles.title)}>{i18n('The stores you work in')}</h2>
                <input className={cx(styles.search)} type="search" placeholder={i18n('Search')} value={term} onChange={e => setTerm(e.target.value)} />
                <ul className={cx(styles.list)}>
                    {stores.map(store => (
                        <li className={cx(styles.store)} key={store.storeCode}>
                            {/* {defaultStoreId === store.value && <span className={cx(styles.default)}>{i18n('Current')}</span>} */}
                            <img src={locationIcon} alt="" className={cx(styles.icon)} />
                            <h3 className={cx(styles.name)}>{store.name}</h3>
                            <h4 className={cx(styles.city)}>{store.city}</h4>
                            <Button onClick={() => handleStoreChange(store)} className={cx(styles.cta)}>{i18n('Switch')}</Button>
                        </li>
                    ))}
                </ul>
            </div>
        </Layout>
    )
}

export default SwitchStore;