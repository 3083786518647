import { useSelector } from 'react-redux';
import { selectStores } from 'src/appSlice';
import useI18n from './useI18n';

export const LENS_TYPES = {
  SINGLE_VISION: 'Single Vision',
  PLAN_O: 'Plano',
  PROGRESSIVE: 'Progressive',
  PRESCRIPTION: 'Contact Lens',
};

export const COUNTRIES = [
  { code: 'ae', label: 'United Arab Emirates', isd: '971' },
  { code: 'sa', label: 'Saudi Arabia', isd: '966' },
  { code: 'bh', label: 'Bahrain', isd: '973' },
  { code: 'kw', label: 'Kuwait', isd: '965' },
  { code: 'om', label: 'Oman', isd: '968' }
];

export const CORRIDOR = {
  EXTRA_SHORT: 'Extra short',
  SHORT: 'Short',
  MEDIUM: 'Medium',
  LARGE: 'Large',
}

const ADDON_4_SINGLEVISION = ['ZEISS 1.5 Blue Protect (BL)', 'ZEISS 1.5 LotuTec (STD)', 'ZEISS 1.5 PhotoFusion (TR)', 'OL.ZEISS 1.6 Blue Protect (BL)', 'OL.ZEISS 1.6 LotuTec (STD)', 'OL.ZEISS AS 1.67 LotuTec (STD)', 'OL.ZEISS 1.5 DriveSafe', 'OL.ZEISS 1.6 DriveSafe', 'OL.RX.ZEISS 1.5 (STD)', 'OL.RX.ZEISS 1.5 (BL)', 'OL.RX.ZEISS 1.5 PhotoFusion (TR,BL)', 'OL.RX.ZEISS 1.5 PhotoFusion (TR,STD)', 'OL.RX.ZEISS 1.53 Trivex (STD,RIMLESS)', 'OL.RX.ZEISS 1.53 Trivex (BL,RIMLESS)', 'OL.RX.ZEISS 1.6 (BL)', 'OL.RX.ZEISS 1.6 (STD)'];
const ADDON_4_PROGRESSIVE = ['ZEISS 1.5 (STD)', 'ZEISS PhotoFusion 1.5 (TR)', 'OL.RX.ZEISS D Light 1.6 (STD)', 'OL.RX.ZEISS D Light 1.6 PhotoFusion (TR)', 'OL.RX.ZEISS D Light 1.67 (STD)', 'OL.RX.ZEISS D Light 1.67 PhotoFusion (TR)', 'ZEISS 1.5 (BL)', 'ZEISS PhotoFusion 1.5 (BL,TR)', 'OL.RX.ZEISS D Light 1.6 (BL)', 'OL.RX.ZEISS D Light 1.6 PhotoFusion (BL,TR)', 'OL.RX.ZEISS D Light 1.67 (BL)', 'OL.RX.ZEISS D Light 1.67 PhotoFusion (BL,TR)', 'OL.RX.ZEISS Precision Pure 1.5 (STD)', 'OL.RX.ZEISS Precision Pure 1.5 PhotoFusion (TR)', 'OL.RX.ZEISS Precision Pure 1.53 Trivex (STD,RIMLESS)', 'OL.RX.ZEISS Precision Pure 1.6 (STD)', 'OL.RX.ZEISS Precision Pure 1.6 PhotoFusion (TR)', 'OL.RX.ZEISS Precision Pure 1.67 (STD)', 'OL.RX.ZEISS Precision Pure 1.67 PhotoFusion (TR)', 'OL.RX.ZEISS Precision Pure 1.74 (STD)', 'OL.RX.ZEISS Precision Pure 1.5 (BL)', 'OL.RX.ZEISS Precision Pure 1.5 PhotoFusion (BL,TR)', 'OL.RX.ZEISS Precision Pure 1.53 Trivex (BL,RIMLESS)', 'OL.RX.ZEISS Precision Pure 1.6 (BL)', 'OL.RX.ZEISS Precision Pure 1.6 PhotoFusion (BL,TR)', 'OL.RX.ZEISS Precision Pure 1.67 (BL)', 'OL.RX.ZEISS Precision Pure 1.67 PhotoFusion (BL,TR)', 'OL.RX.ZEISS Precision Pure 1.74 (BL)'];

export const validatePhone = (value, country, i18n) => {
  switch (country) {
    case 'sa':
    case 'ae': {
      return value?.length === 9
        ? true
        : i18n('please specify a valid phone number and should be {length} digit number.', { length: 9 })
    }
    case 'kw':
    case 'om':
    case 'bh':
    case 'qa': {
      return value?.length === 8
        ? true
        : i18n('please specify a valid phone number and should be {length} digit number.', { length: 8 })
    }
    default: {
      if (value?.length < 6) {
        return i18n('The phone must be at least 6 characters')
      }
      if (value?.length > 11) {
        return i18n('The phone must be at most 11 characters')
      }
      return true
    }
  }
}

export const useValidation = () => {
  const { i18n } = useI18n();
  const stores = useSelector(selectStores);

  const validations = {
    name: {
      type: 'text',
      label: i18n('Name'),
      placeholder: i18n('Name'),
      rule: {
        required: i18n('Please enter name'),
        minLength: { value: 2, message: i18n('Name must be at least 2 characters') },
        maxLength: { value: 50, message: i18n('Name must be at most 50 characters') },
        pattern: {
          value: /^[a-zA-Z\u0621-\u064A\s-_]+$/,
          message: i18n('The name can only contain letters (a-z or A-Z ) and not numbers'),
        },
      },
    },
    staffName: {
      label: i18n('Staff Name'),
      placeholder: i18n('Staff Name'),
      rule: {
        minLength: { value: 2, message: i18n('Name must be at least 2 characters') },
        maxLength: { value: 50, message: i18n('Name must be at most 50 characters') },
        pattern: {
          value: /^[a-zA-Z\u0621-\u064A\s-_]+$/,
          message: i18n('The name can only contain letters (a-z or A-Z ) and not numbers'),
        },
      },
    },
    staffNameWithOptions: {
      label: i18n('Staff Name'),
      options: [
        { label: i18n('Select'), value: '' },
      ],
    },
    firstName: {
      type: 'text',
      label: i18n('First Name'),
      placeholder: i18n('First Name'),
      rule: {
        required: i18n('The first name field is required'),
        minLength: { value: 2, message: i18n('The first name must be at least 2 characters') },
        maxLength: { value: 50, message: i18n('The first name must be at most 50 characters') },
        pattern: {
          value: /^[a-zA-Z\u0621-\u064A\s-_]+$/,
          message: i18n('The first name can only contain letters (a-z or A-Z ) and not numbers'),
        },
      },
    },
    lastName: {
      type: 'text',
      label: i18n('Last Name'),
      placeholder: i18n('Last Name'),
      rule: {
        required: i18n('The last name field is required'),
        minLength: { value: 2, message: i18n('The first name must be at least 2 characters') },
        maxLength: { value: 50, message: i18n('The first name must be at most 50 characters') },
        pattern: {
          value: /^[a-zA-Z\u0621-\u064A\s-_]+$/,
          message: i18n('The first name can only contain letters (a-z or A-Z ) and not numbers'),
        },
      },
    },
    comment: {
      type: 'text',
      label: i18n('comments'),
      placeholder: i18n('comments')
    },
    email: {
      type: 'email',
      label: i18n('Email'),
      placeholder: i18n('Email'),
      rule: {
        required: i18n('Enter an email address'),
        pattern: {
          value:
            /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: i18n('Please enter a valid email address(ex. johndoe@domain.com)'),
        },
      },
    },
    password: {
      type: 'password',
      label: i18n('Password'),
      placeholder: i18n('Password'),
      rule: {
        required: i18n('The password field is required'),
        minLength: {
          value: 6,
          message: i18n(
            'Password should contain at least 6 characters without any leading or trailing spaces'
          ),
        },
      },
    },
    currentPassword: {
      type: 'password',
      label: i18n('Current Password'),
      placeholder: i18n('Current Password'),
      rule: {
        required: i18n('The current password field is required'),
        minLength: {
          value: 6,
          message: i18n(
            'The current password should contain at least 6 characters without any leading or trailing spaces'
          ),
        },
      },
    },
    newPassword: {
      type: 'password',
      label: i18n('New Password'),
      placeholder: i18n('New Password'),
      rule: {
        required: i18n('The new password field is required'),
        minLength: {
          value: 6,
          message: i18n(
            'Password should contain at least 6 characters without any leading or trailing spaces'
          ),
        },
      },
    },
    confirmNewPassword: {
      type: 'password',
      label: i18n('Confirm New Password'),
      placeholder: i18n('Confirm New Password'),
      rule: {
        required: i18n('The confirm password field is required'),
        minLength: {
          value: 6,
          message: i18n(
            'Password should contain at least 6 characters without any leading or trailing spaces'
          ),
        },
      },
    },
    streetName: {
      rule: {
        required: i18n('The street field is required'),
      },
    },
    language: {
      label: i18n('Language'),
      options: [
        { label: i18n('Language'), value: '' },
        { label: i18n('English'), value: 'en' },
        { label: i18n('Arabic'), value: 'ar' }
      ],
      rule: {
        required: i18n('Language is required'),
      },
    },
    gender: {
      label: i18n('Gender'),
      options: [
        { label: i18n('Gender'), value: '' },
        { label: i18n('Male'), value: 'male' },
        { label: i18n('Female'), value: 'female' }
      ],
      rule: {
        required: i18n('Gender is required'),
      },
    },
    age: {
      type: 'number',
      label: i18n('Age'),
      placeholder: i18n('Age')
    },
    city: {
      rule: {
        required: i18n('The city field is required'),
        pattern: {
          value: /^[a-zA-Z\u0621-\u064A\d\s-_]+$/,
          message: i18n(
            'The city field can only contain letters, full-stop and dash or hyphen (a-z A-Z . -)'
          ),
        },
      },
    },
    region: {
      type: 'text',
      label: i18n('State'),
      placeholder: i18n('State'),
      rule: {
        required: i18n('The region field is required'),
      },
    },
    regionId: {
      rule: {
        required: i18n('The region field is required'),
      },
    },
    postCode: {
      rule: {
        required: i18n('The postal code field is required'),
      },
    },
    country: {
      label: i18n('Country'),
      placeholder: i18n('Country'),
      options: COUNTRIES.map(country => ({ value: country.code, label: i18n(country.label) })),
      rule: {
        required: i18n('Country is required'),
      },
    },
    phone: {
      rule: {
        required: i18n('The phone field is required'),
        pattern: {
          value: /^[\d]+$/,
          message: i18n('Please use only numbers E.g. 5XXXXXXXX'),
        },
      },
    },
    collectionPointId: {
      rule: {
        required: i18n('Please select a pick-up location from the dropdown or from the Map'),
      },
    },
    lensTypeWithNoPlano: {
      label: i18n('Lens Type'),
      options: [
        { label: i18n('Lens Type'), value: '' },
        { label: i18n(LENS_TYPES.SINGLE_VISION), value: LENS_TYPES.SINGLE_VISION },
        { label: i18n(LENS_TYPES.PROGRESSIVE), value: LENS_TYPES.PROGRESSIVE },
        { label: i18n(LENS_TYPES.PRESCRIPTION), value: LENS_TYPES.PRESCRIPTION }
      ],
      rule: {
        required: i18n('Lens Type is required'),
      },
    },
    lensType: {
      label: i18n('Lens Type'),
      options: [
        { label: i18n('Select'), value: '' },
        { label: i18n(LENS_TYPES.SINGLE_VISION), value: LENS_TYPES.SINGLE_VISION },
        { label: i18n(LENS_TYPES.PLAN_O), value: LENS_TYPES.PLAN_O },
        { label: i18n(LENS_TYPES.PROGRESSIVE), value: LENS_TYPES.PROGRESSIVE },
      ],
      rule: {
        required: i18n('Lens Type is required'),
      },
    },
    storeName: {
      label: i18n('Store'),
      options: stores,
      rule: {
        required: i18n('Please select a store'),
      },
    },
    addOn1: {
      label: i18n('Blue Light'),
      options: [
        { label: i18n('Select Blue Light'), value: '' },
        { label: i18n('Blue Light'), value: i18n('BL') },
      ],
      rule: {},
    },
    addOn2: {
      label: i18n('Photochromic'),
      options: [
        { label: i18n('Select Photochromic'), value: '' },
        { label: i18n('Photochromic'), value: i18n('TR') },
      ],
      rule: {},
    },
    addOn3: {
      label: i18n('Tinting'),
      options: [
        { label: i18n('Select Tinting'), value: '' },
        { label: i18n('Tinting'), value: 'TN' },
      ],
      rule: {},
    },
    addOn4Singlevision: {
      label: i18n('Tinting'),
      options: [
        { label: i18n('Select Zeiss Add on'), value: '' },
        ...ADDON_4_SINGLEVISION.map(x => ({ label: x, value: x })),
      ],
      rule: {},
    },
    addOn4Progressive: {
      label: i18n('Tinting'),
      options: [
        { label: i18n('Select Zeiss Add on'), value: '' },
        ...ADDON_4_PROGRESSIVE.map(x => ({ label: x, value: x })),
      ],
      rule: {},
    },
    addOnColor2: {
      label: i18n('Addon Color*'),
      options: [
        { label: i18n('Addon Color'), value: '' },
        { label: i18n('Grey'), value: 'Grey' },
        { label: i18n('Green'), value: 'Green' },
        { label: i18n('Brown'), value: 'Brown' },
      ],
      rule: {},
    },
    addOnColor3: {
      label: i18n('Addon Color*'),
      options: [
        { label: i18n('Addon Color'), value: '' },
        { label: i18n('As Sample'), value: 'As Sample' },
        { label: i18n('Grey'), value: 'Grey' },
        { label: i18n('Black'), value: 'Black' },
        { label: i18n('Brown'), value: 'Brown' },
        { label: i18n('Blue'), value: 'Blue' },
        { label: i18n('Green'), value: 'Green' },
        { label: i18n('Silver'), value: 'Silver' },
        { label: i18n('Gold'), value: 'Gold' },
        { label: i18n('Orange'), value: 'Orange' },
        { label: i18n('Gradient Grey'), value: 'Gradient Grey' },
        { label: i18n('Gradient Black'), value: 'Gradient Black' },
        { label: i18n('Gradient Brown'), value: 'Gradient Brown' },
        { label: i18n('Gradient Blue'), value: 'Gradient Blue' },
        { label: i18n('Gradient Green'), value: 'Gradient Green' },
        { label: i18n('Gradient Silver'), value: 'Gradient Silver' },
        { label: i18n('Gradient Gold'), value: 'Gradient Gold' },
        { label: i18n('Gradient Orange'), value: 'Gradient Orange' },
        { label: i18n('Yellow Glow Light 25%'), value: 'Yellow Glow Light 25%' },
        { label: i18n('Orange Zest Light 25%'), value: 'Orange Zest Light 25%' },
        { label: i18n('Pink Pastel Light 25%'), value: 'Pink Pastel Light 25%' },
        { label: i18n('Purple Bloom Light 25%'), value: 'Purple Bloom Light 25%' },
        { label: i18n('Sapphire Blue Light 25%'), value: 'Sapphire Blue Light 25%' },
        { label: i18n('Green Meadow Light 25%'), value: 'Green Meadow Light 25%' },
        { label: i18n('Mocha Brown Light 25%'), value: 'Mocha Brown Light 25%' },
        { label: i18n('Slate Grey Light 25%'), value: 'Slate Grey Light 25%' },
        { label: i18n('Yellow Glow Medium 50%'), value: 'Yellow Glow Medium 50%' },
        { label: i18n('Orange Zest Medium 50%'), value: 'Orange Zest Medium 50%' },
        { label: i18n('Pink Pastel Medium 50%'), value: 'Pink Pastel Medium 50%' },
        { label: i18n('Purple Bloom Medium 50%'), value: 'Purple Bloom Medium 50%' },
        { label: i18n('Sapphire Blue Medium 50%'), value: 'Sapphire Blue Medium 50%' },
        { label: i18n('Green Meadow Medium 50%'), value: 'Green Meadow Medium 50%' },
        { label: i18n('Mocha Brown Medium 50%'), value: 'Mocha Brown Medium 50%' },
        { label: i18n('Slate Grey Medium 50%'), value: 'Slate Grey Medium 50%' },
        { label: i18n('Yellow Glow Dark 80%'), value: 'Yellow Glow Dark 80%' },
        { label: i18n('Orange Zest Dark 80%'), value: 'Orange Zest Dark 80%' },
        { label: i18n('Pink Pastel Dark 80%'), value: 'Pink Pastel Dark 80%' },
        { label: i18n('Purple Bloom Dark 80%'), value: 'Purple Bloom Dark 80%' },
        { label: i18n('Sapphire Blue Dark 80%'), value: 'Sapphire Blue Dark 80%' },
        { label: i18n('Green Meadow Dark 80%'), value: 'Green Meadow Dark 80%' },
        { label: i18n('Mocha Brown Dark 80%'), value: 'Mocha Brown Dark 80%' },
        { label: i18n('Slate Grey Dark 80%'), value: 'Slate Grey Dark 80%' },
        { label: i18n('Yellow Glow Gradient 50% - 25%'), value: 'Yellow Glow Gradient 50% - 25%' },
        { label: i18n('Orange Zest Gradient 50% - 25%'), value: 'Orange Zest Gradient 50% - 25%' },
        { label: i18n('Pink Pastel Gradient 50% - 25%'), value: 'Pink Pastel Gradient 50% - 25%' },
        { label: i18n('Purple Bloom Gradient 50% - 25%'), value: 'Purple Bloom Gradient 50% - 25%' },
        { label: i18n('Sapphire Blue Gradient 50% - 25%'), value: 'Sapphire Blue Gradient 50% - 25%' },
        { label: i18n('Green Meadow Gradient 50% - 25%'), value: 'Green Meadow Gradient 50% - 25%' },
        { label: i18n('Mocha Brown Gradient 50% - 25%'), value: 'Mocha Brown Gradient 50% - 25%' },
        { label: i18n('Slate Grey Gradient 50% - 25%'), value: 'Slate Grey Gradient 50% - 25%' },
        { label: i18n('Yellow Glow Gradient 80% - 25%'), value: 'Yellow Glow Gradient 80% - 25%' },
        { label: i18n('Orange Zest Gradient 80% - 25%'), value: 'Orange Zest Gradient 80% - 25%' },
        { label: i18n('Pink Pastel Gradient 80% - 25%'), value: 'Pink Pastel Gradient 80% - 25%' },
        { label: i18n('Purple Bloom Gradient 80% - 25%'), value: 'Purple Bloom Gradient 80% - 25%' },
        { label: i18n('Sapphire Blue Gradient 80% - 25%'), value: 'Sapphire Blue Gradient 80% - 25%' },
        { label: i18n('Green Meadow Gradient 80% - 25%'), value: 'Green Meadow Gradient 80% - 25%' },
        { label: i18n('Mocha Brown Gradient 80% - 25%'), value: 'Mocha Brown Gradient 80% - 25%' },
        { label: i18n('Slate Grey Gradient 80% - 25%'), value: 'Slate Grey Gradient 80% - 25%' }
      ],
      rule: {},
    },
    addOnPackage: {
      label: i18n('Select Package'),
      options: [
        { label: i18n('Standard'), value: 'Standard' },
        { label: i18n('Premier'), value: 'Premier' },
        { label: i18n('Elite'), value: 'Elite' },
        { label: i18n('Ultra'), value: 'Ultra' },
      ],
      rule: {},
    },
    index: {
      label: i18n('Index'),
      options: [
        { label: i18n('1.5'), value: '1.5' },
        { label: i18n('1.53'), value: '1.53' },
        { label: i18n('1.56'), value: '1.56' },
        { label: i18n('1.57'), value: '1.57' },
        { label: i18n('1.59'), value: '1.59' },
        { label: i18n('1.60'), value: '1.60' },
        { label: i18n('1.60 Hivex'), value: '1.60 Hivex' },
        { label: i18n('1.67'), value: '1.67' },
        { label: i18n('1.74'), value: '1.74' },
        { label: i18n('1.76'), value: '1.76' },
      ],
      rule: {},
    },
    lensDiameter: {
      label: i18n('Lens Diameter'),
      options: [
        { label: i18n('65'), value: '65' },
        { label: i18n('70'), value: '70' },
        { label: i18n('75'), value: '75' },
      ],
      rule: {},
    },
    lensShape: {
      label: i18n('Lens Shape'),
      options: [
        { label: "Clarivo Cateye Opera-48", value: "Clarivo Cateye Opera-48" },
        { label: "Clarivo Rectangular Mayfair-48", value: "Clarivo Rectangular Mayfair-48" },
        { label: "Clarivo Rectangular Chelsea-48", value: "Clarivo Rectangular Chelsea-48" },
        { label: "Clarivo Round Belluno-48", value: "Clarivo Round Belluno-48" },
        { label: "Clarivo Round Cadore-48", value: "Clarivo Round Cadore-48" },
        { label: "Clarivo Oversize Mitte-52", value: "Clarivo Oversize Mitte-52" },
        { label: "Clarivo Oversize Altona-54", value: "Clarivo Oversize Altona-54" },
        { label: "Clarivo Square Soho-48", value: "Clarivo Square Soho-48" },
        { label: "Clarivo Square Tribeca-48", value: "Clarivo Square Tribeca-48" },
        { label: "Clarivo Cateye Opera-52", value: "Clarivo Cateye Opera-52" },
        { label: "Clarivo Rectangular Mayfair-56", value: "Clarivo Rectangular Mayfair-56" },
        { label: "Clarivo Rectangular Chelsea-54", value: "Clarivo Rectangular Chelsea-54" },
        { label: "Clarivo Geometric Ginza-54", value: "Clarivo Geometric Ginza-54" },
        { label: "Clarivo Round Cadore-54", value: "Clarivo Round Cadore-54" },
        { label: "Clarivo Oversize Mitte-58", value: "Clarivo Oversize Mitte-58" },
        { label: "Clarivo Oversize Altona-58", value: "Clarivo Oversize Altona-58" },
        { label: "Clarivo Square Soho-54", value: "Clarivo Square Soho-54" },
        { label: "Clarivo Square Tribeca-54", value: "Clarivo Square Tribeca-54" },
        { label: "Clarivo Cateye Passy-50", value: "Clarivo Cateye Passy-50" },
        { label: "Clarivo Geometric Ginza-48", value: "Clarivo Geometric Ginza-48" },
        { label: "Clarivo Geometric Shibuya-48", value: "Clarivo Geometric Shibuya-48" },
        { label: "Clarivo Cateye Passy-54", value: "Clarivo Cateye Passy-54" },
        { label: "Clarivo Geometric Shibuya-52", value: "Clarivo Geometric Shibuya-52" },
        { label: "Clarivo Round Belluno-54", value: "Clarivo Round Belluno-54" },
        { label: "Clarivo Oversize Mitte-58", value: "Clarivo Oversize Mitte-58" },
        { label: "Clarivo Oversize Altona-58", value: "Clarivo Oversize Altona-58" },
        { label: "Clarivo Square Soho-54", value: "Clarivo Square Soho-54" },
        { label: "Clarivo Square Tribeca-54", value: "Clarivo Square Tribeca-54" },
        { label: "Clarivo Square Soho-50", value: "Clarivo Square Soho-50" },
        { label: "Clarivo Square Tribeca-50", value: "Clarivo Square Tribeca-50" },
        { label: "Clarivo Cateye Opera-54", value: "Clarivo Cateye Opera-54" },
        { label: "Clarivo Cateye Opera-50", value: "Clarivo Cateye Opera-50" },
        { label: "Clarivo Rectangular Mayfair-54", value: "Clarivo Rectangular Mayfair-54" },
        { label: "Clarivo Rectangular Mayfair-50", value: "Clarivo Rectangular Mayfair-50" },
        { label: "Clarivo Geometric Ginza-52", value: "Clarivo Geometric Ginza-52" },
        { label: "Clarivo Chealsea-50", value: "Clarivo Chealsea-50" },
        { label: "Clarivo Round Cadore-50", value: "Clarivo Round Cadore-50" },
        { label: "Clarivo Chealsea-54", value: "Clarivo Chealsea-54" },
        { label: "Clarivo Round Cadore-52", value: "Clarivo Round Cadore-52" },
        { label: "Clarivo Round Belluno-50", value: "Clarivo Round Belluno-50" },
        { label: "Clarivo Oversize Altone-52", value: "Clarivo Oversize Altone-52" },
        { label: "Clarivo Ottesen-56", value: "Clarivo Ottesen-56" },
        { label: "Clarivo Ottesen-50", value: "Clarivo Ottesen-50" },
        { label: "Clarivo Nerima-54", value: "Clarivo Nerima-54" },
        { label: "Clarivo Nerima-50", value: "Clarivo Nerima-50" },
        { label: "Clarivo River Gauche-56", value: "Clarivo River Gauche-56" },
        { label: "Clarivo River Gauche-54", value: "Clarivo River Gauche-54" },
        { label: "Clarivo Marais-50", value: "Clarivo Marais-50" },
        { label: "Clarivo Marais-54", value: "Clarivo Marais-54" },
        { label: "Clarivo Geometric Minato-50", value: "Clarivo Geometric Minato-50" },
        { label: "Clarivo Geometric Minato-54", value: "Clarivo Geometric Minato-54" },
        { label: "Clarivo Square Manhattan-54", value: "Clarivo Square Manhattan-54" },
        { label: "Clarivo Square Manhattan-50", value: "Clarivo Square Manhattan-50" },
        { label: "Clarivo Square Greenwich-54", value: "Clarivo Square Greenwich-54" },
        { label: "Clarivo Cateye Montmarte-54", value: "Clarivo Cateye Montmarte-54" },
        { label: "Clarivo Cateye Montmarte-50", value: "Clarivo Cateye Montmarte-50" },
        { label: "Clarivo Square Greenwich-50", value: "Clarivo Square Greenwich-50" },
        { label: "Clarivo Oversiz Mitte-54", value: "Clarivo Oversiz Mitte-54" },
      ],
      rule: {},
    },
    baseCurve: {
      type: 'text',
      label: i18n('Base Curve'),
      placeholder: i18n('Base Curve'),
    },
    corridor: {
      label: i18n('Corridor'),
      options: [
        { label: i18n('Corridor'), value: '' },
        { label: i18n(CORRIDOR.EXTRA_SHORT), value: CORRIDOR.EXTRA_SHORT },
        { label: i18n(CORRIDOR.SHORT), value: CORRIDOR.SHORT },
        { label: i18n(CORRIDOR.MEDIUM), value: CORRIDOR.MEDIUM },
        { label: i18n(CORRIDOR.LARGE), value: CORRIDOR.LARGE },
      ],
      rule: {
        // required: i18n('Corridor is required for Progressive lens'),
      },
    },
    id: {
      type: 'text',
      label: i18n('Order Id'),
      placeholder: i18n('Order Id'),
      rule: {
        required: i18n('Please enter the order id'),
      },
    },
    term: {
      type: 'text',
      label: i18n('Search term'),
      placeholder: i18n('Search term'),
      rule: {
        required: i18n('Please enter a term to search'),
      },
    },
    addComment: {
      type: 'text',
      label: i18n('Comment'),
      placeholder: i18n('Add comment'),
      rule: {
        required: i18n('Please add a comment'),
      },
    },
    staffName: {
      type: 'text',
      label: i18n('Staff name'),
      placeholder: i18n('Staff name'),
      rule: {
        required: i18n('Staff name is required'),
      },
    },
    channel: {
      label: i18n('Channel'),

      options: [
        { label: i18n('Channel'), value: '' },
        { label: i18n('Whatsapp'), value: 'Whatsapp' },
        { label: i18n('Email'), value: 'Email' },
        { label: i18n('Call'), value: 'Call' },
        { label: i18n('Other'), value: 'Other' }
      ],
      rule: {
        required: i18n('Channel is required'),
      },
    },
    parentItemId: {
      label: i18n('Parent product'),
      options: []
    },
    childItemId: {
      label: i18n('Child product'),
      options: []
    }
  }
  return validations
}

const NO_PRESCRIPTION_SKUS = ['~ CCL', '~ PCL', '~ ACC', '~ SG', '~ BL', '~ RG'];
export const getInvalidProductsMessage = (products = [], i18n) => {
  const message = [];
  const productsWithNoParent = [];
  const productsWithNoChild = [];

  const checkIfNull = product => {
    if (product.power.lensType === LENS_TYPES.PLAN_O || !Object.keys(LENS_TYPES).map(x => LENS_TYPES[x]).includes(product.power.lensType)) return false;
    const nonZeroLeft = Object.keys(product.power.left).find(k => product.power.left[k] !== 0);
    if (nonZeroLeft) return false;
    const nonZeroRight = Object.keys(product.power.right).find(k => product.power.right[k] !== 0)
    if (nonZeroRight) return false;
    return true;
  }

  const checkIfIPD = product => {
    if (!product.power.lensType) return false;

    if (product.power.lensType !== LENS_TYPES.PLAN_O && (!product.power.right.pupilDiameter || !product.power.left.pupilDiameter)) {
      return true;
    }

    return false;
  }

  const checkIfColor = product => {
    if ((product.power.addOn2 || product.power.addOn3) && !product.power.addOnColor) {
      return true;
    }

    return false;
  }

  const checkIfCorridor = product => {
    if (product.power.lensType !== LENS_TYPES.PROGRESSIVE) return false;
    if (!product.power.corridor) return true;
    return false;
  }

  const checkIfNoParent = product => {
    if (!product.hasParents) return false;
    return !product.parentItemId;
  }

  const checkIfNoChild = product => {
    if (!product.hasChildren) return false;
    return !product.childItemId;
  }

  products.forEach(product => {
    if (
      !NO_PRESCRIPTION_SKUS.find(x => product.name.includes(x.name)) &&
      !product.sku?.toUpperCase()?.startsWith?.('DLNN') &&
      product.name?.includes?.('~ EG')
    ) {
      if (checkIfNoParent(product)) {
        message.push(i18n('Please select a parent for all products having parents'))
        productsWithNoParent.push(product.sku);
      };
      if (checkIfNoChild(product)) {
        message.push(i18n('Please select a child for all products having children'))
        productsWithNoChild.push(product.sku);
      };
      // if (checkIfNull(product)) message.push(i18n('Please input prescription details for all products'));
      // if (checkIfIPD(product)) message.push(i18n('All products must have an IPD value'));
      if (checkIfColor(product)) message.push(i18n('Please select Add on Color for all products'));
      // if (checkIfCorridor(product)) message.push(i18n('Corridor is required for all products with Progressive lens type'));
    }
  });

  const checkIfArrayHasDuplicateChildItemIds = products => {
    const childItemIds = products.filter(x => !x.isHidden).map(x => x.childItemId ? `${x.childItemId}` : '').filter(Boolean);
    return childItemIds.length !== new Set(childItemIds).size;
  }

  const checkIfArrayHasDuplicateParentItemIds = products => {
    const parentItemIds = products.filter(x => !x.isHidden).filter(x => x.hasParents).map(x => x.parentItemId ? `${x.parentItemId}` : '').filter(Boolean);
    return parentItemIds.length !== new Set(parentItemIds).size;
  }

  if (checkIfArrayHasDuplicateChildItemIds(products)) message.push(i18n('Child products must be unique for all products'));
  if (checkIfArrayHasDuplicateParentItemIds(products)) message.push(i18n('Parent products must be unique for all products'));

  return { messages: [...new Set(message)], productsWithNoChild, productsWithNoParent };
}
