import React, { useContext, useMemo, useState } from 'react'
import { OrderDetailsContext } from '../order-details/OrderDetails'
import useI18n from 'common-hooks/useI18n';
import { InfoCard } from 'components';
import { convertDataForInfoCard } from '../utils';
import cx from 'classnames'
import styles from '../order-details/OrderDetails.module.scss'
import NoDataAvailable from '../NoDataAvailable';

function ReturnOrders() {
  const { i18n } = useI18n();
  const [currentExpanded, setCurrentExpanded] = useState(null);
  const { childOrders = [], isLoading } = useContext(OrderDetailsContext);

  const compileRootData = (item) => {
    const root = JSON.parse(JSON.stringify(item));
    delete root.customerDTO;
    return root;
  }

  const handleToggle = item => {
    setCurrentExpanded(
      currentExpanded === item.magentoIncrementId ? null : item.magentoIncrementId
    )
  }

  if (!childOrders.length && !isLoading) return <NoDataAvailable />;

  return childOrders.map(item => (
    <div className={cx(styles.section)}>
      <h3 className={cx(styles['accordion-title'])} onClick={() => handleToggle(item)}>
        {item.magentoIncrementId}
        <span className={styles.arrow}>
          {currentExpanded === item.magentoIncrementId ? '▲' : '▼'}
        </span>
      </h3>
      {currentExpanded === item.magentoIncrementId && (
        <>
          <InfoCard wide data={convertDataForInfoCard(compileRootData(item))} />
          {!!item.orderItems?.length && <h4>{i18n('Items')}</h4>}
          {item?.orderItems?.map?.(x => (
            <InfoCard wide data={convertDataForInfoCard(x)} />
          ))}
        </>
      )}
    </div>
  ));
}

export default ReturnOrders
