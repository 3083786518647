import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './FormElements.module.scss'
import { useId } from 'react'
import Skeleton from '../skeleton/Skeleton'

function Input({ label, name, type, className, onChange, min, max, value, onBlur, onFocus, error, required, autoFocus, loading, step, placeholder, disabled, icon, onIconClick }) {
  const id = useId();

  return (
    <div className={cx(styles.field, className, { [styles.error]: error, [styles.disabled]: disabled })}>
      <label className={cx(styles.label)} htmlFor={id}>{label}</label>
      {loading ? <Skeleton type="input" /> : (
        <div className={styles.inputWrapper}>
          <input min={min} max={max} step={step} autoFocus={autoFocus} required={required} onChange={onChange} value={value} onBlur={onBlur} onFocus={onFocus} className={cx(styles.input)} id={id} name={name} type={type} placeholder={placeholder} disabled={disabled} />
          {icon && <img src={icon} className={cx(styles.icon)} onClick={onIconClick} />}
        </div>
      )}
      {error && <label htmlFor={id} className={cx(styles.message)}>{error}</label>}
    </div>
  )
}

Input.defaultProps = {
  type: 'text',
  value: ''
}

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'email', 'tel', 'date']),
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  loading: PropTypes.bool,
  step: PropTypes.string,
  placeholder: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
}

export default Input
