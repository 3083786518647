import React, { useCallback, useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import styles from './Booking.module.scss'
import { Button, Layout, SimpleSelect, Skeleton } from 'components';
import { useParams } from 'react-router';
import { useBookAppointmentSlotMutation, useLazyGetCalendarQuery, useLazyGetCustomerQuery, useLazyWarehousesQuery } from 'app/service';
import { useSelector } from 'react-redux';
import { selectToken } from 'features/login/loginSlice';
import useI18n from 'common-hooks/useI18n';
import CustomerInfoCards from 'pages/customers/CustomerInfoCards';
import ReactDatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { selectDefaults } from 'appSlice';
import BookingSuccess from './BookingSuccess';

const PREV_ARROW = <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M9.32431 1.17496C9.77992 1.63057 9.77992 2.36926 9.32431 2.82488L3.14926 8.99992L9.32431 15.175C9.77992 15.6306 9.77992 16.3693 9.32431 16.8249C8.86869 17.2805 8.13 17.2805 7.67439 16.8249L0.674391 9.82488C0.218779 9.36926 0.218779 8.63057 0.674391 8.17496L7.67439 1.17496C8.13 0.719349 8.8687 0.719349 9.32431 1.17496Z" fill="#31395F" /> </svg>
const NEXT_ARROW = <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M0.675693 16.825C0.220081 16.3694 0.220081 15.6307 0.675693 15.1751L6.85074 9.00008L0.675693 2.82504C0.220081 2.36943 0.220081 1.63074 0.675692 1.17512C1.1313 0.719512 1.87 0.719512 2.32561 1.17512L9.32561 8.17512C9.78122 8.63073 9.78122 9.36943 9.32561 9.82504L2.32561 16.825C1.87 17.2807 1.1313 17.2807 0.675693 16.825Z" fill="#31395F" /> </svg>

function Booking() {
  const { i18n } = useI18n();
  const { storeName: defaultStoreId, country } = useSelector(selectDefaults)
  const token = useSelector(selectToken);
  const { userId } = useParams();
  const [getCustomerInfo, { data: customer = {}, isLoading, isUninitialized }] = useLazyGetCustomerQuery();
  const [getStores, { data: storesData = [] }] = useLazyWarehousesQuery();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedSlot, setSelectedSlot] = useState();
  const [fetchSlots, { data: slots = [], isLoading: fetchingSlots, isUninitialized: hasNotFetchedYet }] = useLazyGetCalendarQuery();
  const openSlots = useMemo(() => slots.filter(x => x.status === 'OPEN'), [slots]);
  const [bookAppointmentSlot, { isLoading: isBookingAppointment, data: appointment }] = useBookAppointmentSlotMutation();
  const [storeId, setStoreId] = useState(defaultStoreId);
  const [stores, setStores] = useState([]);

  const handleSubmit = e => {
    e.preventDefault();
    bookAppointmentSlot({ slotId: parseInt(selectedSlot, 10), customerId: customer.id, country })
  }

  const renderHeader = ({ date, increaseMonth, decreaseMonth }) => (
    <div className={cx(styles.header)}>
      <button className={cx(styles.arrow)} type="button" onClick={decreaseMonth}>{PREV_ARROW}</button>
      <div className={cx(styles.month)}>{dayjs(date).format('MMMM YYYY')}</div>
      <button className={cx(styles.arrow)} type="button" onClick={increaseMonth}>{NEXT_ARROW}</button>
    </div>
  )

  const renderDay = (day, date) => {
    const dayjsDate = dayjs(date);
    const isPast = dayjsDate.isBefore(dayjs(), 'day');

    return (
      <div className={cx(styles.day, { [styles.past]: isPast })} onClick={isPast ? null : () => { }}>
        <div className={cx(styles['day-label'])}>{day}</div>
      </div>
    )
  }

  const bookedStore = useCallback(() => {
    if (!appointment) return null;
    const store = storesData.find(x => x.warehouseCode === storeId);
    return store ? `${store.warehouseName} ${store.warehouseNameArabic ? `(${store.warehouseNameArabic})` : ''}` : '';
  }, [appointment, storesData])

  useEffect(() => {
    if (token) {
      getCustomerInfo(userId)
      getStores()
    }
  }, [token])

  useEffect(() => {
    if (storesData.length && customer.warehouseGroup) {
      const storeArr = storesData
        .filter(store => store.warehouseGroup === customer.warehouseGroup)
        .map(store => ({ value: store.warehouseCode, label: `${store.warehouseName} ${store.warehouseNameArabic ? `(${store.warehouseNameArabic})` : ''}` }))
      setStores(storeArr)
    }
  }, [storesData, customer])

  useEffect(() => {
    if (token && storeId && country) fetchSlots({
      storeId,
      country,
      date: dayjs(selectedDate).local().format('YYYY-MM-DDT00:00:00')
    });
  }, [token, selectedDate, storeId, country]);

  return (
    <Layout narrow title={i18n('Eye test appointments')}>
      <form className={cx(styles.wrapper)} onSubmit={handleSubmit}>
        <CustomerInfoCards data={customer} loading={isLoading || isUninitialized} />

        <h2 className={cx(styles.title)}>{i18n('Select Store')}</h2>
        <div>
          <SimpleSelect isSearchable options={stores} value={storeId} onChange={setStoreId} />
        </div>
        <h2 className={cx(styles.title)}>{i18n('Appointment Date & Time')}</h2>
        <div className={cx(styles['calendar-container'])}>
          <div className={cx(styles.calendar)}><ReactDatePicker value={selectedDate} onChange={setSelectedDate} formatWeekDay={month => month.substring(0, 3)} renderCustomHeader={renderHeader} renderDayContents={renderDay} inline /></div>
          <div className={cx(styles['slots-col'])}>
            <div className={cx(styles.slots)}>
              {fetchingSlots || hasNotFetchedYet ? <Skeleton type="slots" /> : openSlots.map(slot => (
                <label key={slot.slotId} className={cx(styles.slot)}>
                  <input className={cx(styles['slot-input'])} type="radio" name="slot" value={slot.slotId} onChange={e => setSelectedSlot(e.target.value)} />
                  <div className={cx(styles['slot-label'])}>
                    {dayjs(slot.fromTime).format('H:mm')}
                  </div>
                </label>
              ))}
              {!(fetchingSlots || hasNotFetchedYet) && openSlots?.length <= 0 && <div className={cx(styles['no-slots'])}>{i18n('No slots available for the selected date.')}</div>}
            </div>
          </div>
        </div>
        <div className={cx(styles.submit)}>
          <Button loading={isBookingAppointment} disabled={!selectedSlot || !customer.id} type="submit">{i18n(customer.id ? 'Book' : 'Fetching customer...')}</Button>
        </div>
      </form>
      {!!appointment && <BookingSuccess {...openSlots.find(x => x.slotId === parseInt(selectedSlot, 0))} bookedStore={() => bookedStore()} />}
    </Layout>
  )
}

Booking.propTypes = {}

export default Booking
