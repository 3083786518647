import React from 'react'
import cx from 'classnames'
import SidebarNav from 'components/side-bar-navigation/SidebarNav'
import styles from './DeliveryManagement.module.scss'
import { Outlet } from 'react-router'

export default function DeliveryManagement() {
  return (
    <div className={styles.wrapper}>
      <SidebarNav />
      <div className={styles['outlet-wrapper']}>
        <Outlet />
      </div>
    </div>
  )
}
