import React, { useEffect } from 'react';
import cx from 'classnames';
import styles from './Prescription.module.scss';
import SimpleSelect from 'components/form-elements/SimpleSelect';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import useI18n from 'common-hooks/useI18n';

const OPTIONS = {
  Imperial: ['20/2000', '20/630', '20/200', '20/160', '20/125', '20/100', '20/80', '20/63', '20/50', '20/40', '20/32', '20/25', '20/20', '20/16', '20/12.5'],
  Metric: ['6/600', '6/190', '6/60', '6/48', '6/38', '6/30', '6/24', '6/19', '6/15', '6/12', '6/9.5', '6/7.5', '6/6', '6/4.8', '6/3.8'],
  Decimal: ['1.0', '0.32', '0.10', '0.125', '0.16', '0.20', '0.25', '0.32', '0.40', '0.50', '0.63', '0.80', '1.00', '1.25', '1.60'],
  LogMAR: ['2.0', '1.5', '1.00', '0.90', '0.80', '0.70', '0.60', '0.50', '0.40', '0.30', '0.20', '0.10', '0.00', -'0.10', -'0.20']
}

function VisualAcuity({ side, readonly, disabled }) {
  const { i18n } = useI18n();
  const { setValue } = useFormContext();
  const category = useWatch({ name: `${side}.visualAcuityCategory` })

  useEffect(() => {
    setValue(`${side}.visualAcuityValue`, '');
  }, [category])

  return (
    <>
      <Controller
        name={`${side}.visualAcuityCategory`}
        defaultValue=""
        render={({ field: { value, onBlur, onChange }}) => (
          <SimpleSelect disabled={readonly || disabled} className={cx(styles.field)} placeholder={i18n('Select')} label={i18n('Visual Acuity Category')} onBlur={onBlur} onChange={onChange} options={[{ label: 'No Selection', value: '' }, ...Object.keys(OPTIONS).map(x => ({ label: x, value: x }))]} value={value} />
        )}
      />
      {category && <Controller
        name={`${side}.visualAcuityValue`}
        defaultValue=""
        rules={{
          validate: value => {
            if (readonly || disabled) return true;
            if (!category) return true;
            return (!value) ? 'Please select a value' : true;
          },
        }}
        render={({ field: { value, onBlur, onChange }}) => (
          <SimpleSelect disabled={readonly || disabled} className={cx(styles.field)} placeholder={i18n('Select')} label={'Visual Acuity Value'} onBlur={onBlur} onChange={onChange} options={OPTIONS[category].map(x => ({ label:x, value: x }))} value={value} />
        )}
      />}
    </>
  )
}

export default VisualAcuity;