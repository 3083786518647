import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { InfoCard, Layout } from 'components'
import useI18n from 'common-hooks/useI18n';
import cx from 'classnames';
import styles from './DeliveryPromiseCalculator.module.scss';
import DeliveryPromiseCalculatorFormFields from './DeliveryPromiseCalculatorFormFields';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { DEFAULT_VALUES } from 'components/prescription/prescription-constants';
import { useGetDeliverySlaMutation, useLazyFetchPrescriptionByIdQuery } from 'app/service';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

function DeliveryPromiseCalculator(props) {
  const { i18n } = useI18n();
  const { search } = useLocation()

  let prescriptionId = useMemo(() => {
    const params = new URLSearchParams(search);
    return params.get('prescriptionId')
  }, [search])

  const [getDeliverySla] = useGetDeliverySlaMutation();
  const [fetchPrescription] = useLazyFetchPrescriptionByIdQuery();
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      lensType: 'Single Vision',
      frameType: 'Rimless',
      addon: '',
      package: '',
      color: '',
      left: { sphere: DEFAULT_VALUES.sphere, cylinder: DEFAULT_VALUES.cylinder },
      right: { sphere: DEFAULT_VALUES.sphere, cylinder: DEFAULT_VALUES.cylinder },
    }
  });
  const [deliveryPromiseDate, setDeliveryPromiseDate] = useState('');

  const fields = useWatch({ control: methods.control });

  const fetchDeliverySla = useCallback(async () => {
    try {

      const { data } = await getDeliverySla({ data: fields });
      setDeliveryPromiseDate(data.estimatedDeliveryDate);
    } catch (error) {
      toast.error('Failed to fetch delivery SLA, please try again.');
    }
  }, [fields, getDeliverySla]);

  const fetchPrescriptionById = useCallback(async (id) => {
    try {
      const { data: latestPrescription } = await fetchPrescription(id);
      methods.reset({
        lensType: latestPrescription.lensType,
        frameType: '',
        addon: '',
        color: '',
        left: { sphere: latestPrescription?.left?.sphere ?? DEFAULT_VALUES.sphere, cylinder: latestPrescription?.left?.cylinder ?? DEFAULT_VALUES.cylinder },
        right: { sphere: latestPrescription?.right?.sphere ?? DEFAULT_VALUES.sphere, cylinder: latestPrescription?.right?.cylinder ?? DEFAULT_VALUES.cylinder },
      });
    } catch (error) {
      toast.error('Failed to fetch latest prescription, please add the values manually.');
    }
  }, []);


  useEffect(() => {
    if (prescriptionId) {
      fetchPrescriptionById(prescriptionId);
    }
  }, [prescriptionId]);

  useEffect(() => {
    fetchDeliverySla();
  }, [fields]);

  return (
    <Layout title={i18n('Calculate delivery promise')}>
      <div className={cx(styles.wrapper)}>
        <form className={cx(styles.form)}>
          <FormProvider {...methods}>
            <DeliveryPromiseCalculatorFormFields />
          </FormProvider>
          <h4 className={cx(styles['notes-heading'])}>Notes:</h4>
          <ul className={cx(styles.notes)}>
            <li className={cx(styles.note)}>For customer frames (Glazing) and if frames are not available in the warehouse, please add at least 1 more day</li>
            <li className={cx(styles.note)}>No urgent orders at all</li>
            <li className={cx(styles.note)}>Processing time starts after the prescription update in the tool</li>
          </ul>
        </form>
        <div className={cx(styles.display)}>
          <h3 className={cx(styles['display-heading'])}>{i18n('Delivery promise date - ')}</h3>
          <div className={styles.deliveryDate}>{deliveryPromiseDate}</div>
        </div>
      </div>
    </Layout>
  )
}

export default DeliveryPromiseCalculator
