import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './UserBar.module.scss'
import { useSelector } from 'react-redux';
import { selectUser } from 'features/login/loginSlice';
import { selectDefaults } from 'appSlice';
import Skeleton from 'components/skeleton/Skeleton';
import useStore from 'common-hooks/useStore';
import useI18n from 'common-hooks/useI18n';
import Button from 'components/button/Button';
import { useParams } from 'react-router';

function UserBar() {
  const { name } = useSelector(selectUser);
  const { label: store } = useStore();
  const { store: storeIdInParams } = useParams();
  const { storeName: storeId } = useSelector(selectDefaults);
  const { i18n } = useI18n();

  return (
    <div className={cx(styles.wrapper)}>
      <div className={cx(styles.user)}>
        <div className={cx(styles.image)}>
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.4355 21H22.4355L25.9355 17.5L26.9355 13.5L24.9355 9.5L20.4355 7.5L16.9355 8L14.4355 10L13.4355 12.5L16.1022 13.5L19.9355 10.5L23.4355 13.5L21.9355 14L22.4355 18L17.9355 17.5L17.4355 14L13.4355 12.5V17L17.4355 21Z" fill="#80C8C1"/>
            <path d="M27.2751 14.2904C27.2751 10.2373 23.9894 6.95166 19.9364 6.95166C15.8833 6.95166 12.5977 10.2373 12.5977 14.2904C12.5977 18.3434 15.8833 21.6291 19.9364 21.6291C23.9875 21.6244 27.2704 18.3415 27.2751 14.2904ZM13.7267 14.2904C13.7267 10.8608 16.5068 8.08069 19.9364 8.08069C23.3659 8.08069 26.146 10.8608 26.146 14.2904C26.146 17.7199 23.3659 20.5 19.9364 20.5C16.5085 20.4959 13.7308 17.7183 13.7267 14.2904Z" fill="#232323"/>
            <path d="M36.4363 33.4999C36.4363 35.7091 29.3252 37.4999 19.9363 37.4999C10.5475 37.4999 3.43634 35.7091 3.43634 33.4999C2.95542 31.3781 16.4907 28.8914 16.9363 29.4999L19.9363 33.4999L22.9363 29.5C22.9239 28.9266 36.4169 31.0293 36.4363 33.4999Z" fill="#FC766A"/>
            <path d="M23.9475 29.0857C27.1127 24.625 31.2258 18.0964 31.2258 14.2903C31.2258 8.05473 26.1711 3 19.9355 3C13.6999 3 8.64516 8.05473 8.64516 14.2903C8.64516 18.0964 12.7583 24.625 15.9235 29.0857C9.73974 29.4639 3 30.8052 3 33.4839C3 36.4487 11.5196 38 19.9355 38C28.3514 38 36.871 36.4487 36.871 33.4839C36.871 30.8052 30.1312 29.4639 23.9475 29.0857ZM19.9355 4.12903C25.5448 4.13565 30.0902 8.68099 30.0968 14.2903C30.0968 19.2778 21.9383 30.0144 19.9355 32.574C17.9327 30.0144 9.77419 19.2778 9.77419 14.2903C9.78081 8.68099 14.3262 4.13565 19.9355 4.12903ZM19.9355 36.871C10.1422 36.871 4.12903 34.8979 4.12903 33.4839C4.12903 32.3435 8.50238 30.5789 16.7069 30.1753C18.2069 32.2352 19.3558 33.6644 19.4969 33.8383C19.6042 33.9707 19.7651 34.0476 19.9355 34.0476C20.1058 34.0476 20.2668 33.9707 20.374 33.8383C20.5152 33.6644 21.664 32.2352 23.1641 30.1753C31.3686 30.5789 35.7419 32.3435 35.7419 33.4839C35.7419 34.8979 29.7288 36.871 19.9355 36.871Z" fill="#232323"/>
          </svg>
        </div>
        <div className={cx(styles.details)}>
          <p className={cx(styles.name)}>{name || <Skeleton />}</p>
          <p className={cx(styles.store)}>{store || <Skeleton />}</p>
        </div>
      </div>
      {!storeIdInParams && <Button size="small" theme="secondary" to={`/availability/${storeId}/list`} className={cx(styles.availability)}>{i18n('Update Availability')}</Button>}
    </div>
  )
}

UserBar.propTypes = {}

export default UserBar
