import React from 'react'
import PropTypes from 'prop-types'
import { Layout, UserBar, UserStores } from 'components'
import { useNavigate } from 'react-router'
import cx from 'classnames'
import styles from './DefaultStore.module.scss'
import useI18n from 'common-hooks/useI18n'

function DefaultStore(props) {
  const {i18n} = useI18n();

  return (
    <Layout narrow  title={i18n('Stores')}>
      <div className={cx(styles.wrapper)}>
        <UserBar />
        <UserStores mode="default" />
      </div>
    </Layout>
  )
}

DefaultStore.propTypes = {}

export default DefaultStore
