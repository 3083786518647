import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './Button.module.scss'
import Skeleton from 'components/skeleton/Skeleton'
import { Link } from 'react-router-dom'

function Button({ type, theme, disabled, children, onClick, className, to, size, skeleton, loading }) {
  if (skeleton) return <Skeleton type="button" />;

  const spinner = loading ? (
    <div className={cx(styles.spinner)}><div /><div /><div /><div /></div>
  ) : null;

  const commonProps = {
    className: cx(styles.button, className, styles[theme], styles[size], { [styles.loading]: loading }),
    onClick,
    theme,
    disabled: disabled || loading,
  }

  return to ? (
    <Link to={to} {...commonProps}>{children}{spinner}</Link>
  ) : (
    <button type={type} {...commonProps}>{children}{spinner}</button>
  )
}

Button.defaultProps = {
  type: 'button',
  theme: 'primary',
  onClick: () => {},
}

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit']),
  theme: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  to: PropTypes.string,
  size: PropTypes.oneOf(['small', 'extra-small']),
  skeleton: PropTypes.bool,
  loading: PropTypes.bool
}

export default Button
