import React from 'react'
import { FormSectionTitle, Input, Button, Checkbox } from 'components'
import { useNavigate } from 'react-router'
import useI18n from 'common-hooks/useI18n';
import { useCreateUserMutation } from 'app/service';
import { Controller, useForm } from "react-hook-form";
import cx from 'classnames'
import styles from '../Users.module.scss'

function AddUser() {
  const methods = useForm({ mode: 'all' });
  // const { handleSubmit, watch, setValue, formState: { isValid } } = useFormContext();
  const navigate = useNavigate();
  const { i18n } = useI18n();
  const [createUser, { isLoading }] = useCreateUserMutation();

  const _onSubmit = async (data, e) => {
    e.preventDefault();
    await createUser({ data })
    navigate('/settings/users')
  }

  return (
    <div>
      <FormSectionTitle>{i18n('User Information')}</FormSectionTitle>
      <form className={cx(styles.form)} onSubmit={methods.handleSubmit(_onSubmit)}>
        <div className={cx(styles.fields)}>
          <Controller
            control={methods.control}
            key={'username'}
            name={'username'}
            rules={{
              required: i18n('Please enter username'),
            }}
            render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
              <Input
                type="text"
                label={i18n('User Name')}
                error={error?.message}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                placeholder="user"
              />
            )}
          />
          <Controller
            control={methods.control}
            key={'email'}
            name={'email'}
            rules={{
              required: i18n('Email address is required'),
              pattern: {
                value: /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: i18n('Please enter a valid email address (ex. johndoe@domain.com)'),
              },
              validate: {
                // unique: async email => {
                //   if (disabled) return true;
                //   const { data } = await getCustomer(email);
                //   return data?.email ? i18n('Email already exists') : true;
                // },
              }
            }}
            render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
              <Input
                disabled={isLoading}
                type="email"
                label={i18n('Email Address')}
                error={error?.message}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                placeholder='john@doe.com'
              />
            )}
          />
          <Controller
            control={methods.control}
            key={'password'}
            name={'password'}
            rules={{
              required: i18n('Please enter password'),
            }}
            render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
              <Input
                type="text"
                label={i18n('Password')}
                error={error?.message}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                placeholder="xxxx"
              />
            )}
          />
        </div>
        <div className={cx(styles.fields)}>
          <Controller
            control={methods.control}
            key={'firstName'}
            name={'firstName'}
            rules={{
              // required: i18n('Please enter first name'),
            }}
            render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
              <Input
                type="text"
                label={i18n('First Name')}
                error={error?.message}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                placeholder="John"
              />
            )}
          />
          <Controller
            control={methods.control}
            key={'lastName'}
            name={'lastName'}
            rules={{

            }}
            render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
              <Input
                disabled={isLoading}
                type="text"
                label={i18n('Last Name')}
                error={error?.message}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                placeholder='Doe'
              />
            )}
          />
          <Controller
            control={methods.control}
            key={'employeeCode'}
            name={'employeeCode'}
            rules={{

            }}
            render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
              <Input
                type="text"
                label={i18n('Employee Code')}
                error={error?.message}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                placeholder="emp123"
              />
            )}
          />
          <Controller
            control={methods.control}
            key={'mobileNo'}
            name={'mobileNo'}
            rules={{}}
            render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
              <Input type="number" label={i18n('Phone')} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} />
            )}
          />
        </div>
        <div className={cx(styles.fields)}>
          <Controller
            control={methods.control}
            key={'active'}
            name={'mobileNo'}
            render={({ field: { value, onBlur, onChange }, fieldState: { error, name } }) => (
              <Checkbox className={cx(styles['active-checkbox'])} name="active" value="active" checked={value} label={i18n('Active')} error={error?.message} onBlur={onBlur} onChange={onChange} />
            )}
          />
        </div>
        <Button loading={isLoading} skeleton={isLoading} type="submit" className={cx(styles.submit)} disabled={isLoading}>{i18n('Save')}</Button>
      </form>
    </div >
  )
}

AddUser.propTypes = {}

export default AddUser
