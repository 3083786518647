import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from './Order.module.scss'
import cx from 'classnames'
import { Button, InfoCard, Layout, SectionNav, Skeleton } from 'components'
import useI18n from 'common-hooks/useI18n'
import { useNavigate, useParams } from 'react-router'
import { useLazyGetOrderQuery, useUpdateOrderMutation } from 'app/service'
import { useSelector } from 'react-redux'
import { selectToken } from 'features/login/loginSlice'
import dayjs from 'dayjs'
import Sku from './sku/Sku'
import OrdersNav from '../OrdersNav'
import { Resync } from '../Resync'

const CHILD_PRODUCT_START = 'DLNN';
const WARNING_ICON = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 486.463 486.463" xmlSpace="preserve"> <path d="M243.225 333.382c-13.6 0-25 11.4-25 25s11.4 25 25 25c13.1 0 25-11.4 24.4-24.4.6-14.3-10.7-25.6-24.4-25.6z"/> <path d="M474.625 421.982c15.7-27.1 15.8-59.4.2-86.4l-156.6-271.2c-15.5-27.3-43.5-43.5-74.9-43.5s-59.4 16.3-74.9 43.4l-156.8 271.5c-15.6 27.3-15.5 59.8.3 86.9 15.6 26.8 43.5 42.9 74.7 42.9h312.8c31.3 0 59.4-16.3 75.2-43.6zm-34-19.6c-8.7 15-24.1 23.9-41.3 23.9h-312.8c-17 0-32.3-8.7-40.8-23.4-8.6-14.9-8.7-32.7-.1-47.7l156.8-271.4c8.5-14.9 23.7-23.7 40.9-23.7 17.1 0 32.4 8.9 40.9 23.8l156.7 271.4c8.4 14.6 8.3 32.2-.3 47.1z"/> <path d="M237.025 157.882c-11.9 3.4-19.3 14.2-19.3 27.3.6 7.9 1.1 15.9 1.7 23.8 1.7 30.1 3.4 59.6 5.1 89.7.6 10.2 8.5 17.6 18.7 17.6s18.2-7.9 18.7-18.2c0-6.2 0-11.9.6-18.2 1.1-19.3 2.3-38.6 3.4-57.9.6-12.5 1.7-25 2.3-37.5 0-4.5-.6-8.5-2.3-12.5-5.1-11.2-17-16.9-28.9-14.1z"/> </svg>

function Order() {
  const { i18n } = useI18n();
  const navigate = useNavigate();
  const token = useSelector(selectToken);
  const workingProducts = useRef({});
  const [isValid, setIsValid] = useState(false);
  const { orderid, productid } = useParams();
  const [getOrder, { data = { products: [] }, error, isLoading: isLoadingOrder }] = useLazyGetOrderQuery();
  const [updateOrder, { isLoading }] = useUpdateOrderMutation();
  const visibleProducts = useMemo(() => data.products.filter(x => !x.sku.startsWith(CHILD_PRODUCT_START)), [data.products]);
  const childProducts = useMemo(() => data.products.filter(x => x.sku.startsWith(CHILD_PRODUCT_START)), [data.products]);
  const parentProducts = useMemo(() => visibleProducts.filter(x => !x.name?.includes?.('~ EG')), [visibleProducts])

  const order = useMemo(() => {
    return {
      [i18n('Order Id')]: data.id,
      [i18n('Date')]: dayjs(data.orderCreatedAt).format('D MMMM YYYY'),
      [i18n('Fullfilment Center')]: data.fulfilmentCentre?.name,
      [i18n('ERP order Id')]: data.erpOrderId,
      [i18n('Receipt Id')]: data.receiptId,
      [i18n('Prescription updated at EG tool')]: data.lastPrescriptionUpdatedAt,
      [i18n('Prescription updated in ERP at')]: data.lastPrescriptionUpdatedAtERP,
    }
  }, [data]);

  const customer = useMemo(() => {
    return {
      [i18n('Customer Name')]: data.clientName,
      [i18n('Customer Id_p')]: data.customerId,
      [i18n('Email id')]: data.email,
      [i18n('Mobile No')]: data.phone,
      [i18n('Address')]: data.address,
      [i18n('City')]: data.city,
      [i18n('Grand Total')]: data.grandTotal,
      [i18n('Payment Method')]: data.paymentMethod,
      [i18n('Coupon Code')]: data.discountCode,
    }
  }, [data]);

  const handleUpdate = async (product, id) => {
    workingProducts.current = { ...workingProducts.current, [id]: product };
    setIsValid(Object.values(workingProducts.current).findIndex(x => x === false) === -1);
  }

  const handleSubmit = async () => {
    const products = JSON.parse(JSON.stringify(data.products));
    Object.keys(workingProducts.current).forEach(id => {
      const product = workingProducts.current[id];
      const currentIndex = products.findIndex(x => `${x.id}` === `${id}`);
      products[currentIndex].power = product;
      delete products[currentIndex].power.sku;
      if (product.childItemId) products[currentIndex].childItemId = product.childItemId;
      if (product.parentItemId) products[currentIndex].parentItemId = product.parentItemId;
    });
    await updateOrder({ products, orderNumber: orderid, id: orderid });
    getOrder(orderid)
    navigate(`/orders/${orderid}`);
  }

  useEffect(() => {
    if (error?.data && !isLoadingOrder) {
      setTimeout(() => {
        getOrder(orderid);
      }, [60000])
    };
  }, [error]);

  useEffect(() => {
    if (token) getOrder(orderid);
  }, [token]);

  if (error?.data && !isLoadingOrder) {
    return <Layout wide title={i18n('Order Details')}><Resync /></Layout>
  }

  return (
    <Layout wide title={i18n('Order Details')}>
      <OrdersNav active={0} />
      {!!data.lastPrescriptionUpdatedAtERP && <div className={cx(styles.warn)}>
        {WARNING_ICON}
        <span className={cx(styles['warn-text'])}>
          <span>Please update the prescription only once</span>
          <span>يرجى تحديث الوصفة الطبية مرة واحدة فقط</span>
        </span>
      </div>}
      <InfoCard wide title={i18n('Order Detail')} data={order} loading={!data.id} />
      <InfoCard wide title={i18n('Customer Detail')} data={customer} loading={!data.id} />
      <h2 className={cx(styles['sku-heading'])}>{i18n('SKU Details')}</h2>
      {!data.id ? <Skeleton /> : visibleProducts.map(sku => <Sku orderDate={data.timestamp} orderId={data.id} email={data.email} phone={data.phone} key={sku.id} id={sku.id} {...sku} parentProducts={parentProducts} children={childProducts} onUpdate={handleUpdate} loading={isLoading} customerId={data.customerId} customerName={data.clientName} country={data.country} />)}
      <Button disabled={!isValid} type="submit" loading={isLoading} theme="primary" onClick={handleSubmit}>Submit</Button>
      {!isValid && <div className={cx(styles.validations)}>
        <span className={cx(styles['validation-text'])}>{i18n('Please fill in prescription on all products of the order')}</span>
        <span className={cx(styles['validation-text'])}>{i18n('يرجى ملء الوصفة الطبية على جميع المنتجات في الطلب')}</span>
      </div>}
    </Layout>
  )
}

Order.propTypes = {}

export default Order
