import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  alerts: [],
  stores: [],
  staff: [],
  externalDoctors: [],
  language: 'en',
  defaults: {
    storeName: '',
    country: '',
    staffName: '',
    city: '',
    loaded: false
  }
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    addAlert: (state, action) => {
      state.alerts.push(action.payload);
    },
    clearAlerts: (state) => {
      state.alerts = [];
    },
    addStores: (state, action) => {
      state.stores = action.payload;
    },
    addExternalDoctors: (state, action) => {
      state.externalDoctors = action.payload;
    },
    updateDefaults: (state, action) => {
      state.defaults = { ...state.defaults, ...action.payload }
      if (action.payload.loaded === undefined) state.defaults.loaded = true;
    },
    addStaff: (state, action) => {
      state.staff = action.payload;
    },
    updateLaguage: (state, action) => {
      state.language = action.payload
    }
  },
});

export const { addAlert, clearAlerts, addStores, addStaff, updateDefaults, updateLaguage, addExternalDoctors } = appSlice.actions;
export const selectAlerts = state => state.app.alerts;
export const selectStores = state => state.app.stores;
export const selectStaff = state => state.app.staff;
export const selectDefaults = state => state.app.defaults;
export const selectLanguage = state => state.app.language;
export const selectExternalDoctors = state => state.app.externalDoctors;
export default appSlice.reducer;
