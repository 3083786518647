import React, { useEffect, useState } from 'react'
import HomeCard from './HomeCard'
import { BoxLink, PredictiveSearch } from 'components'
import addCustomerIcon from './icons/prescription.svg'
import add from './icons/add.svg'
import useI18n from 'common-hooks/useI18n'
import { useLazyGetNewestCustomersQuery, useLazyGetPredictedCustomersQuery } from 'app/service'
import { useNavigate } from 'react-router'
import cx from 'classnames'
import styles from './Home.module.scss'
import TabularSummary from 'components/tabular-summary/TabularSummary'
import { useSelector } from 'react-redux'
import { selectToken } from 'features/login/loginSlice'

function PrescriptionCard() {
  const { i18n } = useI18n();
  const token = useSelector(selectToken)
  const navigate = useNavigate();
  const [focused, setFocused] = useState(false);
  const [results, setResults] = useState([]);
  const [getPredictedCustomers, { isLoading }] = useLazyGetPredictedCustomersQuery();
  const [getNewestCustomers, { data: newestCustomers = [] }] = useLazyGetNewestCustomersQuery();
  const handleFocus = () => setFocused(true);
  const handleBlur = () => setFocused(false);
  const handleSearch = async term => {
    if (term) {
      const { data } = await getPredictedCustomers(term);
      setResults(data);
    } else {
      setResults([]);
    }
  }

  const handleSelect = id => {
    navigate(`customers/${id}/prescriptions`);
  }

  const handleNewPrescription = () => navigate('customer');

  useEffect(() => {
    if (token) getNewestCustomers();
  }, [token, getNewestCustomers])

  return (
    <HomeCard focused={focused} to="customers/add" icon={addCustomerIcon} title={i18n('Add Customer Prescription')} description={i18n('')}>
      <PredictiveSearch
        onSelect={handleSelect}
        loading={isLoading}
        results={results}
        onSearch={handleSearch}
        placeholder={i18n('Search for customer')}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onNoResultsCtaClick={handleNewPrescription}
        noResultsMessage={i18n('Sorry, we cannot find any matches.')}
        noResultsCtaLabel={i18n('Add New')}
      />
      <div className={cx(styles.cols)}>
        <BoxLink className={cx(styles.cta)} icon={add} label={i18n('Add Prescription')} to="/customers/add" />
        <div className={cx(styles['newest-prescriptions'])}>
          {newestCustomers.map(customer => (
            <TabularSummary
              to={`/customers/${customer.id}/prescriptions`}
              className={cx(styles['newest-prescription'])}
              title={customer.fullName}
              key={customer.id}
              items={[
                { term: i18n('Phone'), def: customer.phone },
                { term: i18n('Email'), def: customer.email },
                { term: i18n('Prescription'), def: customer.prescriptionCount ? `${customer.prescriptionCount} ${i18n('Added')}` : '-', highlight: !!customer.prescriptionCount },
              ]}
            />
          ))}
        </div>
      </div>
    </HomeCard>
  )
}

PrescriptionCard.propTypes = {}

export default PrescriptionCard
