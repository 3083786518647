import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Message } from 'components'
import useI18n from 'common-hooks/useI18n'
import SkuFormFields from './SkuFormFields'
import { useParams } from 'react-router'

function SkuForm({ onSubmit, loading, email, parentProducts, defaultParent, hasParent, id, orderId, phone, customerId,  ...props }) {
  const { i18n } = useI18n();
  const methods = useForm({ mode: 'all', defaultValues: props });
  const { formState: { isValid } } = methods;
  const [messages, setMessages] = useState([]);
  const [showConfirmBeforeSubmit, setShowConfirmBeforeSubmit] = useState(false);
  const fields = methods.watch();

  const confirmBeforeSubmit = () => {
    setShowConfirmBeforeSubmit(true);
  }

  const validateAll = async () => {
    const validateResponse = await methods.trigger()
    if (validateResponse) {
      onSubmit(fields, id);
    } else {
      onSubmit(false, id);
    }
  }

  useEffect(() => {
    validateAll()
  }, [isValid, fields]);

  return (
    <>
      <FormProvider {...methods}>
        <SkuFormFields orderId={orderId} hasParent={hasParent} parentProducts={parentProducts} defaultParent={defaultParent} email={email} phone={phone} customerId={customerId} onSubmit={confirmBeforeSubmit} loading={loading} />
      </FormProvider>
      {showConfirmBeforeSubmit && <Message
        error
        info={
          <>
            <span>The prescription will not be saved in ERP. Please reach out to warehouse team before updating prescription</span>
            <br />
            <span>لن يتم حفظ الوصفة الطبية. يرجى التواصل مع فريق المستودعات قبل تحديث الوصفة الطبية</span>
          </>
        }
        cta={i18n('Yes')}
        onClick={methods.handleSubmit(onSubmit)}
        cancel={i18n('No')}
        onCancel={() => setShowConfirmBeforeSubmit(false)} />
      }
      {!!messages.length && <Message error message={i18n('Order detail has not been updated.')} info={messages.join(', ')} cta={i18n('Close')} onClick={() => setMessages([])} />}
    </>
  )
}

SkuForm.propTypes = {}

export default SkuForm
