import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './FormElements.module.scss'
import { useId } from 'react'
import Select, { components } from 'react-select'
import { useController, useFormContext, useWatch } from 'react-hook-form'
import useI18n from 'common-hooks/useI18n'
import Skeleton from 'components/skeleton/Skeleton'
import { useSelector } from 'react-redux'
import { selectDefaults } from 'appSlice'
import useStore from 'common-hooks/useStore'

const ARROW = <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M0.52827 0.52827C0.788619 0.26792 1.21073 0.26792 1.47108 0.52827L4.99967 4.05687L8.52827 0.52827C8.78862 0.26792 9.21073 0.26792 9.47108 0.52827C9.73143 0.788619 9.73143 1.21073 9.47108 1.47108L5.47108 5.47108C5.21073 5.73143 4.78862 5.73143 4.52827 5.47108L0.52827 1.47108C0.26792 1.21073 0.26792 0.788619 0.52827 0.52827Z" fill="#31395F"/> </svg>
const CODES = [
  { code: 'ae', label: '+971', value: '971', country: 'United Arab Emirates', flag: require('./flags/ae.png') },
  { code: 'sa', label: '+966', value: '966', country: 'Saudi Arabia', flag: require('./flags/sa.png') },
  { code: 'bh', label: '+973', value: '973', country: 'Bahrain', flag: require('./flags/bh.png')  },
  { code: 'kw', label: '+965', value: '965', country: 'Kuwait', flag: require('./flags/kw.png')  },
  { code: 'om', label: '+968', value: '968', country: 'Oman', flag: require('./flags/om.png')  },
  { code: 'qa', label: '+974', value: '974', country: 'Qatar', flag: require('./flags/qa.png')  },
]

function Phone({ className, label, loading }) {
  const id = useId();
  const { i18n } = useI18n();
  const { country = 'ae' } = useStore();
  const { trigger, setValue } = useFormContext();
  const code = useWatch({ name: 'code' });
  const { field: codeField } = useController({ name: 'code' });
  const { field: phoneField, fieldState: phoneFieldState } = useController({
    name: 'phone',
    rules: {
      required: i18n('Phone field is required'),
      validate: val => checkIfValidPhone(val) || i18n('Phone number is not valid')
    },
  });

  const checkIfValidPhone = val => {
    if (!code) return false;
    const length = val.length;
    if (length < 6 && length > 11) return false;
    if (isNaN(val)) return false;
    if (length !== 9 && ['971', '966'].includes(code)) return false;
    if (length !== 8 && ['965', '968', '973', '974'].includes(code)) return false;
    return true;
  }

  useEffect(() => {
    setValue('code', CODES.find(x => x.code === country || x.country === country)?.value);
  }, [country]);

  return (
    <div className={cx(styles.field, className, { [styles.error]: phoneFieldState.error } )}>
      <label className={cx(styles.label)} htmlFor={id}>{label}</label>
      {loading ? <Skeleton type="input" /> : (
        <div className={cx(styles.inputs)}>
          <Select
            isSearchable={false}
            unstyled
            components={{
              DropdownIndicator: () => ARROW,
              Option: props => (
                <components.Option {...props}>
                  <img src={props.data.flag} alt="" />
                  {props.data.label}
                </components.Option>
              ),
              SingleValue: props => (
                <components.SingleValue {...props} className={cx(styles['isd-value'])}>
                  <img src={CODES.find(x => x.value === props.data.value)?.flag} alt="" />
                  {props.data.label}
                </components.SingleValue>
              )
            }}
            classNames={{
              control: () => cx(styles['select-control'], styles.input, styles.isd),
              menu: () => cx(styles['select-menu']),
              option: state => cx(styles['select-option'], { [styles.selected]: state.isSelected }),
            }}
            options={CODES}
            value={{ label: code, value: code }}
            onChange={e => codeField.onChange(e.value)}
          />
          <input required onChange={phoneField.onChange} value={phoneField.value || ''} onBlur={phoneField.onBlur} className={cx(styles.input)} id={id} name={phoneField.name} type="tel" />
        </div>
      )}
      {phoneFieldState.error && <label htmlFor={id} className={cx(styles.message)}>{phoneFieldState.error?.message}</label>}
    </div>
  )
}

Phone.defaultProps = {}

Phone.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.bool
}

export default Phone
