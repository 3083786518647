import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './UserStores.module.scss'
import useI18n from 'common-hooks/useI18n'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from 'features/login/loginSlice'
import { selectDefaults, selectStores, updateDefaults } from 'appSlice'
import locationIcon from './location.png'
import Button from 'components/button/Button'
import { useNavigate } from 'react-router'
import { useUpdateDefaultConfigsMutation } from 'app/service'
import Message from 'components/message/Message'

function UserStores({ mode }) {
  const { i18n } = useI18n();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { stores: userStoreCodes = [], email } = useSelector(selectUser);
  const defaults = useSelector(selectDefaults);
  const { storeName: defaultStoreId } = defaults;
  const stores = useSelector(selectStores);
  const userStores = useMemo(() => stores.filter(x => userStoreCodes.includes(x.value)), [userStoreCodes, stores]);
  const [update, { isLoading }] = useUpdateDefaultConfigsMutation();

  const updateDefaultStore = async store => {
    const data = { ...defaults, storeName: store.value }
    await update(data);
    dispatch(updateDefaults(data));
  }

  debugger;

  if (email && !userStores.length) return (
    <Message error message={i18n('You are not part of any stores. Contact an admin')} />
  );

  return (
    <div className={cx(styles.wrapper)}>
      <h2 className={cx(styles.title)}>{i18n('The stores you work in')}</h2>
      <ul className={cx(styles.list)}>
        {userStores.map(store => (
          <li className={cx(styles.store)} key={store.value}>
            {defaultStoreId === store.value && <span className={cx(styles.default)}>{i18n('Current')}</span>}
            <img src={locationIcon} alt="" className={cx(styles.icon)} />
            <h3 className={cx(styles.name)}>{store.label}</h3>
            {mode === 'default' ? (
              store.value !== defaultStoreId && <Button loading={isLoading} onClick={() => updateDefaultStore(store)} className={cx(styles.cta)}>{i18n('Set Current')}</Button>
            ) : (
              <Button onClick={() => navigate(`/availability/${store.value}/list`)} className={cx(styles.cta)}>{i18n('Update Availability')}</Button>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

UserStores.propTypes = {
  mode: PropTypes.oneOf(['default', 'availability'])
}

export default UserStores
