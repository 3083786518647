import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './Calendar.module.scss'
import { Layout, Skeleton, UserBar } from 'components'
import { useNavigate, useParams } from 'react-router'
import ViewToggle from '../ViewToggle'
import useI18n from 'common-hooks/useI18n'
import ReactDatePicker from 'react-datepicker'
import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { useLazyFetchWorkingHoursQuery } from 'app/service'
import { useSelector } from 'react-redux'
import { selectToken } from 'features/login/loginSlice'
import HoursModal from './HoursModal'
import { createWeekIdString } from '../utils'
import { selectDefaults } from 'appSlice'

dayjs.extend(weekOfYear);

const PREV_ARROW = <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M9.3248 1.17472C9.78041 1.63033 9.78041 2.36902 9.3248 2.82463L3.14975 8.99967L9.32479 15.1747C9.78041 15.6303 9.78041 16.369 9.32479 16.8246C8.86918 17.2802 8.13049 17.2802 7.67488 16.8246L0.67488 9.82463C0.219267 9.36902 0.219268 8.63033 0.67488 8.17472L7.67488 1.17472C8.13049 0.719105 8.86918 0.719105 9.3248 1.17472Z" fill="#31395F"/> </svg>
const NEXT_ARROW = <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M0.675205 16.8253C0.219593 16.3697 0.219593 15.631 0.675205 15.1754L6.85025 9.00033L0.675204 2.82528C0.219593 2.36967 0.219592 1.63098 0.675204 1.17537C1.13082 0.719755 1.86951 0.719755 2.32512 1.17537L9.32512 8.17537C9.78073 8.63098 9.78073 9.36967 9.32512 9.82528L2.32512 16.8253C1.86951 17.2809 1.13082 17.2809 0.675205 16.8253Z" fill="#31395F"/> </svg>

function Calendar() {
  const { i18n } = useI18n();
  const token = useSelector(selectToken);
  const { store: storeId } = useParams();
  const { country } = useSelector(selectDefaults);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [feetchWorkingHours, { data, isLoading, isUninitialized }] = useLazyFetchWorkingHoursQuery();
  const [selectedDay, setSelectedDay] = useState();

  const fetchAllHours = () => {
    const weeks = new Set();
    const dayjsDay = dayjs();
    const year = dayjsDay.year();
    const startOfMonth = dayjsDay.startOf('month');
    const endOfMonth = dayjsDay.endOf('month');

    for (let date = startOfMonth; date.isBefore(endOfMonth); date = date.add(1, 'day')) {
      weeks.add(date.week());
    }

    feetchWorkingHours({
      storeId,
      country,
      weekIds: Array.from(weeks).map(x => createWeekIdString({ year, week: x }))
    })
  }

  const handleModalClose = () => {
    fetchAllHours();
    setSelectedDay();
  }

  const renderHeader = ({ date, increaseMonth, decreaseMonth }) => (
    <div className={cx(styles.header)}>
      <div className={cx(styles.month)}>{dayjs(date).format('MMMM YYYY')}</div>
      <div className={cx(styles.nav)}>
        <button className={cx(styles.arrow)} type="button" onClick={decreaseMonth}>{PREV_ARROW}</button>
        <button className={cx(styles.arrow)} type="button" onClick={increaseMonth}>{NEXT_ARROW}</button>
      </div>
    </div>
  )

  const renderDay = (day, date) => {
    const dayjsDate = dayjs(date);
    const isPast = dayjsDate.isBefore(dayjs(), 'day');
    const year = dayjsDate.year();
    const week = dayjsDate.week();
    const formattedDay = dayjsDate.format('dddd').toUpperCase();
    const workingHours = data?.customWorkingHours?.[createWeekIdString({ week, year })]?.workingHours.filter(x => x.day === formattedDay);

    return (
      <div className={cx(styles.day, {[styles.past]: isPast})} onClick={isPast ? null : () => setSelectedDay({workingHours, date})}>
        <div className={cx(styles.date)}>{day}</div>
        {(isLoading || isUninitialized) ? (
          <div className={cx(styles.hours)}><Skeleton /></div>
        ) : (
          <div className={cx(styles.hours)}>
            {workingHours?.length ? workingHours.map(hour => (
              <div className={cx(styles.hour)}>{[hour.fromTime.substring(0, 5), hour.toTime.substring(0, 5)].join(' - ')}</div>
            )) : <div className={cx(styles.hour, styles.default)}>{i18n('Uses default')}</div>}
          </div>
        )}
      </div>
    )
  }

  useEffect(() => {
    if (token && country) fetchAllHours()
  }, [currentDate, token, country])

  return (
    <Layout narrow  title={i18n('Edit Availability')}>
      <div className={cx(styles.wrapper)}>
        <UserBar />
        <div className={cx(styles.card)}>
          <div className={cx(styles.toggle)}><ViewToggle current="calendar" /></div>
          <div className={cx(styles.calendar)}><ReactDatePicker onMonthChange={setCurrentDate} formatWeekDay={month => month.substring(0, 3)} selected={null} inline renderCustomHeader={renderHeader} renderDayContents={renderDay} /></div>
        </div>
        {!!selectedDay && <HoursModal {...selectedDay} onClose={handleModalClose} />}
      </div>
    </Layout>
  )
}

Calendar.propTypes = {}

export default Calendar
