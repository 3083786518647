import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'common-hooks/useI18n'
import { Button, SplashScreen } from 'components';

function Success({ onUnderstand }) {
  const { i18n, dir } = useI18n();

  return (
    <SplashScreen dir={dir} title={i18n('Details successfully added')} description={i18n('Thank you for sharing your information. Kindly handover the tablet back to the optometrist.')}>
      <Button onClick={onUnderstand}>{i18n('I Understand')}</Button>
    </SplashScreen>
  )
}

Success.propTypes = {
  onClick: PropTypes.func
}

export default Success
