import useI18n from 'common-hooks/useI18n';
import { SectionNav } from 'components';
import React from 'react'
import { useParams } from 'react-router';

function OrdersNav({ active }) {
  const { i18n } = useI18n();
  const { orderid } = useParams();

  return (
    <SectionNav items={[
      { label: i18n('Enter prescriptions'), to: `/orders/${orderid}` },
      { label: i18n('Order details'), to: `/order-details/${orderid}` },
      { label: i18n('Tracking Details'), to: `/order-details/${orderid}/tracking-details` },
      { label: i18n('Return Orders'), to: `/order-details/${orderid}/return-orders` },
      { label: i18n('Invoices'), to: `/order-details/${orderid}/invoices` },
      { label: i18n('Refund details'), to: `/order-details/${orderid}/refund-details` },
      // { label: i18n('Fulfillment Worflow'), to: `/order-details/${orderid}/fulfillment-workflow` },
    ]} active={active} />
  )
}

export default OrdersNav;
