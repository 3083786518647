import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { useLazyFetchWorkingHoursAllQuery } from 'app/service';
import { Layout, SimpleSelect } from 'components';
import useI18n from 'common-hooks/useI18n';
import cx from 'classnames'
import styles from './../StoreAnalytics.module.scss'
import { useSelector } from 'react-redux';
import { selectToken } from 'features/login/loginSlice';
import { createWeekIdString } from 'pages/availability/utils';
import { selectDefaults } from 'appSlice';
import Table from 'components/table/Table';

dayjs.extend(weekOfYear);

function BookingAvailabilityDashboard() {
  const { current: days } = useRef(['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']);
  const { i18n } = useI18n();
  const { country } = useSelector(selectDefaults);
  const token = useSelector(selectToken);
  const { current: year } = useRef(dayjs().year());
  const { current: currentWeek } = useRef(dayjs().week());
  const [selectedStore, setSelectedStore] = useState('');
  const [availabilitySet, setAvailabilitySet] = useState('yes');
  const [fetchWorkingHoursAll, { data: workingHoursAll = [], isLoading, isUninitialized, isSuccess }] = useLazyFetchWorkingHoursAllQuery();

  const getWeekDates = () => {
    const startOfWeek = dayjs().startOf('week');
    return Array.from({ length: 8 }, (_, i) => startOfWeek.add(i, 'day').format('MMM DD'));
  };

  const weekDates = useMemo(getWeekDates, []);

  const columns = useMemo(() => [
    { Header: 'StoreID', accessor: 'StoreID' },
    { Header: 'Name', accessor: 'Name' },
    { Header: 'Site', accessor: 'Site' },
    ...days.map((day, index) => ({ Header: `${day} (${weekDates[index + 1]})`, accessor: day })),
  ], [days, weekDates]);

  const transformData = (data, isAvailabilitySet) => {
    let filteredDataArray = data.filter(store => !!store.defaultWorkingHours)
    if (isAvailabilitySet === 'no') {
      filteredDataArray = data.filter(store => !store.defaultWorkingHours)
    }
    return filteredDataArray.map(store => {
      const workingHours = store.defaultWorkingHours?.workingHours?.reduce((acc, day) => {
        acc[day.day] = !acc[day.day] ? `${day.fromTime} - ${day.toTime}` : `${acc[day.day]}, ${day.fromTime} - ${day.toTime}`;
        return acc;
      }, {}) ?? {};
      return {
        StoreID: store.storeId,
        Name: store.warehouseDTO.name,
        Site: store.warehouseDTO.site,
        SUNDAY: workingHours.SUNDAY || '-',
        MONDAY: workingHours.MONDAY || '-',
        TUESDAY: workingHours.TUESDAY || '-',
        WEDNESDAY: workingHours.WEDNESDAY || '-',
        THURSDAY: workingHours.THURSDAY || '-',
        FRIDAY: workingHours.FRIDAY || '-',
        SATURDAY: workingHours.SATURDAY || '-'
      };
    });
  };

  const transformedData = useMemo(() => (!isLoading && !isUninitialized) && isSuccess ? transformData(workingHoursAll, availabilitySet) : [], [workingHoursAll, isLoading, isUninitialized, isSuccess, availabilitySet]);

  const filteredData = useMemo(() => {
    if (selectedStore) {
      return transformedData.filter(row => row.Site === selectedStore);
    }
    return transformedData;
  }, [selectedStore, transformedData]);

  const storeOptions = useMemo(() => {
    let sites = Array.from(new Set(transformedData.map(row => row.Site)));
    let optionsArray = sites.map(site => ({ label: site, value: site }))
    optionsArray.unshift({ label: 'Select', value: '' })
    return optionsArray
  }, [transformedData]);

  useEffect(() => {
    if (token && country) fetchWorkingHoursAll({
      country,
      weekIds: [currentWeek].map(x => createWeekIdString({ year, week: x }))
    });
  }, [currentWeek, token, country])

  return (
    <div>
      <h1 className={styles.heading}>{i18n('Eye test booking availability summary')}</h1>
      <div className={styles.filterBar}>
        <SimpleSelect
          options={storeOptions}
          value={selectedStore}
          onChange={setSelectedStore}
          isSearchable
          label={'Select site'}
        />
        <SimpleSelect
          options={[{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }]}
          value={availabilitySet}
          onChange={setAvailabilitySet}
          label={'Is Availability Set?'}
        />
      </div>
      <Table columns={columns} items={filteredData} />
    </div>

  )
}

BookingAvailabilityDashboard.propTypes = {}

export default BookingAvailabilityDashboard
