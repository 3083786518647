import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

// function copyStyles(sourceDoc, targetDoc) {
//   Array.from(sourceDoc.styleSheets).forEach(styleSheet => {
//     if (styleSheet.cssRules) { // true for inline styles
//       const newStyleEl = targetDoc.createElement('style');

//       Array.from(styleSheet.cssRules).forEach(cssRule => {
//         newStyleEl.appendChild(targetDoc.createTextNode(cssRule.cssText));
//       });

//       targetDoc.head.appendChild(newStyleEl);
//     } else if (styleSheet.href) { // true for stylesheets loaded from a URL
//       const newLinkEl = targetDoc.createElement('link');

//       newLinkEl.rel = 'stylesheet';
//       newLinkEl.href = styleSheet.href;
//       targetDoc.head.appendChild(newLinkEl);
//     }
//   });
// }


const Portal = ({ children, containerRef }) => {
  const container = containerRef.current;

  useEffect(() => {
    if (!container) {
      const newContainer = document.createElement('div');
      containerRef.current = newContainer;
      document.body.appendChild(containerRef.current);
    }
    return () => {
      if (containerRef.current) {
        document.body.removeChild(containerRef.current);
        containerRef.current = null;
      }
    };
  }, [containerRef]);

  return container ? ReactDOM.createPortal(children, container) : null;
};

export default Portal;