import React, { useContext, useMemo } from 'react'
import { OrderDetailsContext } from '../order-details/OrderDetails'
import useI18n from 'common-hooks/useI18n';
import { InfoCard } from 'components';
import { convertDataForInfoCard } from '../utils';
import cx from 'classnames'
import styles from '../order-details/OrderDetails.module.scss'
import NoDataAvailable from '../NoDataAvailable';

function TrackingDetails() {
  const { i18n } = useI18n();
  const { trackingDetails = [], isLoading } = useContext(OrderDetailsContext);

  if (!trackingDetails.length && !isLoading) return <NoDataAvailable />

  return trackingDetails.map(item => (
    <div className={cx(styles.section)}>
      <h3>{item.orderNumber}</h3>
      <InfoCard wide data={convertDataForInfoCard(item)} />
      <h4>{i18n('Items')}</h4>
      {item.itemList.map(x => (
        <InfoCard wide data={convertDataForInfoCard(x)} />
      ))}
    </div>
  ));
}

export default TrackingDetails
