import { useEffect, useMemo, useState } from 'react'
import { selectToken } from 'features/login/loginSlice';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useGetTransferOrdersViaRestMutation, useLazyUpdateTransferOrderContactedStatusQuery } from 'app/service';
import useI18n from 'common-hooks/useI18n';
import { useLocation } from 'react-router';
import styles from './Orders.module.scss'
import { Button } from 'components';
import cx from 'classnames';
import { toast } from 'react-toastify';
import { convertBodyToQuery } from 'app/request-transformers';


const DEFAULT_FILTERS = { term: '', status: 'Ready For Customer Pickup', assemblyStatus: '', createdAtFrom: dayjs().subtract(7, 'day').format('YYYY-MM-DD'), createdAtTo: dayjs().format('YYYY-MM-DD'), page: 1 };

function useTransferOrders() {
  const { search } = useLocation();
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [getOrders, { data: currentData, isLoading, isUninitialized, isError, error }] = useGetTransferOrdersViaRestMutation();
  const [updateContactStatus] = useLazyUpdateTransferOrderContactedStatusQuery();
  const [data, setData] = useState([]);
  const token = useSelector(selectToken);
  const [hasMore, setHasMore] = useState(true);
  const { i18n } = useI18n();
  const renderBool = cell => {
    return cell.value ? i18n('Yes') : i18n('No')
  };

  const handleLastCallStatusChange = async (id, status) => {
    const res = await updateContactStatus({ id, callStatus: status });
    if (res?.error?.status > 400) {
      toast.error('Failed to update status');
      return;
    }
    toast.info('Status updated successfully');
    filter();
  }

  const handleExportOrders = async () => {
    const query = buildQuery();
    const url = convertBodyToQuery(query);
    window.open(`${process.env.REACT_APP_MIDDLEWARE_BASE_URL}/api/v1/transfer-order/sync/export?${url}`, '_blank');
  }

  const columns = useMemo(() => [
    { Header: i18n('Store name'), accessor: 'storeName', width: 150, style: { wordWrap: 'break-word' } },
    { Header: i18n('ERP order id'), accessor: 'erpOrderId' },
    { Header: i18n('Order id'), accessor: 'magentoIncrementId', width: 100 },
    { Header: i18n('Customer Name '), accessor: 'customerDTO.name', width: 100 },
    { Header: i18n('Mobile '), accessor: 'customerDTO.mobileNo', width: 100 },
    { Header: i18n('Order date'), accessor: 'orderDate', width: 110 },
    { Header: i18n('Store Delivery Date'), accessor: 'deliveryDate', width: 110 },
    { Header: i18n('Order status'), accessor: 'orderStatus', width: 100 },
    { Header: i18n('Customer Visited Store'), accessor: 'customerVisited', Cell: renderBool },
    { Header: i18n('Last Contact'), accessor: 'lastContactedDate', width: 100 },
    { Header: i18n('Contact Count'), accessor: 'contactCount', width: 60 },
    {
      Header: 'Last Call Status',
      accessor: 'lastCallStatus',
      Cell: ({ row }) => (
        <>
          {row.original.lastContactedDate && <div className={cx(styles.lastCallStatus, { [styles.success]: !!row.original.lastCallStatus, [styles.failure]: !row.original.lastCallStatus })}>{row.original.lastCallStatus ? 'Success': 'Failed'}</div>}
          <div className={styles.twoButton}>
            <Button className={styles.acceptButton} onClick={() => handleLastCallStatusChange(row.original.entityId, true)}>Success</Button>
            <Button className={styles.rejectButton} onClick={() => handleLastCallStatusChange(row.original.entityId, false)}>Fail</Button>
          </div>
        </>
      )
    }
  ], []);

  const buildQuery = () => {
    let customerId
    if (search) {
      const params = new URLSearchParams(search);
      customerId = params.get('customerId')
    }
    return {
      term: filters.term,
      status: filters.status,
      createdAtFrom: filters.createdAtFrom,
      createdAtTo: filters.createdAtTo,
      page: filters.page,
      assemblyStatus: filters.assemblyStatus,
      customerId,
    };
  }

  const filter = () => {
    const query = buildQuery();
    getOrders(query);
  }

  const onFilterReset = () => setFilters(DEFAULT_FILTERS);

  const handleFilterChange = filters => {
    setFilters({ ...filters, page: 1 });
  }

  const handleNextPage = () => {
    setFilters({ ...filters, page: filters.page + 1 });
  }



  useEffect(() => {
    setData(filters.page === 1 ? (currentData || []) : [...data, ...(currentData || [])]);
    setHasMore(currentData?.length);
  }, [currentData])

  useEffect(() => {
    if (token) filter();
  }, [token, filters.assemblyStatus, filters.page, filters.term, filters.status, filters.createdAtFrom, filters.createdAtTo, search])

  return {
    data,
    columns,
    filters,
    onFilterChange: handleFilterChange,
    loading: isLoading,
    isUninitialized,
    onFilterReset,
    handleNextPage,
    hasMore,
    hasError: isError,
    error,
    handleExportOrders
  }
}

export default useTransferOrders
