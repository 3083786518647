import React from 'react'
import { SectionNav } from 'components'
import useI18n from 'common-hooks/useI18n';

function SettingsNav({ active }) {
  const { i18n } = useI18n();

  return (
    <SectionNav active={active}
      items={[
        { label: i18n('Stores'), to: `warehouses` },
        { label: i18n('Users'), to: `users` },
        { label: i18n('Config'), to: `setup` },
      ]}
    />
  )
}

SettingsNav.propTypes = {}

export default SettingsNav
