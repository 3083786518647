import cx from 'classnames'
import styles from './Layout.module.scss'
import logo from './logo.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectUser } from 'features/login/loginSlice'
import Select from 'react-select';
import defaultAvatar from './avatar.svg'
import { Button } from 'components'
import { useCallback, useState } from 'react'
import useI18n from 'common-hooks/useI18n'
import { EG_SWITCHED_USER } from 'common-hooks/useLogin'
import SwitchStore from 'components/SwitchStore/SwitchStore'
import { ROLE_TYPES } from 'pages/users/Roles'
import { persistor } from 'app/store'

const ARROW_DOWN = <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1.25977 0.779297L6.24023 5.75977L11.2207 0.779297" stroke="#31395F" strokeOpacity="0.5" strokeWidth="1.40625" strokeLinecap="round" strokeLinejoin="round"/> </svg>

function StaticLayout({ onSignout, children, backTo, title, narrow, wide, preContent, widest }) {
  const { i18n } = useI18n();
  const [menuOpen, setMenuOpen] = useState(false);
  const { pathname, state } = useLocation();
  const { name, avatar, switched, roleList } = useSelector(selectUser);
  const navigate = useNavigate();
  const loggedInUserRole = roleList?.[0]?.roleName

  const handleMenuToggle = () => setMenuOpen(!menuOpen);

  const renderUserMenu = () => (
    <button className={cx(styles.user)} onClick={handleMenuToggle}>
      <img className={cx(styles.avatar)} src={avatar || defaultAvatar} alt={name} />
      <span className={cx(styles.name)}>
        <span>{name}</span>
        <br />
        <span className={cx(styles.role)}>{loggedInUserRole}</span>
      </span>
      {ARROW_DOWN}
    </button>
  )

  const handleUserMenuChange = ({ value }) => {
    if (value === 'logout') onSignout();
    if (value === 'switch') {
      window.localStorage.removeItem(EG_SWITCHED_USER);
      navigate('/login');
      persistor?.purge?.();
    }
    if (value === 'settings') navigate('/settings/users');
    if (value === 'update-password') navigate('/update-password');
    if (value === 'delivery-management') navigate('/delivery-management/drivers');
    if (value === 'store-analytics') navigate('/store-analytics/eyetest-schedule');
    if (value === 'transfer-order-cs') navigate('/transfer-orders-cs');
    if (value === 'transfer-orders-staff') navigate('/transfer-orders-staff');
    
  }

  const renderUserMenuOptions = useCallback(() => {
    const options = [{ label: i18n('Store analytics'), value: 'store-analytics' }, { label: i18n('Change password'), value: 'update-password' }, { label: i18n('Logout'), value: 'logout' }, ];

    if ([]?.includes(loggedInUserRole)) {
      options.unshift({ label: i18n('Delivery Management'), value: 'delivery-management' });
    }

    if ([ROLE_TYPES.ADMIN, ROLE_TYPES.EG_ADMIN]?.includes(loggedInUserRole)) {
      options.unshift({ label: i18n('Settings'), value: 'settings' });
      options.unshift({ label: i18n('Transfer Orders'), value: 'transfer-order-cs' });
    }
    if ([ROLE_TYPES.EG_OPTOM,ROLE_TYPES.EG_USER, ROLE_TYPES.EG_STORE]?.includes(loggedInUserRole)) {
      
      options.unshift({ label: i18n('Transfer Orders'), value: 'transfer-orders-staff' });
    }
    
    if (switched) options.unshift({ label: i18n('Switch user'), value: 'switch' });
    return options;
  }, [switched, roleList])

  const close = (
    <Button size="small" theme="secondary" onClick={typeof backTo === 'function' ? backTo : () => {}} to={!(typeof backTo === 'function') && (state?.backTo || backTo)} className={cx(styles.close)}>{i18n('Close')}</Button>
  )

  const user = (
    <div className={cx(styles['header-col'])}>
      <SwitchStore />
      <Select
        isSearchable={false}
        unstyled
        onMenuClose={handleMenuToggle}
        onMenuOpen={handleMenuToggle}
        menuIsOpen={menuOpen}
        components={{ Control: renderUserMenu }}
        onChange={handleUserMenuChange}
        classNames={{
          menu: () => cx(styles['select-menu']),
          option: state => cx(styles['select-option'], { [styles.selected]: state.isSelected }),
        }}
        options={renderUserMenuOptions()}
      />
      {pathname !== '/' && close}
    </div>
  )

  return (
    <>
      <header role="banner" className={cx(styles.header)}>
        <div className={cx(styles.container, styles['header-container'])}>
          <div className={cx(styles['header-col'])}>
            <Link to="/"><img className={cx(styles.logo)} src={logo} alt="Eyewa EG tool" /></Link>
          </div>
          {title && <h1 className={cx(styles.title, styles['header-col'])}>{title}</h1>}
          {user}
        </div>
      </header>
      {preContent}
      <main role="main" className={cx(styles.main, styles.container, {[styles.narrow]: narrow, [styles.wide]: wide, [styles.widest]: widest})}>{children}</main>
    </>
  )
}

export default StaticLayout;
