import React from 'react'
import cx from 'classnames';
import styles from './SwitchStore.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, updateLoginData } from 'features/login/loginSlice';
import SimpleSelect from 'components/form-elements/SimpleSelect';
import { selectDefaults } from 'appSlice';
import useI18n from 'common-hooks/useI18n';
import { useLazySwitchStoreQuery } from 'app/service';
import { Link } from 'react-router-dom';

function SwitchStore() {
  const { warehouses, roles } = useSelector(selectUser);
  const { storeName } = useSelector(selectDefaults);
  const { i18n } = useI18n();
  const [switchStore] = useLazySwitchStoreQuery();
  const dispatch = useDispatch();

  if (!warehouses) return null;

  if (warehouses.length === 1) return (
    <div className={cx(styles.wrapper)}>
      <p className={cx(styles.label)}>{i18n('Store')}</p>
      <p className={cx(styles.value)}>{warehouses?.[0]?.name}</p>
    </div>
  );

  const selected = warehouses?.find?.(x => x.storeCode === storeName) || {};

  const handleChange = async (storeCode) => {
    const res = await switchStore(storeCode);
    dispatch(updateLoginData(res?.data));
    window.location.reload();
  }

  return (
    <div className={cx(styles.wrapper)}>
      <p className={cx(styles.label)}>{i18n('Store')}</p>
      <p className={cx(styles.value)}>
        {warehouses?.find?.(x => x.default)?.name}
        {warehouses?.length > 1 && !roles.includes('EG_STORE') && <Link to="/switch-store">{i18n('Switch')}</Link>}
      </p>
    </div>
  )
}

export default SwitchStore;
