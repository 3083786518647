import React, { useEffect } from 'react'
import { FormSectionTitle, Layout, Message } from 'components'
import { useNavigate, useParams } from 'react-router'
import useI18n from 'common-hooks/useI18n';
import { FormProvider, useForm } from 'react-hook-form';
import CustomerFields from '../../CustomerFields';
import { selectToken } from 'features/login/loginSlice';
import { useSelector } from 'react-redux';
import { useCreateCustomerMutation, useLazyGetCustomerInfoQuery } from 'app/service';

function Info() {
  const { email } = useParams();
  const methods = useForm({ mode: 'all' });
  const { reset } = methods;
  const token = useSelector(selectToken);
  const navigate = useNavigate();
  const { i18n } = useI18n();
  const [getCustomerInfo, { data = {}, isLoading, isUninitialized, error }] = useLazyGetCustomerInfoQuery();
  const [createCustomer, { data: submittedData, isLoading: isSubmitting }] = useCreateCustomerMutation();

  useEffect(() => {
    if (data.fullName) reset(data);
  }, [data, reset])

  useEffect(() => {
    if (token) getCustomerInfo(email);
  }, [token, email, getCustomerInfo]);

  useEffect(() => {
    const id = submittedData?.saveCustomerPrescriptions?.id;
    if (id) navigate(`/customers/${id}/prescriptions${data.prescriptions.length ? '' : '/add'}`);
  }, [submittedData]);

  return (
    <Layout narrow onClose={() => navigate(`/customers/${email}/prescriptions`)} title={i18n('Edit Customer Information')}>
      <FormSectionTitle>{i18n('Customer Information')}</FormSectionTitle>
      <FormProvider {...methods}><CustomerFields edit onSubmit={createCustomer} submitting={isSubmitting} loading={isLoading || isUninitialized || !!error} /></FormProvider>
      {error && <Message error message={i18n('Customer does not exist')} cta={i18n('Back to home')} onClick={() => navigate('/')} />}
    </Layout>
  )
}

Info.propTypes = {}

export default Info
