import React, { useEffect, useState } from 'react'
import HomeCard from './HomeCard'
import { BoxLink, PredictiveSearch } from 'components'
import orderIcon from './icons/order.svg'
import add from './icons/add.svg'
import useI18n from 'common-hooks/useI18n'
import { useLazyGetNewestOrdersQuery, useLazyGetPredictedOrdersQuery } from 'app/service'
import { useNavigate } from 'react-router'
import cx from 'classnames'
import styles from './Home.module.scss'
import TabularSummary from 'components/tabular-summary/TabularSummary'
import { useSelector } from 'react-redux'
import { selectToken } from 'features/login/loginSlice'
import { isOrderValid } from 'pages/orders/utils'

function OrderCard() {
  const { i18n } = useI18n();
  const token = useSelector(selectToken)
  const navigate = useNavigate();
  const [focused, setFocused] = useState(false);
  const [results, setResults] = useState([]);
  const [getPredictedOrders, { isLoading }] = useLazyGetPredictedOrdersQuery();
  const [getNewestOrders, { data: newestOrders = [] }] = useLazyGetNewestOrdersQuery();
  const handleFocus = () => setFocused(true);
  const handleBlur = () => setFocused(false);
  const handleSearch = async term => {
    term = term.trim()
    if (isOrderValid(term)) {
      navigate(`/orders/${term}`);
    } else {
      if (term) {
        const { data } = await getPredictedOrders(term);
        setResults(data || []);
      } else {
        setResults([]);
      }
    }
  }

  const handleSelect = id => {
    navigate(`/orders/${id}`);
  }

  useEffect(() => {
    if (token) getNewestOrders();
  }, [token])

  return (
    <HomeCard focused={focused} to="order-grid" icon={orderIcon} title={i18n('Order Details')} description={i18n('You can find the previously given orders here.')}>
      <PredictiveSearch
        onSelect={handleSelect}
        loading={isLoading}
        results={results}
        onSearch={handleSearch}
        placeholder={i18n('Search order')}
        onFocus={handleFocus}
        onBlur={handleBlur}
        noResultsMessage={i18n('No orders found, please try again in a few minutes')}
      />
      <div className={cx(styles.cols)}>
        <BoxLink className={cx(styles.cta)} icon={add} label={i18n('Delivery calculator')} to="/delivery-promise" />
        <div className={cx(styles['newest-orders'])}>
          {newestOrders.map(order => (
            <TabularSummary
              to={`orders/${order.id}`}
              className={cx(styles['newest-order'])}
              title={order.id}
              key={order.id}
              items={[
                { term: i18n('Full Name'), def: order.name },
                { term: i18n('Order Date'), def: order.date },
                { term: i18n('Fullfilment'), def: order.fulfilmentCentre?.name },
              ]}
            />
          ))}
        </div>
      </div>
    </HomeCard>
  )
}

OrderCard.propTypes = {}

export default OrderCard
