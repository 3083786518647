import { useEffect, useState } from 'react';
import en from '../i18n/en.json';
import ar from '../i18n/ar.json';
import { selectLanguage, updateLaguage } from '../appSlice';
import { useDispatch, useSelector } from 'react-redux';

const languages = { en, ar }

function useI18n(currentLanguage = 'en') {
  const [labels, setLabels] = useState(languages[currentLanguage]);
  const language = useSelector(selectLanguage);
  const dispatch = useDispatch();

  const updateRootLanguage = lang => {
    document.documentElement.lang = lang;
  }

  const i18n = str => {
    return labels[str] || str;
  }

  const update = lang => {
    dispatch(updateLaguage(lang))
  }

  useEffect(() => {
    updateRootLanguage(language);
    setLabels(languages[language]);
  }, [language])

  return { i18n, update, dir: language === 'ar' ? 'rtl' : 'ltr' };
}

export default useI18n;
