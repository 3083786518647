import React, { useContext } from 'react'
import { OrderDetailsContext } from './order-details/OrderDetails'
import useI18n from 'common-hooks/useI18n';
import cx from 'classnames'
import styles from './order-details/OrderDetails.module.scss'
import NoDataAvailable from './NoDataAvailable';
import InvoiceForm from './InvoiceForm';

function Invoices() {
  const { i18n } = useI18n();
  const {invoices = [], erpOrderId, isLoading, error} = useContext(OrderDetailsContext);

  if (isLoading) return
  if (invoices.length <= 0 && !isLoading) return <NoDataAvailable message={i18n('Slack Musharib and Shoaib')} error={error} />

  const handleDownloadClick = (e, url, index) => {
    e.preventDefault();
    var link = document.createElement('a');
    link.href = url;
    link.target = '_blank'
    link.download =  `${erpOrderId}_${index}.pdf`;
    link.dispatchEvent(new MouseEvent('click'));
  }

  return (
    <div className={cx(styles.section)}>
      <h4>{i18n('Invoices')}</h4>
      <div>
        {invoices.map((invoice, index) => (
          <a key={index} className={styles.link} href={invoice} onClick={e => handleDownloadClick(e, invoice, index)} rel="noreferrer">{i18n('Download invoice') + ' ' + (index + 1)}</a>
        ))}
      </div>
      <h4>{i18n('Modify Invoice?')}</h4>
      <InvoiceForm />
    </div>
  );
}

export default Invoices
