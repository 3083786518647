import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './HoursModal.module.scss'
import timeStyles from '../list/List.module.scss'
import useI18n from 'common-hooks/useI18n'
import dayjs from 'dayjs'
import { useSetWorkingHoursMutation } from 'app/service'
import { useParams } from 'react-router'
import { Button } from 'components'
import { createWeekIdString } from '../utils'
import { selectDefaults } from 'appSlice'
import { useSelector } from 'react-redux'

const TRASH_ICON = <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M13.5 3H12.5V14.5C12.5 14.7083 12.4609 14.9036 12.3828 15.0859C12.3047 15.2682 12.1979 15.4271 12.0625 15.5625C11.9271 15.6979 11.7682 15.8047 11.5859 15.8828C11.4036 15.9609 11.2083 16 11 16H3C2.79167 16 2.59635 15.9609 2.41406 15.8828C2.23177 15.8047 2.07292 15.6979 1.9375 15.5625C1.80208 15.4271 1.69531 15.2682 1.61719 15.0859C1.53906 14.9036 1.5 14.7083 1.5 14.5V3H0.5V2H4.5V1C4.5 0.859375 4.52604 0.729167 4.57812 0.609375C4.63021 0.489583 4.70182 0.384115 4.79297 0.292969C4.88411 0.201823 4.98958 0.130208 5.10938 0.078125C5.22917 0.0260417 5.35938 0 5.5 0H8.5C8.64062 0 8.77083 0.0260417 8.89062 0.078125C9.01042 0.130208 9.11589 0.201823 9.20703 0.292969C9.29818 0.384115 9.36979 0.489583 9.42188 0.609375C9.47396 0.729167 9.5 0.859375 9.5 1V2H13.5V3ZM5.5 2H8.5V1H5.5V2ZM11.5 3H2.5V14.5C2.5 14.6354 2.54948 14.7526 2.64844 14.8516C2.7474 14.9505 2.86458 15 3 15H11C11.1354 15 11.2526 14.9505 11.3516 14.8516C11.4505 14.7526 11.5 14.6354 11.5 14.5V3ZM5.5 13H4.5V5H5.5V13ZM7.5 13H6.5V5H7.5V13ZM9.5 13H8.5V5H9.5V13Z" fill="#31395F"/> </svg>
const PLUS_ICON = <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M7 1V12.3137" stroke="#31395F" strokeWidth="2" strokeLinecap="round"/> <path d="M12.6569 6.65685H1.34314" stroke="#31395F" strokeWidth="2" strokeLinecap="round"/> </svg>

function HoursModal({workingHours = [], date, onClose}) {
  const { current: formattedDay } = useRef(dayjs(date).format('dddd'));
  const { current: defaultHour } = useRef({ day: formattedDay.toUpperCase(), fromTime: '', toTime: '', available: true });
  const {i18n} = useI18n();
  const { current: weekId } = useRef(createWeekIdString({year: dayjs(date).year(), week: dayjs(date).week()}))
  const { store: storeId } = useParams();
  const { country } = useSelector(selectDefaults);
  const [hours, setHours] = useState(workingHours);
  const [setWorkingHours] = useSetWorkingHoursMutation();
  const [loading, setLoading] = useState(false);
  const {current: commonPayload} = useRef({ storeId, weekId });
  const handleDelete = idx => {
    const clone = JSON.parse(JSON.stringify(hours));
    if (clone[idx].fromTime && clone[idx].toTime) {
      const hoursToSend = clone.map(x => ({...x, available: true}));
      hoursToSend[idx].available = false;

      setWorkingHours({
        ...commonPayload,
        country,
        hours: hoursToSend
      })
    }
    clone.splice(idx, 1);
    setHours(clone);
  }

  const handleChange = (e, idx) => {
    const clone = JSON.parse(JSON.stringify(hours));
    clone[idx] = {...clone[idx], [e.target.name]: `${e.target.value}:00`};
    if (clone[idx].fromTime && clone[idx].toTime) {
      setWorkingHours({
        ...commonPayload,
        country,
        hours: clone
      })
    }
    setHours(clone);
  }

  const handleAddHourSlot = () => {
    const clone = JSON.parse(JSON.stringify(hours));
    clone.push(defaultHour);
    setHours(clone);
  }

  const handleDefault = async () => {
    setLoading(true);
    await setWorkingHours({ storeId: commonPayload.storeId, hours, country })
    await setWorkingHours({ ...commonPayload, country, hours: hours.map(x => ({ ...x, available: false })) })
    onClose();
  }

  return (
    <div className={cx(styles.overlay)}>
      <div className={cx(styles.modal)}>
        <h4 className={cx(styles.title)}>{i18n(`Override availability for ${formattedDay}`)}</h4>
        <div className={cx(styles.hours)}>
          <div className={cx(timeStyles.times)}>
            {hours.length ? hours.map((hour, j) => (
              <div className={cx(timeStyles.time)} key={j}>
                <input className={cx(timeStyles['time-input'])} type="time" step="1800" name="fromTime" onChange={e => handleChange(e, j)} value={hour.fromTime} />
                <div className={cx(timeStyles.divider)}>-</div>
                <input className={cx(timeStyles['time-input'])} type="time" step="1800" name="toTime" onChange={e => handleChange(e, j)} value={hour.toTime} />
                <button className={cx(timeStyles.delete)} onClick={() => handleDelete(j)}>{TRASH_ICON}</button>
              </div>
            )) : <span className={cx(timeStyles['day-message'], styles['day-message'])}>{i18n('Unavailable')}</span>}
          </div>
          <button className={cx(timeStyles.add)} onClick={handleAddHourSlot}>{PLUS_ICON}</button>
        </div>
        <div className={cx(styles.actions)}>
          <Button loading={loading} disabled={!hours.length} onClick={handleDefault} theme="secondary" className={cx(styles.action)}>{i18n(`Set default for every ${formattedDay}`)}</Button>
          <Button onClick={onClose} className={cx(styles.action)}>{i18n('Done')}</Button>
        </div>
      </div>
    </div>
  )
}

HoursModal.propTypes = {
  onClose: PropTypes.func,
  date: PropTypes.object,
  workingHours: PropTypes.arrayOf(PropTypes.shape({
    day: PropTypes.string,
    fromTime: PropTypes.string,
    toTime: PropTypes.string,
    available: PropTypes.bool
  }))
}

export default HoursModal
