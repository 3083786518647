import React, { useContext } from 'react'
import { OrderDetailsContext } from '../order-details/OrderDetails'
import useI18n from 'common-hooks/useI18n';
import { InfoCard } from 'components';
import { convertDataForInfoCard } from '../utils';
import cx from 'classnames'
import styles from '../order-details/OrderDetails.module.scss'
import { Link } from 'react-router-dom';
import NoDataAvailable from '../NoDataAvailable';

function ProductDetails() {
  const { i18n } = useI18n();
  const data = useContext(OrderDetailsContext);

  const compileRootData = () => {
    const root = JSON.parse(JSON.stringify(data));
    delete root.addressDTOS;
    delete root.customerDTO;
    delete root.childOrders;
    delete root.orderItems;
    delete root.paymentDetails;
    delete root.trackingDetails;
    delete root.refundDetails
    delete root.error

    return root;
  }

  const compileAddressData = () => {
    if (!data.addressDTOS?.length) return [];
    const addresses = JSON.parse(JSON.stringify(data.addressDTOS));
    addresses.forEach(x => {
      x.latLong = [x.latitude, x.longitude].filter(Boolean).join(', ');
      delete x.latitude;
      delete x.longitude;
    })
    return addresses;
  }

  const compilePrescriptions = (item) =>({
    'id': item.lensData?.id,
    'lensType': item.lensData?.lens_type,
    'addOnColor': item.lensData?.add_on_color,
    'addOnPackage': item.lensData?.add_on_package,
    'addOn': [item.lensData?.tinting, item.lensData?.transition, item.lensData?.blueLight].filter(Boolean).join(', '),
    'corridor': item.lensData?.corridor,
    'index': item.lensData?.index,
  })

  const renderEye = (item, eye) => {
    return {
      [i18n('Sphere (SPH)')]: item?.lensData?.[`${eye}Lens`]?.[`${eye}_sphere`],
      [i18n('Cylinder (CYL)')]: item?.lensData?.[`${eye}Lens`]?.[`${eye}_cylinder`],
      [i18n('Axis (AXIS)')]: item?.lensData?.[`${eye}Lens`]?.[`${eye}_axis`],
      [i18n('Pupil Distance (IPD)')]: item?.lensData?.[`${eye}Lens`]?.[`${eye}_pupil_diameter`],
      [i18n('Additional Power (ADD)')]: item?.lensData?.[`${eye}Lens`]?.[`${eye}_additional_power`],
      [i18n('Segment Height (SH)')]: item?.lensData?.[`${eye}Lens`]?.[`${eye}_segment_height`],
    }
  }

  const childOrders = data.childOrders?.length ? (
    <>
      <h3>{i18n('Children')}</h3>
      <ul className={styles.children}>
        {data.childOrders?.map(x => (
          <li className={styles.child}><Link className={styles['child-link']} to={`/order-details/${x.magentoIncrementId || x.erpOrderId}`}>{x.magentoIncrementId || x.erpOrderId}</Link></li>
        ))}
      </ul>
    </>
  ) : null;

  return data.isLoading || data.magentoIncrementId || data.erpOrderId ? (
    <>
      <h3>{i18n('Customer info')}</h3>
      <InfoCard wide data={convertDataForInfoCard(data.customerDTO)} />
      <h3>{i18n('Addresses')}</h3>
      {compileAddressData().map(x => <InfoCard wide data={convertDataForInfoCard(x)} />)}
      <h3>{i18n('Payment details')}</h3>
      {data.paymentDetails?.map(x => <InfoCard wide data={convertDataForInfoCard(x)} />)}
      <h3>{i18n('Other details')}</h3>
      <InfoCard wide data={convertDataForInfoCard(compileRootData(data))} />
      <h3>{i18n('Prescriptions')}</h3>
      {data?.orderItems?.map(x => (
        <>
          <h4>{x.ProductName} {!x.prescriptionCompatible && <span className={cx(styles['no-prescriptions'])}>{i18n('No prescriptions')}</span>}</h4>
          {x.prescriptionCompatible ? (
            <div className={cx(styles.prescriptions)}>
              <InfoCard title={i18n('Right eye (OD)')} data={renderEye(x, 'right')} />
              <InfoCard title={i18n('Left eye (OS)')} data={renderEye(x, 'left')} />
              <InfoCard wide={!x.prescriptionCompatible} className={cx(styles['additional-details'])} title={i18n('Lens details')} data={convertDataForInfoCard(compilePrescriptions(x))} />
            </div>
          ) : null}
        </>
      ))}
      {childOrders}
    </>
  ) : <NoDataAvailable error={data.isLoading ? {} : data?.error} />;
}

export default ProductDetails
