import { useEffect, useMemo, useState } from 'react'
import { selectToken, selectUser } from 'features/login/loginSlice';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useGetOrdersViaRestMutation } from 'app/service';
import useI18n from 'common-hooks/useI18n';
import { useLocation } from 'react-router';
import styles from './Review.module.scss'

const DEFAULT_FILTERS = { term: '', storeName: '', status: '', assemblyStatus: '', createdAtFrom: '2022-04-05', createdAtTo: dayjs().format('YYYY-MM-DD'), page: 1 };

function useReview() {
  const { pathname, search } = useLocation();
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const { warehouses } = useSelector(selectUser);
  const [getOrders, { data: currentData, isLoading, isUninitialized, isError, error }] = useGetOrdersViaRestMutation();
  const [data, setData] = useState([]);
  const token = useSelector(selectToken);
  const [hasMore, setHasMore] = useState(true);
  const { i18n } = useI18n();
  const renderBool = cell => {
    return cell.value ? i18n('Yes') : i18n('No')
  };

  const renderLink = cell => {
    const base = window.location.href.split(pathname)[0];
    return <a className={styles.link} href={`${base}/orders/${cell.row.original.magentoIncrementId}`}>{cell.value}</a>
  }
  const columns = useMemo(() => [
    { Header: i18n('Title'), accessor: 'title' },
    { Header: i18n('Details'), accessor: 'detail'},
    { Header: i18n('Nickname'), accessor: 'nickName'},
    { Header: i18n('Rating'), accessor: 'rating'},
    { Header: i18n('Status'), accessor: 'status' },

  ], []);

  const buildQuery = () => {
    let customerId
    if (search) {
      const params = new URLSearchParams(search);
      customerId = params.get('customerId')
    }
    return {
      term: filters.term,
      storeName: filters.storeName,
      status: filters.status,
      createdAtFrom: filters.createdAtFrom,
      createdAtTo: filters.createdAtTo,
      page: filters.page,
      assemblyStatus: filters.assemblyStatus,
      customerId,
    };
  }

  const filter = () => {
    const query = buildQuery();
    getOrders(query);
  }

  const onFilterReset = () => setFilters(DEFAULT_FILTERS);

  const handleFilterChange = filters => {
    setFilters({...filters, page: 1});
  }

  const handleNextPage = () => {
    setFilters({...filters, page: filters.page + 1 });
  }

  useEffect(() => {
    setFilters({ ...filters, storeName: warehouses?.find?.(x => x.default)?.storeCode });
  }, [warehouses]);

  useEffect(() => {
    setData(filters.page === 1 ? (currentData || []) : [...data, ...(currentData || [])]);
    setHasMore(currentData?.length);
  }, [currentData])

  useEffect(() => {
    if (token) filter();
  }, [token, filters.assemblyStatus, filters.page, filters.term, filters.status, filters.storeName, filters.createdAtFrom, filters.createdAtTo, search])

  return {
    data,
    columns,
    filters,
    onFilterChange: handleFilterChange,
    loading: isLoading,
    isUninitialized,
    onFilterReset,
    handleNextPage,
    hasMore,
    hasError: isError,
    error
  }
}

export default useReview
