
export const validate = (stepData, stepNumber ) => {
  switch (stepNumber) {
    case 1: {
      if (['Glasses', 'Contact Lenses', 'Both', 'None'].includes(stepData?.useGlasses)) {
        return false
      }
      return { useGlasses: 'Required *' }
    }

    case 2: {
      let keys = Object.keys(stepData)
      let errorObj = {}
      keys.forEach(k => {
        if(!k.includes('duration') && stepData[k]) {
          if (k === 'None') return;
          if (stepData[`${k}-duration`] < 0) {
            errorObj[`${k}-duration`] = 'Value should be > 0'
          } else if (!stepData[`${k}-duration`]) {
            errorObj[`${k}-duration`] = 'Duration is required *'
          }
        }
      })
      if (keys.length === 0) {
        errorObj['button'] = 'Please select at least one symptom'
      }
      if(!Object.values(stepData).reduce((accumulator, currentValue) => accumulator || currentValue, false)) {
        errorObj['button'] = 'Please select at least one symptom'
      }
      if (Object.keys(errorObj).length > 0) {
        return errorObj
      }
      return false
    }

    case 3: {
      let errorObj = {}
      if(!Object.values(stepData).reduce((accumulator, currentValue) => accumulator || currentValue, false)) {
        errorObj['button'] = 'Please select at least one illness'
      }
      if (Object.keys(errorObj).length > 0) {
        return errorObj
      }
      return false
    }

    case 4: {
      let occupation = stepData.occupation
      let errorObj = {}
      if (!occupation?.trim()) {
        errorObj['occupation'] = 'Required *'
      }
      if (Object.keys(errorObj).length > 0) {
        return errorObj
      }
      return false
    }

    case 5: {
      let errorObj = {}
      if(!Object.values(stepData).reduce((accumulator, currentValue) => accumulator || currentValue, false)) {
        errorObj['button'] = 'Please select at least one treatment'
      }
      if (Object.keys(errorObj).length > 0) {
        return errorObj
      }
      return false
    }

    case 6: {
      let hobbies = stepData.hobbies
      let errorObj = {}
      if (!hobbies?.trim()) {
        errorObj['hobbies'] = 'Required *'
      }
      if (Object.keys(errorObj).length > 0) {
        return errorObj
      }
      return false
    }

    default: {
      return false
    }
  } 
}