import { LENS_TYPES } from "common-hooks/useFields";

export const getInvalidProductsMessage = (product, i18n) => {
  let message = [];

  const checkIfNull = () => {
    if (product.lensType === LENS_TYPES.PLAN_O || !Object.keys(LENS_TYPES).map(x => LENS_TYPES[x]).includes(product.lensType)) return false;
    const nonZeroLeft = Object.keys(product.left).find(k => product.left[k] !== 0);
    if (nonZeroLeft) return false;
    const nonZeroRight = Object.keys(product.right).find(k => product.right[k] !== 0)
    if (nonZeroRight) return false;
    return true;
  }

  const checkIfIPD = () => {
    if (!product.lensType) return false;

    if (product.lensType !== LENS_TYPES.PLAN_O && (!product.right.pupilDiameter || !product.left.pupilDiameter)) {
      return true;
    }

    return false;
  }

  const checkIfColor = () => {
    if ((product.addOn2 || product.addOn3) && !product.addOnColor) {
      return true;
    }

    if (((product.lensType === LENS_TYPES.SINGLE_VISION || product.lensType === LENS_TYPES.PROGRESSIVE) && product.addOn4.includes('PhotoFusion')) && !product.addOnColor) {
      return true;
    }

    return false;
  }

  if (checkIfNull(product)) message.push(i18n('Please input prescription details'));
  if (checkIfIPD(product)) message.push(i18n('Product must have an IPD value'));
  if (checkIfColor(product)) message.push(i18n('Please select Add on Color'));

  return [...new Set(message)];
}

export const expandAddons = (addons) => {
  return addons.map(addon => {
    switch (addon.toUpperCase()) {
      case 'BL':
        return 'Blue Light';
      case 'TR':
        return 'Photochromic';
      case 'TN':
        return 'Tinting';
      case 'TS':
        return 'Transition';
      case 'MOY':
        return 'Mioeye';
      case 'OFFICE':
        return 'Office';
      case 'LT01':
        return 'Office 1,3M';
      case 'LT02':
        return 'Office 2M';
      case 'LT03':
        return 'Office 4M';
      default:
        return addon;
    }
  })
}
