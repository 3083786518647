import { selectDefaults, selectExternalDoctors } from "appSlice";
import { Checkbox, Countries, Genders, Input, Languages, Phone, RadioGroup, SimpleSelect, Textarea } from "components";
import { useEffect, useMemo } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import useI18n from "./useI18n";
import calculateIndex from "app/index-calculator";
import { isTocOrder, isTocPackage } from "app/utils";

export const LENS_SHAPES = [
  { label: "Clarivo Cateye Opera-48", value: "Clarivo Cateye Opera-48" },
  { label: "Clarivo Rectangular Mayfair-48", value: "Clarivo Rectangular Mayfair-48" },
  { label: "Clarivo Rectangular Chelsea-48", value: "Clarivo Rectangular Chelsea-48" },
  { label: "Clarivo Round Belluno-48", value: "Clarivo Round Belluno-48" },
  { label: "Clarivo Round Cadore-48", value: "Clarivo Round Cadore-48" },
  { label: "Clarivo Oversize Mitte-52", value: "Clarivo Oversize Mitte-52" },
  { label: "Clarivo Oversize Altona-54", value: "Clarivo Oversize Altona-54" },
  { label: "Clarivo Square Soho-48", value: "Clarivo Square Soho-48" },
  { label: "Clarivo Square Tribeca-48", value: "Clarivo Square Tribeca-48" },
  { label: "Clarivo Cateye Opera-52", value: "Clarivo Cateye Opera-52" },
  { label: "Clarivo Rectangular Mayfair-56", value: "Clarivo Rectangular Mayfair-56" },
  { label: "Clarivo Rectangular Chelsea-54", value: "Clarivo Rectangular Chelsea-54" },
  { label: "Clarivo Geometric Ginza-54", value: "Clarivo Geometric Ginza-54" },
  { label: "Clarivo Round Cadore-54", value: "Clarivo Round Cadore-54" },
  { label: "Clarivo Oversize Mitte-58", value: "Clarivo Oversize Mitte-58" },
  { label: "Clarivo Oversize Altona-58", value: "Clarivo Oversize Altona-58" },
  { label: "Clarivo Square Soho-54", value: "Clarivo Square Soho-54" },
  { label: "Clarivo Square Tribeca-54", value: "Clarivo Square Tribeca-54" },
  { label: "Clarivo Cateye Passy-50", value: "Clarivo Cateye Passy-50" },
  { label: "Clarivo Geometric Ginza-48", value: "Clarivo Geometric Ginza-48" },
  { label: "Clarivo Geometric Shibuya-48", value: "Clarivo Geometric Shibuya-48" },
  { label: "Clarivo Cateye Passy-54", value: "Clarivo Cateye Passy-54" },
  { label: "Clarivo Geometric Shibuya-52", value: "Clarivo Geometric Shibuya-52" },
  { label: "Clarivo Round Belluno-54", value: "Clarivo Round Belluno-54" },
  { label: "Clarivo Oversize Mitte-58", value: "Clarivo Oversize Mitte-58" },
  { label: "Clarivo Oversize Altona-58", value: "Clarivo Oversize Altona-58" },
  { label: "Clarivo Square Soho-54", value: "Clarivo Square Soho-54" },
  { label: "Clarivo Square Tribeca-54", value: "Clarivo Square Tribeca-54" },
  { label: "Clarivo Square Soho-50", value: "Clarivo Square Soho-50" },
  { label: "Clarivo Square Tribeca-50", value: "Clarivo Square Tribeca-50" },
  { label: "Clarivo Cateye Opera-54", value: "Clarivo Cateye Opera-54" },
  { label: "Clarivo Cateye Opera-50", value: "Clarivo Cateye Opera-50" },
  { label: "Clarivo Rectangular Mayfair-54", value: "Clarivo Rectangular Mayfair-54" },
  { label: "Clarivo Rectangular Mayfair-50", value: "Clarivo Rectangular Mayfair-50" },
  { label: "Clarivo Geometric Ginza-52", value: "Clarivo Geometric Ginza-52" },
  { label: "Clarivo Chealsea-50", value: "Clarivo Chealsea-50" },
  { label: "Clarivo Round Cadore-50", value: "Clarivo Round Cadore-50" },
  { label: "Clarivo Chealsea-54", value: "Clarivo Chealsea-54" },
  { label: "Clarivo Round Cadore-52", value: "Clarivo Round Cadore-52" },
  { label: "Clarivo Round Belluno-50", value: "Clarivo Round Belluno-50" },
  { label: "Clarivo Oversize Altone-52", value: "Clarivo Oversize Altone-52" },
  { label: "Clarivo Ottesen-56", value: "Clarivo Ottesen-56" },
  { label: "Clarivo Ottesen-50", value: "Clarivo Ottesen-50" },
  { label: "Clarivo Nerima-54", value: "Clarivo Nerima-54" },
  { label: "Clarivo Nerima-50", value: "Clarivo Nerima-50" },
  { label: "Clarivo River Gauche-56", value: "Clarivo River Gauche-56" },
  { label: "Clarivo River Gauche-54", value: "Clarivo River Gauche-54" },
  { label: "Clarivo Marais-50", value: "Clarivo Marais-50" },
  { label: "Clarivo Marais-54", value: "Clarivo Marais-54" },
  { label: "Clarivo Geometric Minato-50", value: "Clarivo Geometric Minato-50" },
  { label: "Clarivo Geometric Minato-54", value: "Clarivo Geometric Minato-54" },
  { label: "Clarivo Square Manhattan-54", value: "Clarivo Square Manhattan-54" },
  { label: "Clarivo Square Manhattan-50", value: "Clarivo Square Manhattan-50" },
  { label: "Clarivo Square Greenwich-54", value: "Clarivo Square Greenwich-54" },
  { label: "Clarivo Cateye Montmarte-54", value: "Clarivo Cateye Montmarte-54" },
  { label: "Clarivo Cateye Montmarte-50", value: "Clarivo Cateye Montmarte-50" },
  { label: "Clarivo Square Greenwich-50", value: "Clarivo Square Greenwich-50" },
  { label: "Clarivo Oversiz Mitte-54", value: "Clarivo Oversiz Mitte-54" },
]

export const LENS_SHAPES_TOC = [
  { label: "Cateye Passy-50", value: "Cateye Passy-50" },
  { label: "Cateye Passy-54", value: "Cateye Passy-54" },
  { label: "Rectangular Mayfair-50", value: "Rectangular Mayfair-50" },
  { label: "Rectangular Mayfair-56", value: "Rectangular Mayfair-56" },
  { label: "Geometric Ginza-48", value: "Geometric Ginza-48" },
  { label: "Geometric Ginza-52", value: "Geometric Ginza-52" },
  { label: "Round Belluno-50", value: "Round Belluno-50" },
  { label: "Round Belluno-54", value: "Round Belluno-54" },
  { label: "Oversize Ottensen-50", value: "Oversize Ottensen-50" },
  { label: "Square Greenwich-50", value: "Square Greenwich-50" },
  { label: "Square Greenwich-54", value: "Square Greenwich-54" },
]

export const RELATIONSHIP_TYPES = [
  { label: 'Self', value: 'self' },
  { label: 'Husband', value: 'husband' },
  { label: 'Wife', value: 'wife' },
  { label: 'Son', value: 'son' },
  { label: 'Daughter', value: 'daughter' },
  { label: 'Sibling', value: 'sibling' },
  { label: 'Relative', value: 'relative' }
]

export const INDEX = [
  { label: 'Select', value: '' },
  { label: '1.5', value: '1.5' },
  { label: '1.53', value: '1.53' },
  { label: '1.56', value: '1.56' },
  { label: '1.57', value: '1.57' },
  { label: '1.59', value: '1.59' },
  { label: '1.60', value: '1.60' },
  { label: '1.67', value: '1.67' },
  { label: '1.74', value: '1.74' },
  { label: '1.76', value: '1.76' },
]

export const CORRIDOR = [
  { label: 'Select', value: '' },
  { label: 'Extra Short', value: 'Extra Short' },
  { label: 'Short', value: 'Short' },
  { label: 'Medium', value: 'Medium' },
  { label: 'Large', value: 'Large' },
]

export const ADDON_PACKAGE = i18n => [
  { label: i18n('Select'), value: '' },
  { label: i18n('Basic'), value: 'Basic' },
  { label: i18n('Premier'), value: 'Premier' },
  { label: i18n('Elite'), value: 'Elite' },
  { label: i18n('Ultra'), value: 'Ultra' },
]

export const ADDON_PACKAGE_KIDS = i18n => [
  { label: i18n('KIDS Standard'), value: 'KIDS Standard' },
  { label: i18n('KIDS Premier'), value: 'KIDS Premier' },
  { label: i18n('KIDS Elite'), value: 'KIDS Elite' },
]

export const ADDON_COLOR = (i18n, respectiveAddon, addOnPackage) => {
  let options = [
    { label: i18n('Select'), value: '' },
    { label: i18n('As Sample'), value: 'As Sample' },
    { label: i18n('Grey'), value: 'Grey' },
    { label: i18n('Black'), value: 'Black' },
    { label: i18n('Brown'), value: 'Brown' },
    { label: i18n('Blue'), value: 'Blue' },
    { label: i18n('Green'), value: 'Green' },
    { label: i18n('Silver'), value: 'Silver' },
    { label: i18n('Gold'), value: 'Gold' },
    { label: i18n('Orange'), value: 'Orange' },
    { label: i18n('Gradient Grey'), value: 'Gradient Grey' },
    { label: i18n('Gradient Black'), value: 'Gradient Black' },
    { label: i18n('Gradient Brown'), value: 'Gradient Brown' },
    { label: i18n('Gradient Blue'), value: 'Gradient Blue' },
    { label: i18n('Gradient Green'), value: 'Gradient Green' },
    { label: i18n('Gradient Silver'), value: 'Gradient Silver' },
    { label: i18n('Gradient Gold'), value: 'Gradient Gold' },
    { label: i18n('Gradient Orange'), value: 'Gradient Orange' },
    { label: i18n('Yellow Glow Light 25%'), value: 'Yellow Glow Light 25%'},
    { label: i18n('Orange Zest Light 25%'), value: 'Orange Zest Light 25%'},
    { label: i18n('Pink Pastel Light 25%'), value: 'Pink Pastel Light 25%'},
    { label: i18n('Purple Bloom Light 25%'), value: 'Purple Bloom Light 25%'},
    { label: i18n('Sapphire Blue Light 25%'), value: 'Sapphire Blue Light 25%'},
    { label: i18n('Green Meadow Light 25%'), value: 'Green Meadow Light 25%'},
    { label: i18n('Mocha Brown Light 25%'), value: 'Mocha Brown Light 25%'},
    { label: i18n('Slate Grey Light 25%'), value: 'Slate Grey Light 25%'},
    { label: i18n('Yellow Glow Medium 50%'), value: 'Yellow Glow Medium 50%'},
    { label: i18n('Orange Zest Medium 50%'), value: 'Orange Zest Medium 50%'},
    { label: i18n('Pink Pastel Medium 50%'), value: 'Pink Pastel Medium 50%'},
    { label: i18n('Purple Bloom Medium 50%'), value: 'Purple Bloom Medium 50%'},
    { label: i18n('Sapphire Blue Medium 50%'), value: 'Sapphire Blue Medium 50%'},
    { label: i18n('Green Meadow Medium 50%'), value: 'Green Meadow Medium 50%'},
    { label: i18n('Mocha Brown Medium 50%'), value: 'Mocha Brown Medium 50%'},
    { label: i18n('Slate Grey Medium 50%'), value: 'Slate Grey Medium 50%'},
    { label: i18n('Yellow Glow Dark 80%'), value: 'Yellow Glow Dark 80%'},
    { label: i18n('Orange Zest Dark 80%'), value: 'Orange Zest Dark 80%'},
    { label: i18n('Pink Pastel Dark 80%'), value: 'Pink Pastel Dark 80%'},
    { label: i18n('Purple Bloom Dark 80%'), value: 'Purple Bloom Dark 80%'},
    { label: i18n('Sapphire Blue Dark 80%'), value: 'Sapphire Blue Dark 80%'},
    { label: i18n('Green Meadow Dark 80%'), value: 'Green Meadow Dark 80%'},
    { label: i18n('Mocha Brown Dark 80%'), value: 'Mocha Brown Dark 80%'},
    { label: i18n('Slate Grey Dark 80%'), value: 'Slate Grey Dark 80%'},
    { label: i18n('Yellow Glow Gradient 50% - 25%'), value: 'Yellow Glow Gradient 50% - 25%'},
    { label: i18n('Orange Zest Gradient 50% - 25%'), value: 'Orange Zest Gradient 50% - 25%'},
    { label: i18n('Pink Pastel Gradient 50% - 25%'), value: 'Pink Pastel Gradient 50% - 25%'},
    { label: i18n('Purple Bloom Gradient 50% - 25%'), value: 'Purple Bloom Gradient 50% - 25%'},
    { label: i18n('Sapphire Blue Gradient 50% - 25%'), value: 'Sapphire Blue Gradient 50% - 25%'},
    { label: i18n('Green Meadow Gradient 50% - 25%'), value: 'Green Meadow Gradient 50% - 25%'},
    { label: i18n('Mocha Brown Gradient 50% - 25%'), value: 'Mocha Brown Gradient 50% - 25%'},
    { label: i18n('Slate Grey Gradient 50% - 25%'), value: 'Slate Grey Gradient 50% - 25%'},
    { label: i18n('Yellow Glow Gradient 80% - 25%'), value: 'Yellow Glow Gradient 80% - 25%'},
    { label: i18n('Orange Zest Gradient 80% - 25%'), value: 'Orange Zest Gradient 80% - 25%'},
    { label: i18n('Pink Pastel Gradient 80% - 25%'), value: 'Pink Pastel Gradient 80% - 25%'},
    { label: i18n('Purple Bloom Gradient 80% - 25%'), value: 'Purple Bloom Gradient 80% - 25%'},
    { label: i18n('Sapphire Blue Gradient 80% - 25%'), value: 'Sapphire Blue Gradient 80% - 25%'},
    { label: i18n('Green Meadow Gradient 80% - 25%'), value: 'Green Meadow Gradient 80% - 25%'},
    { label: i18n('Mocha Brown Gradient 80% - 25%'), value: 'Mocha Brown Gradient 80% - 25%'},
    { label: i18n('Slate Grey Gradient 80% - 25%'), value: 'Slate Grey Gradient 80% - 25%'}
  ]

  const commonColors = [
    { label: "Yellow Glow Light 25%", value: "Yellow Glow Light 25%" },
    { label: "Orange Zest Light 25%", value: "Orange Zest Light 25%" },
    { label: "Pink Pastel Light 25%", value: "Pink Pastel Light 25%" },
    { label: "Purple Bloom Light 25%", value: "Purple Bloom Light 25%" },
    { label: "Sapphire Blue Light 25%", value: "Sapphire Blue Light 25%" },
    { label: "Green Meadow Light 25%", value: "Green Meadow Light 25%" },
    { label: "Mocha Brown Light 25%", value: "Mocha Brown Light 25%" },
    { label: "Slate Grey Light 25%", value: "Slate Grey Light 25%" },
    { label: "Yellow Glow Medium 50%", value: "Yellow Glow Medium 50%" },
    { label: "Orange Zest Medium 50%", value: "Orange Zest Medium 50%" },
    { label: "Pink Pastel Medium 50%", value: "Pink Pastel Medium 50%" },
    { label: "Purple Bloom Medium 50%", value: "Purple Bloom Medium 50%" },
    { label: "Sapphire Blue Medium 50%", value: "Sapphire Blue Medium 50%" },
    { label: "Green Meadow Medium 50%", value: "Green Meadow Medium 50%" },
    { label: "Mocha Brown Medium 50%", value: "Mocha Brown Medium 50%" },
    { label: "Slate Grey Medium 50%", value: "Slate Grey Medium 50%" },
    { label: "Yellow Glow Dark 80%", value: "Yellow Glow Dark 80%" },
    { label: "Orange Zest Dark 80%", value: "Orange Zest Dark 80%" },
    { label: "Pink Pastel Dark 80%", value: "Pink Pastel Dark 80%" },
    { label: "Purple Bloom Dark 80%", value: "Purple Bloom Dark 80%" },
    { label: "Sapphire Blue Dark 80%", value: "Sapphire Blue Dark 80%" },
    { label: "Green Meadow Dark 80%", value: "Green Meadow Dark 80%" },
    { label: "Mocha Brown Dark 80%", value: "Mocha Brown Dark 80%" },
    { label: "Slate Grey Dark 80%", value: "Slate Grey Dark 80%" },
    { label: "Yellow Glow Gradient 50% - 25%", value: "Yellow Glow Gradient 50% - 25%" },
    { label: "Orange Zest Gradient 50% - 25%", value: "Orange Zest Gradient 50% - 25%" },
    { label: "Pink Pastel Gradient 50% - 25%", value: "Pink Pastel Gradient 50% - 25%" },
    { label: "Purple Bloom Gradient 50% - 25%", value: "Purple Bloom Gradient 50% - 25%" },
    { label: "Sapphire Blue Gradient 50% - 25%", value: "Sapphire Blue Gradient 50% - 25%" },
    { label: "Green Meadow Gradient 50% - 25%", value: "Green Meadow Gradient 50% - 25%" },
    { label: "Mocha Brown Gradient 50% - 25%", value: "Mocha Brown Gradient 50% - 25%" },
    { label: "Slate Grey Gradient 50% - 25%", value: "Slate Grey Gradient 50% - 25%" },
    { label: "Yellow Glow Gradient 80% - 25%", value: "Yellow Glow Gradient 80% - 25%" },
    { label: "Orange Zest Gradient 80% - 25%", value: "Orange Zest Gradient 80% - 25%" },
    { label: "Pink Pastel Gradient 80% - 25%", value: "Pink Pastel Gradient 80% - 25%" },
    { label: "Purple Bloom Gradient 80% - 25%", value: "Purple Bloom Gradient 80% - 25%" },
    { label: "Sapphire Blue Gradient 80% - 25%", value: "Sapphire Blue Gradient 80% - 25%" },
    { label: "Green Meadow Gradient 80% - 25%", value: "Green Meadow Gradient 80% - 25%" },
    { label: "Mocha Brown Gradient 80% - 25%", value: "Mocha Brown Gradient 80% - 25%" },
    { label: "Slate Grey Gradient 80% - 25%", value: "Slate Grey Gradient 80% - 25%" },
  ]

  if (respectiveAddon) {
    options = [
      { label: i18n('Select'), value: '' },
      { label: i18n('Grey'), value: 'Grey' },
      { label: i18n('Green'), value: 'Green' },
      { label: i18n('Brown'), value: 'Brown' }
    ]
  }

  if ((addOnPackage.toLowerCase() === 'elite' || addOnPackage.toLowerCase() === 'ultra') && respectiveAddon?.toLowerCase() === 'tn') {
    options = [
      { label: i18n('Select'), value: '' },
      { label: i18n('As Sample'), value: 'As Sample' },
      ...commonColors,
      { label: "Polarized Grey 80%", value: "Polarized Grey 80%" },
      { label: "Polarized Brown 80%", value: "Polarized Brown 80%" },
      { label: "Polarized Green 80%", value: "Polarized Green 80%" },
      { label: "Mirror Gold-Grey 80%", value: "Mirror Gold-Grey 80%" },
      { label: "Mirror Blue-Grey 80%", value: "Mirror Blue-Grey 80%" },
      { label: "Mirror Silver-Grey 80%", value: "Mirror Silver-Grey 80%" },
      { label: "Mirror Green-Grey 80%", value: "Mirror Green-Grey 80%" },
      { label: "Mirror Red-Grey 80%", value: "Mirror Red-Grey 80%" },
      { label: "Mirror Purple-Grey 80%", value: "Mirror Purple-Grey 80%" },
      { label: "Mirror Rose Gold-Grey 80%", value: "Mirror Rose Gold-Grey 80%" },
      { label: "Mirror Gold-Brown 80%", value: "Mirror Gold-Brown 80%" },
      { label: "Mirror Blue-Brown 80%", value: "Mirror Blue-Brown 80%" },
      { label: "Mirror Silver-Brown 80%", value: "Mirror Silver-Brown 80%" },
      { label: "Mirror Green-Brown 80%", value: "Mirror Green-Brown 80%" },
      { label: "Mirror Red-Brown 80%", value: "Mirror Red-Brown 80%" },
      { label: "Mirror Purple-Brown 80%", value: "Mirror Purple-Brown 80%" },
      { label: "Mirror Rose Gold-Brown 80%", value: "Mirror Rose Gold-Brown 80%" },
    ]
  } else if (addOnPackage && respectiveAddon?.toLowerCase() === 'tn') {
    options = [
      { label: i18n('Select'), value: '' },
      { label: i18n('As Sample'), value: 'As Sample' },
      ...commonColors,
    ]
  }

  if (isTocPackage(addOnPackage)) {
    options = [
      { label: i18n('Select'), value: '' },
      { label: i18n('Grey'), value: 'Grey' },
      { label: i18n('Green'), value: 'Green' },
      { label: i18n('Brown'), value: 'Brown' }
    ]
  }

  return options;
}

export const ADDON_4 = (i18n, lensType) => {
  let options = [];
  if (lensType === LENS_TYPES.SINGLE_VISION) {
    options = ['ZEISS 1.5 Blue Protect (BL)', 'ZEISS 1.5 LotuTec (STD)', 'ZEISS 1.5 PhotoFusion (TR)', 'OL.ZEISS 1.6 Blue Protect (BL)', 'OL.ZEISS 1.6 LotuTec (STD)', 'OL.ZEISS AS 1.67 LotuTec (STD)', 'OL.ZEISS 1.5 DriveSafe', 'OL.ZEISS 1.6 DriveSafe', 'OL.RX.ZEISS 1.5 (STD)', 'OL.RX.ZEISS 1.5 (BL)', 'OL.RX.ZEISS 1.5 PhotoFusion (TR,BL)', 'OL.RX.ZEISS 1.5 PhotoFusion (TR,STD)', 'OL.RX.ZEISS 1.53 Trivex (STD,RIMLESS)', 'OL.RX.ZEISS 1.53 Trivex (BL,RIMLESS)', 'OL.RX.ZEISS 1.6 (BL)', 'OL.RX.ZEISS 1.6 (STD)']
  } else if (lensType === LENS_TYPES.PROGRESSIVE) {
    options = ['ZEISS 1.5 (STD)', 'ZEISS PhotoFusion 1.5 (TR)', 'OL.RX.ZEISS D Light 1.6 (STD)', 'OL.RX.ZEISS D Light 1.6 PhotoFusion (TR)', 'OL.RX.ZEISS D Light 1.67 (STD)', 'OL.RX.ZEISS D Light 1.67 PhotoFusion (TR)', 'ZEISS 1.5 (BL)', 'ZEISS PhotoFusion 1.5 (BL,TR)', 'OL.RX.ZEISS D Light 1.6 (BL)', 'OL.RX.ZEISS D Light 1.6 PhotoFusion (BL,TR)', 'OL.RX.ZEISS D Light 1.67 (BL)', 'OL.RX.ZEISS D Light 1.67 PhotoFusion (BL,TR)', 'OL.RX.ZEISS Precision Pure 1.5 (STD)', 'OL.RX.ZEISS Precision Pure 1.5 PhotoFusion (TR)', 'OL.RX.ZEISS Precision Pure 1.53 Trivex (STD,RIMLESS)', 'OL.RX.ZEISS Precision Pure 1.6 (STD)', 'OL.RX.ZEISS Precision Pure 1.6 PhotoFusion (TR)', 'OL.RX.ZEISS Precision Pure 1.67 (STD)', 'OL.RX.ZEISS Precision Pure 1.67 PhotoFusion (TR)', 'OL.RX.ZEISS Precision Pure 1.74 (STD)', 'OL.RX.ZEISS Precision Pure 1.5 (BL)', 'OL.RX.ZEISS Precision Pure 1.5 PhotoFusion (BL,TR)', 'OL.RX.ZEISS Precision Pure 1.53 Trivex (BL,RIMLESS)', 'OL.RX.ZEISS Precision Pure 1.6 (BL)', 'OL.RX.ZEISS Precision Pure 1.6 PhotoFusion (BL,TR)', 'OL.RX.ZEISS Precision Pure 1.67 (BL)', 'OL.RX.ZEISS Precision Pure 1.67 PhotoFusion (BL,TR)', 'OL.RX.ZEISS Precision Pure 1.74 (BL)'];
  };

  return [{ label: i18n('Select'), value: '' }, ...options.map(x => ({ label: i18n(x), value: x }))]
}

export const LENS_TYPES = {
  SINGLE_VISION: 'Single Vision',
  PROGRESSIVE: 'Progressive',
  PRESCRIPTION: 'Contact Lens',
};

export const FIELDS = {
  FULL_NAME: 'fullName',
  EMAIL: 'email',
  PHONE: 'phone',
  DOB: 'dateOfBirth',
  COUNTRY: 'country',
  LANGUAGE: 'language',
  GENDER: 'gender',
  RELATIONSHIP_TYPE: 'relationshipType',
  LENS_TYPE: 'lensType',
  LENS_NAME: 'name',
  PRESCRIPTIONS: 'prescriptions',
  COMMENT: 'comment.comment',
  STAFF_NAME: 'staffName',
  STORE_NAME: 'storeName',
  INDEX: 'index',
  LENS_DIAMETER: 'lensDiameter',
  LENS_SHAPE: 'lensShape',
  BASE_CURVE: 'baseCurve',
  CORRIDOR: 'corridor',
  ADDON_PACKAGE: 'addOnPackage',
  ADDON_1: 'addOn1',
  ADDON_2: 'addOn2',
  ADDON_3: 'addOn3',
  ADDON_4: 'addOn4',
  ADDON_5: 'addOn5',
  ADDON_6: 'addOn6',
  ADDON_7: 'addOn7',
  ADDON_8: 'addOn8',
  ADDON_COLOR: 'addOnColor',
  EXTERNAL: 'externalPrescription',
  EXTERNAL_SOURCE: 'prescriptionDoctorName',
  AGE: 'age',
}

function useFields(props = {}) {
  const { i18n } = useI18n();
  const { setValue, getValues } = useFormContext()
  const { storeName, staffName } = useSelector(selectDefaults);
  const { width, eyeSize, type, externalPrescription = false, prescriptionDoctorName = '' } = props;
  const fields = useWatch({name: [FIELDS.LENS_TYPE, FIELDS.ADDON_1, FIELDS.ADDON_2, FIELDS.ADDON_3, FIELDS.ADDON_4, FIELDS.INDEX, FIELDS.ADDON_PACKAGE, FIELDS.ADDON_5, FIELDS.ADDON_6, FIELDS.ADDON_7, FIELDS.EXTERNAL, FIELDS.ADDON_8]});
  const watchedFields = useMemo(() => ({ [FIELDS.LENS_TYPE]: fields[0], [FIELDS.ADDON_1]: fields[1], [FIELDS.ADDON_2]: fields[2], [FIELDS.ADDON_3]: fields[3], [FIELDS.ADDON_4]: fields[4], [FIELDS.INDEX]: fields[5], [FIELDS.ADDON_PACKAGE]: fields[6], [FIELDS.ADDON_5]: fields[7], [FIELDS.ADDON_6]: fields[8], [FIELDS.ADDON_7]: fields[9], [FIELDS.EXTERNAL]: fields[10], [FIELDS.ADDON_8]: fields[11] }), [fields]);
  const right = useWatch({name: 'right'});
  const left = useWatch({name: 'left'});
  const isAddOn1Disabled = useMemo(() => !!(watchedFields[FIELDS.ADDON_2] || watchedFields[FIELDS.ADDON_3] || watchedFields[FIELDS.ADDON_5] || watchedFields[FIELDS.ADDON_6] || watchedFields[FIELDS.ADDON_7]), [watchedFields]);
  const isAddOn2Disabled = useMemo(() => !!(watchedFields[FIELDS.ADDON_1] || watchedFields[FIELDS.ADDON_3] || watchedFields[FIELDS.ADDON_5] || watchedFields[FIELDS.ADDON_6] || watchedFields[FIELDS.ADDON_7]), [watchedFields]);
  const isAddOn3Disabled = useMemo(() => !!(watchedFields[FIELDS.ADDON_1] || watchedFields[FIELDS.ADDON_2] || watchedFields[FIELDS.ADDON_5] || watchedFields[FIELDS.ADDON_6] || watchedFields[FIELDS.ADDON_7]), [watchedFields]);
  const isAddOn5Disabled = useMemo(() => !!(watchedFields[FIELDS.ADDON_1] || watchedFields[FIELDS.ADDON_2] || watchedFields[FIELDS.ADDON_3] || watchedFields[FIELDS.ADDON_6] || watchedFields[FIELDS.ADDON_7]), [watchedFields]);
  const isAddOn6Disabled = useMemo(() => !!(watchedFields[FIELDS.ADDON_1] || watchedFields[FIELDS.ADDON_2] || watchedFields[FIELDS.ADDON_3] || watchedFields[FIELDS.ADDON_5] || watchedFields[FIELDS.ADDON_7]), [watchedFields]);
  const isAddOn7Disabled = useMemo(() => !!(watchedFields[FIELDS.ADDON_1] || watchedFields[FIELDS.ADDON_2] || watchedFields[FIELDS.ADDON_3] || watchedFields[FIELDS.ADDON_5] || watchedFields[FIELDS.ADDON_6]), [watchedFields]);
  const externalDoctors = useSelector(selectExternalDoctors);

  // useEffect(() => {
  //   if (watchedFields[FIELDS.ADDON_1]) {
  //     setValue(FIELDS.ADDON_2, '');
  //     setValue(FIELDS.ADDON_3, '');
  //     setValue(FIELDS.ADDON_5, '');
  //     setValue(FIELDS.ADDON_6, '');
  //     setValue(FIELDS.ADDON_7, '');
  //   }
  // }, [watchedFields[FIELDS.ADDON_1]]);

  // useEffect(() => {
  //   if (watchedFields[FIELDS.ADDON_2]) {
  //     setValue(FIELDS.ADDON_1, '');
  //     setValue(FIELDS.ADDON_3, '');
  //     setValue(FIELDS.ADDON_5, '');
  //     setValue(FIELDS.ADDON_6, '');
  //     setValue(FIELDS.ADDON_7, '');
  //   }
  // }, [watchedFields[FIELDS.ADDON_2]]);

  // useEffect(() => {
  //   if (watchedFields[FIELDS.ADDON_3]) {
  //     setValue(FIELDS.ADDON_1, '');
  //     setValue(FIELDS.ADDON_2, '');
  //     setValue(FIELDS.ADDON_5, '');
  //     setValue(FIELDS.ADDON_6, '');
  //     setValue(FIELDS.ADDON_7, '');
  //   }
  // }, [watchedFields[FIELDS.ADDON_3]]);

  // useEffect(() => {
  //   if (watchedFields[FIELDS.ADDON_5]) {
  //     setValue(FIELDS.ADDON_1, '');
  //     setValue(FIELDS.ADDON_2, '');
  //     setValue(FIELDS.ADDON_3, '');
  //     setValue(FIELDS.ADDON_6, '');
  //     setValue(FIELDS.ADDON_7, '');
  //   }
  // }, [watchedFields[FIELDS.ADDON_5]]);

  // useEffect(() => {
  //   if (watchedFields[FIELDS.ADDON_6]) {
  //     setValue(FIELDS.ADDON_1, '');
  //     setValue(FIELDS.ADDON_2, '');
  //     setValue(FIELDS.ADDON_3, '');
  //     setValue(FIELDS.ADDON_5, '');
  //     setValue(FIELDS.ADDON_7, '');
  //   }
  // }, [watchedFields[FIELDS.ADDON_6]]);

  // useEffect(() => {
  //   if (watchedFields[FIELDS.ADDON_6]) {
  //     setValue(FIELDS.ADDON_1, '');
  //     setValue(FIELDS.ADDON_2, '');
  //     setValue(FIELDS.ADDON_3, '');
  //     setValue(FIELDS.ADDON_5, '');
  //     setValue(FIELDS.ADDON_6, '');
  //   }
  // }, [watchedFields[FIELDS.ADDON_7]]);

  useEffect(() => {
    const newIndex = calculateIndex({
      defaultIndex: watchedFields[FIELDS.INDEX] || '1.60',
      type,
      left,
      right,
      addOnPackage: watchedFields[FIELDS.ADDON_PACKAGE],
      addOn8: watchedFields[FIELDS.ADDON_8],
    });

    setValue(FIELDS.INDEX, newIndex);
  }, [type, right?.sphere, left?.sphere, right?.cylinder, left?.cylinder, watchedFields[FIELDS.ADDON_8], watchedFields[FIELDS.ADDON_PACKAGE]]);

  useEffect(() => {
    let diameter = '70';
    const D = ((width + eyeSize) / 2) + right?.pupilDiameter + left?.pupilDiameter + 2;

    if (right?.sphere >= 0 && left?.sphere >= 0) {
      if (['1.60', '1.67', '1.74'].includes(watchedFields[FIELDS.INDEX]) || D > 70) {
        diameter = '75';
      }
    } else if (right?.sphere < 0 && left?.sphere < 0) {
      if (D - 5 <= 65) {
        diameter = '65'
      }
    }

    setValue(FIELDS.LENS_DIAMETER, diameter);
  }, [width, eyeSize, right?.pupilDiameter, left?.pupilDiameter, watchedFields[FIELDS.INDEX], left?.sphere, right?.sphere])

  useEffect(() => {
    setValue(FIELDS.EXTERNAL, externalPrescription ? FIELDS.EXTERNAL : '');
    setValue(FIELDS.EXTERNAL_SOURCE, prescriptionDoctorName);
  }, [externalPrescription, prescriptionDoctorName])

  const renderField = (name, className, loading, disabled) => {
    const commonProps = { className, loading }

    switch (name) {
      case FIELDS.FULL_NAME:
        return (
          <Controller
            key={name}
            name={name}
            rules={{
              required: i18n('Full name is required'),
              validate: val => (!!val && val.trim().includes(' ')) || i18n('Please enter your full name'),
            }}
            render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
              <Input {...commonProps} label={i18n('Full Name')} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} />
            )}
          />
        )

      case FIELDS.EMAIL:
        return (
          <Controller
            key={name}
            name={name}
            rules={{
              pattern: {
                value: /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: i18n('Please enter a valid email address (ex. johndoe@domain.com)'),
              }
            }}
            render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
              <Input {...commonProps} type="email" disabled={disabled} label={i18n('Email Address')} error={error?.message} onChange={onChange} onBlur={onBlur} value={value} />
            )}
          />
        )

      case FIELDS.PHONE:
        return (
          <Phone key={name} label={i18n('Phone Number')} {...commonProps} />
        )

      case FIELDS.DOB:
        return (
          <Controller
            key={name}
            name={name}
            rules={{
              required: i18n('Please enter your date of birth'),
            }}
            render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
              <Input disabled={disabled} {...commonProps} type="date" label={i18n('Date of Birth')} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} placeholder="dd/mm/yyyy" />
            )}
          />
        )

      case FIELDS.COUNTRY:
        return (
          <Controller
            key={name}
            name={name}
            rules={{
              required: i18n('Please enter the country')
            }}
            render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
              <Countries {...commonProps} i18n={i18n} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} />
            )}
          />
        )

      case FIELDS.LANGUAGE:
        return (
          <Controller
            key={name}
            name={name}
            rules={{
              required: i18n('Please choose a language')
            }}
            render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
              <Languages {...commonProps} i18n={i18n} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} />
            )}
          />
        )

      case FIELDS.GENDER:
        return (
          <Controller
            key={name}
            name={name}
            rules={{
              required: i18n('Please choose a gender')
            }}
            render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
              <Genders {...commonProps} i18n={i18n} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} />
            )}
          />
        )

      case FIELDS.RELATIONSHIP_TYPE:
        return (
          <Controller
            key={name}
            name={name}
            rules={{
              required: i18n('Select a relation ship type'),
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <RadioGroup options={RELATIONSHIP_TYPES} name={name} {...commonProps} label={i18n('Relationship Type')} error={error?.message} onChange={onChange} value={value} />
            )}
          />
        )

      case FIELDS.LENS_TYPE:
        return (
          <Controller
            key={name}
            name={name}
            rules={{
              required: i18n('Please select a lens type'),
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <RadioGroup
                  options={[
                    ...Object.keys(LENS_TYPES).map(x => ({ label: LENS_TYPES[x], value: LENS_TYPES[x] })),
                    { label: i18n('Plano'), value: 'Plano' }
                  ]}
                  name={name}
                  {...commonProps}
                  label={i18n('Lens Type')}
                  error={error?.message}
                  onChange={onChange}
                  defaultValue={value}
                />
              )
            }}
          />
        )

      case FIELDS.LENS_NAME:
        return (
          <Controller
            key={name}
            name={name}
            rules={{ required: i18n('Prescription name is required'), }}
            render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
              <Input {...commonProps} label={i18n('Prescription Name')} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} />
            )}
          />
        )

      case FIELDS.COMMENT:
        return (
          <Controller
            key={name}
            name={name}
            render={({ field: { value, onBlur, onChange } }) => (
              <Textarea {...commonProps} label={i18n('Comment')} onBlur={onBlur} onChange={onChange} value={value} />
            )}
          />
        )

      case FIELDS.STAFF_NAME:
        return staffName ? (
          <Controller key={name} name={name} defaultValue={staffName} render={() => <input type="hidden" value={staffName} />} />
        ) : null;

      case FIELDS.STORE_NAME:
        return storeName ? (
          <Controller key={name} name={name} defaultValue={storeName} render={() => <input type="hidden" value={storeName}  />} />
        ) : null;

      case FIELDS.INDEX:
        return (
          <Controller
            key={name}
            name={name}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <SimpleSelect options={INDEX} name={name} {...commonProps} label={i18n('Index')} error={error?.message} onChange={onChange} value={value} />
            )}
          />
        )

      case FIELDS.CORRIDOR:
        return watchedFields[FIELDS.LENS_TYPE] === LENS_TYPES.PROGRESSIVE ? (
          <Controller
            key={name}
            name={name}
            rules={{
              validate: val => !!val || i18n('Please select a corridor value')
            }}
            // rules={{ required: i18n('Please select a corridor') }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <SimpleSelect options={CORRIDOR} name={name} {...commonProps} label={i18n('Corridor')} error={error?.message} onChange={onChange} value={value} />
            )}
          />
        ) : null;

      case FIELDS.LENS_DIAMETER:
        return (
          <Controller
            key={name}
            name={name}
            render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
              <Input {...commonProps} label={i18n('Lens Diameter')} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} />
            )}
          />
        )

      case FIELDS.LENS_SHAPE:
        return (
          <Controller
            key={name}
            name={name}
            render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
              <SimpleSelect {...commonProps} isSearchable name={name} options={isTocOrder(props.orderId) || isTocPackage(watchedFields[FIELDS.ADDON_PACKAGE]) ? LENS_SHAPES_TOC : LENS_SHAPES} label={i18n('Lens Shape')} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} />
            )}
          />
        )

      case FIELDS.BASE_CURVE:
        return (
          <Controller
            key={name}
            name={name}
            render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
              <Input {...commonProps} label={i18n('Base Curve')} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} />
            )}
          />
        )

      case FIELDS.ADDON_PACKAGE: {
        const options = watchedFields[FIELDS.LENS_TYPE] === LENS_TYPES.SINGLE_VISION
          ? [...ADDON_PACKAGE(i18n), ...ADDON_PACKAGE_KIDS(i18n)]
          : ADDON_PACKAGE(i18n);

        return (
          <Controller
            key={name}
            name={name}
            render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
              <SimpleSelect options={options} name={name} {...commonProps} label={i18n('Package')} error={error?.message} onChange={onChange} onBlur={onBlur} value={value} />
            )}
          />
        )
      }

      case FIELDS.ADDON_1:
        return (
          <Controller
            key={name}
            name={name}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Checkbox label={i18n('Blue Light')} value="BL" name={name} {...commonProps} error={error?.message} onChange={onChange} checked={value === 'BL'} disabled={isAddOn1Disabled} />
            )}
          />
        )

      case FIELDS.ADDON_2:
        return (
          <Controller
            key={name}
            name={name}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Checkbox label={i18n('Photochromic')} value="TR" name={name} {...commonProps} error={error?.message} onChange={onChange} checked={value === 'TR'} disabled={isAddOn2Disabled} />
            )}
          />
        )

      case FIELDS.ADDON_3:
        return (
          <Controller
            key={name}
            name={name}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Checkbox label={i18n('Tinting')} value="TN" name={name} {...commonProps} error={error?.message} onChange={onChange} checked={value === 'TN'} disabled={isAddOn3Disabled} />
            )}
          />
        )

        case FIELDS.ADDON_5:
          return (
            <Controller
              key={name}
              name={name}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Checkbox label={i18n('Transitions')} value="TS" name={name} {...commonProps} error={error?.message} onChange={onChange} checked={value === 'TS'} disabled={isAddOn5Disabled} />
              )}
            />
          )

      case FIELDS.ADDON_6:
        return (
          <Controller
            key={name}
            name={name}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Checkbox label={i18n('Mioeye')} value="MOY" name={name} {...commonProps} error={error?.message} onChange={onChange} checked={value === 'MOY'} disabled={isAddOn6Disabled} />
            )}
          />
        )

      case FIELDS.ADDON_7:
        return (
          <Controller
            key={name}
            name={name}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Checkbox label={i18n('Office')} value="Office" name={name} {...commonProps} error={error?.message} onChange={onChange} checked={value === 'Office'} disabled={isAddOn7Disabled} />
            )}
          />
        )

      case FIELDS.ADDON_COLOR:
        return watchedFields[FIELDS.ADDON_2] || watchedFields[FIELDS.ADDON_3] || watchedFields[FIELDS.ADDON_4]?.includes('PhotoFusion') || watchedFields[FIELDS.ADDON_5] ? (
          <Controller
            key={name}
            name={name}
            rules={{ required: i18n('Please choose a color'), }}
            render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
              <SimpleSelect options={ADDON_COLOR(i18n, (watchedFields[FIELDS.ADDON_2] || watchedFields[FIELDS.ADDON_3] || watchedFields[FIELDS.ADDON_5]), watchedFields[FIELDS.ADDON_PACKAGE])} name={name} {...commonProps} label={i18n('Color')} error={error?.message} onChange={onChange} onBlur={onBlur} value={value} />
            )}
          />
        ) : null;

      case FIELDS.EXTERNAL:
        return (
          <Controller
            key={name}
            name={name}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Checkbox label={i18n('External')} value={FIELDS.EXTERNAL} name={name} {...commonProps} error={error?.message} onChange={onChange} checked={value === FIELDS.EXTERNAL} />
            )}
          />
        )

      case FIELDS.EXTERNAL_SOURCE:
        return watchedFields[FIELDS.EXTERNAL] ? (
          <Controller
            key={name}
            name={name}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <SimpleSelect options={[{label: i18n('Others'), value: ''}, ...externalDoctors.map(({ name }) => ({ label: name, value: name }))]} name={name} {...commonProps} error={error?.message} onChange={onChange} value={value} />
            )}
          />
        ) : null;

      case FIELDS.AGE:
        return (
          <Controller
            key={name}
            name={name}
            render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
              <Input {...commonProps} label={i18n('Age')} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} />
            )}
          />
        )

      default:
        return null;
    }
  }

  return { renderField, watchedFields };
}

export default useFields;
