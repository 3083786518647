import React from 'react'
import cx from 'classnames'
import styles from './Availability.module.scss'
import { Layout, StoresWithAvailability, UserBar, UserStores } from 'components'
import { useNavigate } from 'react-router'

function Availability() {
  const navigate = useNavigate();

  return (
    <Layout narrow >
      <div className={cx(styles.wrapper)}>
        <UserBar />
        <StoresWithAvailability />
        <UserStores />
      </div>
    </Layout>
  )
}

Availability.propTypes = {}

export default Availability
