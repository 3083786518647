import React from 'react'
import { Layout } from 'components'
import { Outlet, useLocation } from 'react-router'
import useI18n from 'common-hooks/useI18n';
import cx from 'classnames';
import styles from './Settings.module.scss'
import SettingsNav from './SettingsNav';

function Settings() {
  const { i18n } = useI18n();
  const { pathname } = useLocation();

  let activeIndex = 0;
  if (pathname.includes('users')) {
    activeIndex = 1;
  } else if (pathname.includes('setup')) {
    activeIndex = 2;
  } else if (pathname.includes('delivery-management')) {
    activeIndex = 3;
  } else if (pathname.includes('warehouses')) {
    activeIndex = 0;
  }

  return (
    <Layout title={i18n('Settings')}>
      <div className={cx(styles.wrapper)}>
        <SettingsNav active={activeIndex} />
        <Outlet />
      </div>
    </Layout>
  )
}

Settings.propTypes = {}

export default Settings
