export const titleize = (str) => {
  let trimmedStr = str.trim()
  let upper = true
  let newStr = ""
  for (let i = 0, l = trimmedStr.length; i < l; i++) {
    if (trimmedStr[i] === " ") {
      upper = true
      newStr += trimmedStr[i]
      continue
    }
    newStr += upper ? trimmedStr[i].toUpperCase() : trimmedStr[i].toLowerCase()
    upper = false
  }
  return newStr
}