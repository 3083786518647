import React, { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import ChildSkuFormFields from './ChildSkuFormFields';
import { Message } from 'components';
import useI18n from 'common-hooks/useI18n';
import { LENS_TYPES } from 'common-hooks/useFields';

function ChildSkuForm({ childProducts, parentProducts, onSubmit, defaultChild, defaultParent, loading, email, hasParent, id, orderId, phone, customerId, ...props }) {
  const { i18n } = useI18n();
  const methods = useForm({ mode: 'all', defaultValues: JSON.parse(JSON.stringify(props)) });
  const { formState: { isValid } } = methods;
  const childItemId = useWatch({ control: methods.control, name: 'childItemId', defaultValue: defaultChild });
  const [messages, setMessages] = useState([]);

  const fields = methods.watch();

  const validateAll = async () => {
    const validateResponse = await methods.trigger();
    if (validateResponse) {
      onSubmit(fields, id);
    } else {
      onSubmit(false, id);
    }
  }

  useEffect(() => {
    validateAll()
  }, [isValid, fields]);

  useEffect(() => {
    if (childItemId) {
      const childProduct = childProducts.find(x => x.id === childItemId);
      const lensType = childProduct.power.lensType.replace(' ', '_').replace('-', '_').toUpperCase();
      const addOnPackage = childProduct.power.addOnPackage;

      methods.setValue('lensType', LENS_TYPES[lensType]);
      methods.setValue('addOnPackage', addOnPackage);
      methods.setValue('comment.comment', props.comment?.comment);

      if (childProduct.power.addOn1) {
        methods.setValue('addOn1', childProduct.power.addOn1);
      } else if (childProduct.power.addOn2) {
        methods.setValue('addOn2', childProduct.power.addOn2);
      } else if (childProduct.power.addOn3) {
        methods.setValue('addOn3', childProduct.power.addOn3);
      } else if (childProduct.power.addOn5) {
        methods.setValue('addOn5', childProduct.power.addOn5);
      } else if (childProduct.power.addOn6) {
        methods.setValue('addOn6', childProduct.power.addOn6);
      } else if (childProduct.power.addOn7) {
        methods.setValue('addOn7', childProduct.power.addOn7);
      } else if (childProduct.power.addOn8) {
        methods.setValue('addOn8', childProduct.power.addOn8);
      }
    }
  }, [childItemId])

  return (
    <>
      <FormProvider {...methods}>
        {!!childItemId && <ChildSkuFormFields email={email} phone={phone} customerId={customerId} loading={loading} onSubmit={methods.handleSubmit(onSubmit)} childProduct={childProducts.find(x => x.id === childItemId)} children={childProducts} material={props.material} type={props.type} width={props.width} eyeSize={props.eyeSize} childItemId={defaultChild} parentItemId={props.parentItemId} externalPrescription={props.externalPrescription} prescriptionDoctorName={props.prescriptionDoctorName} orderId={orderId} />}
      </FormProvider>
      {!!messages.length && <Message error message={i18n('Order detail has not been updated.')} info={messages.join(', ')} cta={i18n('Close')} onClick={() => setMessages([])} />}
    </>
  )
}

ChildSkuForm.propTypes = {}

export default ChildSkuForm
