export default function deliveryPromiseCalculator({ frameType, lensType, addon, left, right, color }) {
  const val = 6;

  if (lensType !== 'Single Vision') return val;
  if (!addon) return val;
  if (!['BL', 'TR', 'TN'].includes(addon)) return val;

  if (addon === 'TN') return 3;

  if (
    addon === 'TR' &&
    color === 'Grey' &&
    (left.sphere >= -4 && left.sphere <= 0) &&
    (right.sphere >= -4 && right.sphere <= 0) &&
    (left.cylinder >= -2 && left.cylinder <= 0) &&
    (right.cylinder >= -2 && right.cylinder <= 0)
  ) return 2;

  if (
    addon === 'BL' &&
    frameType?.toLowerCase?.() !== 'non rimless' &&
    (left.sphere >= -6 && left.sphere <= 6) &&
    (right.sphere >= -6 && right.sphere <= 6) &&
    (left.cylinder >= -3 && left.cylinder <= 0) &&
    (right.cylinder >= -3 && right.cylinder <= 0)
  ) return 2;

  if (
    addon === 'BL' &&
    frameType?.toLowerCase?.() === 'rimless' &&
    (left.sphere >= -4 && left.sphere <= 4) &&
    (right.sphere >= -4 && right.sphere <= 4) &&
    (left.cylinder >= -2 && left.cylinder <= 0) &&
    (right.cylinder >= -2 && right.cylinder <= 0)
  ) return 2;

  return val;
}
