import React from 'react'
import cx from 'classnames'
import styles from './order-details/OrderDetails.module.scss'
import noResulctIcon from 'components/predictive-search/no-results-icon.png'
import useI18n from 'common-hooks/useI18n';

function NoDataAvailable({ message, error }) {
  const { i18n } = useI18n();
  return (
    <>
      <p className={cx(styles.results, styles['no-results'])}>
        <img className={cx(styles['no-results-icon'])} src={noResulctIcon} alt="" />
        <strong className={cx(styles['no-results-message'])}>{message || i18n('No data available')}</strong>
      </p>
      <span className={styles['error-info']}>{error?.data?.message}</span>
    </>
  )
}

export default NoDataAvailable
