import React, { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import useFields, { FIELDS } from 'common-hooks/useFields';
import cx from 'classnames'
import styles from './CustomerFields.module.scss'
import { useFormContext } from 'react-hook-form';
import { Button, Input } from 'components';
import useI18n from 'common-hooks/useI18n';
import { selectDefaults } from 'appSlice';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Bounce, toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { useLazySearchCustomerQuery, useSendOTPMutation, useVerifyOTPMutation } from 'app/service';
import PopUpDialog from 'components/pop-up/PopUp';

const emailRegex = /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function CustomerFields({ loading, onSubmit, submitting, edit }) {
  const dialogRef = useRef(null);
  const originalPhone = useRef(null);
  const originalEmail = useRef(null);
  const { i18n } = useI18n();
  const { handleSubmit, watch, setValue, formState: { isValid, isDirty }, register } = useFormContext();
  const [searchCustomer, { isLoading: checkingNumberExists, isFetching }] = useLazySearchCustomerQuery();
  const [sendOTP, { isLoading: isSendingOTP }] = useSendOTPMutation();
  const [verifyOTP, { isLoading: isVerifyingOTP }] = useVerifyOTPMutation();

  const { country } = useSelector(selectDefaults);
  const { age, dateOfBirth, code } = watch();
  const watchPhone = watch('phone')
  const watchEmail = watch('email')
  const navigate = useNavigate();
  const { renderField } = useFields();

  const [isOTPSent, setIsOTPSent] = useState(false)
  const [otpValue, setOptValue] = useState('')

  const _onSubmit = (data, e) => {
    e.preventDefault();
    e.stopPropagation();
    Reflect.deleteProperty(data, 'age');
    onSubmit({ ...data, countryCode: country })
  }

  const handlePhoneFieldBlur = async (mobileNumber) => {
    if (originalPhone.current === mobileNumber) return;
    try {
      const { data } = await searchCustomer(`+${code}-${mobileNumber}`)
      let responseCustomer = data[0];
      if (responseCustomer && edit) {
        toast.error('Customer with this phone number already exists', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setValue('phone', originalPhone.current);
        return;
      }
      if (responseCustomer) {
        setValue('email', responseCustomer.email);
        setValue('fullName', responseCustomer.fullName);
        const dob = new Date(responseCustomer.dateOfBirth);
        const age = new Date().getFullYear() - dob.getFullYear();
        setValue('age', age);
        setValue('gender', responseCustomer.gender);
        setValue('language', responseCustomer.language);

        if (responseCustomer.mobileVerified) {
          const { id } = responseCustomer;
          if (!edit) {
            navigate(`/customers/${id}/prescriptions`);
          }
        } else {
          showPopUp()
        }
      } else {
        showPopUp()
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleEmailFieldBlur = async (email) => {
    try {
      const { data } = await searchCustomer(email)
      let responseCustomer = data[0];
      if (responseCustomer && !edit) {
        const { id } = responseCustomer;
        navigate(`/customers/${id}/prescriptions`);
      }
      if (responseCustomer && edit && email !== originalEmail.current) {
        const { id } = responseCustomer;
        toast.info('Customer with this email already exists with id - ' + id);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const showPopUp = () => {
    toast.info('Please verify customer\'s phone number', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });

    if (dialogRef.current) {
      dialogRef.current.showModal();
    }
    setIsOTPSent(false);
    setOptValue('');
  }

  const checkIfValidPhone = val => {
    if (!code || !val) return false;
    const length = val.length;
    if (length < 6 && length > 11) return false;
    if (isNaN(val)) return false;
    if (length !== 9 && ['971', '966'].includes(code)) return false;
    if (length !== 8 && ['965', '968', '973', '974'].includes(code)) return false;
    return true;
  }

  const handleSendOTP = async () => {
    const { data } = await sendOTP({ countryCode: country, data: { mobileNumber: `+${code}${watchPhone}` } });
    if (data) {
      toast.success('OTP sent successfully', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setIsOTPSent(true);
    }
  }

  const handleVerifyOTPValue = async () => {
    const data = {
      mobileNumber: `+${code}${watchPhone}`,
      otp: otpValue
    }

    const { data: response } = await verifyOTP({ countryCode: country, data });
    if (response.message === 'Invalid OTP') {
      alert('Invalid OTP');
    } else if (response.body.success) {
      toast.success('OTP verified successfully', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });

      if (dialogRef.current) {
        dialogRef.current?.close?.();
        setValue('mobileVerified', true);
        setOptValue('');
        setIsOTPSent(false);
      }
    }
  }

  useEffect(() => {
    if (edit && !isDirty) {
      originalPhone.current = watchPhone;
    }
    if (checkIfValidPhone(watchPhone) && isDirty) {
      handlePhoneFieldBlur(watchPhone)
    }
  }, [watchPhone, code]);

  useEffect(() => {
    if (edit && !isDirty) {
      originalEmail.current = watchEmail;
    }
    if (emailRegex.test(watchEmail) && isDirty) {
      handleEmailFieldBlur(watchEmail)
    }
  }, [watchEmail]);

  useEffect(() => {
    if (!dateOfBirth || !isDirty) return;
    const dob = new Date(dateOfBirth);
    const age = new Date().getFullYear() - dob.getFullYear();
    setValue('age', age, { shouldValidate: true });
  }, [dateOfBirth])

  useEffect(() => {
    if (!age || !isDirty) return;
    const dob = new Date();
    dob.setFullYear(dob.getFullYear() - age);
    dob.setMonth(0);
    dob.setDate(1);
    setValue('dateOfBirth', dayjs(dob).format('YYYY-MM-DD'), { shouldValidate: true });
  }, [age])

  const handleCancel = () => {
    navigate(-1);
  }

  return (
    <>
      <form className={cx(styles.form)} onSubmit={handleSubmit(_onSubmit)}>
        <Input type='hidden' {...register("mobileVerified")} />
        <Fragment>
          {renderField(FIELDS.PHONE, cx(styles.field), loading)}
        </Fragment>
        <div className={cx(styles.fields)}>
          {renderField(FIELDS.FULL_NAME, cx(styles.field), loading)}
          {renderField(FIELDS.EMAIL, cx(styles.field), loading)}
          {[FIELDS.GENDER, FIELDS.LANGUAGE, FIELDS.AGE,FIELDS.DOB].map(field => (
            <Fragment key={field}>{renderField(field, cx(styles.field), loading)}</Fragment>
          ))}
        </div>
        <div className={cx(styles.fields, styles.buttonsWrapper)}>
          <Button size='small' loading={submitting || checkingNumberExists || isFetching} skeleton={loading} type="submit" disabled={!isValid}>{i18n('Save & Next')}</Button>
          <Button size='small' theme='secondary' type="button" onClick={handleCancel}>{i18n('Cancel')}</Button>
        </div>
      </form>
      <PopUpDialog ref={dialogRef} title="Verify Mobile Number" showCloseButton backdropClose={false}>
        <>
          {!isOTPSent ? (
            <div className={styles.form}>
              <p>{i18n('Please verify the mobile number before proceeding')}</p>
              <Button
                loading={isSendingOTP}
                disabled={isSendingOTP}
                className={styles['verify-button']}
                onClick={handleSendOTP}>
                {i18n('Send OTP')}
              </Button>
            </div>
          ) : (
            <div className={styles.form}>
              <p>{i18n('OTP sent to the mobile number. Please enter the OTP to proceed')}</p>
              <div className={styles.verifyForm}>
                <Input type="text" label={i18n('OTP')} className={cx(styles['otp-input'])} placeholder={i18n('Enter OTP')} value={otpValue} onChange={(e) => setOptValue(e.target.value)} />
                <Button loading={isVerifyingOTP} disabled={isVerifyingOTP} className={styles['verify-button']} onClick={handleVerifyOTPValue}>{i18n('Verify')}</Button>
              </div>
            </div>
          )}
        </>
      </PopUpDialog>
    </>
  )
}

CustomerFields.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  submitting: PropTypes.bool
}

export default CustomerFields
