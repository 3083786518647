import React from 'react'
import PropTypes from 'prop-types'
import { FilterCard, Layout } from 'components'
import useI18n from 'common-hooks/useI18n'
import { useNavigate } from 'react-router';
import cx from 'classnames'
import styles from './Dashboard.module.scss'
import useDashboard from './useDashboard';
import SummaryCard from './SummaryCard';
import customersIcon from './customers.png';
import prescriptionsIcon from './prescriptions.png';
import CustomerTable from './CustomerTable';
import noResultsIcon from 'components/message/error.png'

function Dashboard() {
  const {i18n} = useI18n();
  const navigate = useNavigate();
  const { filters, onFilterChange, summary, columns, data, loading, isUninitialized, onFilterReset } = useDashboard();
  const hasNoResults = !isUninitialized && !loading && !data.length;

  return (
    <Layout
      narrow
      title={i18n('Dashboard')}

      preContent={<FilterCard value={filters} onChange={onFilterChange} onReset={onFilterReset} />}
    >
      <div className={cx(styles.wrapper)}>
        {hasNoResults ? (
          <div className={cx(styles['no-results'])}>
            <img className={cx(styles['no-results-icon'])} src={noResultsIcon} alt="" />
            <p className={cx(styles['no-results-message'])}>{i18n('No results')}</p>
            <p className={cx(styles['no-results-info'])}>{i18n('Please try refining your search')}</p>
          </div>
        ) : (
          <>
            <h2 className={cx(styles.title)}>{i18n('Summary')}</h2>
            <div className={cx(styles['summary-section'])}>
              <SummaryCard number={summary.customers} label={i18n('Customer(s) Created')} icon={customersIcon} />
              <SummaryCard number={summary.prescriptions} label={i18n('Prescription(s) Added')} icon={prescriptionsIcon} />
            </div>
            <h2 className={cx(styles.title)}>{i18n('Customer Details')}</h2>
            <CustomerTable columns={columns} data={data} />
          </>
        )}
      </div>
    </Layout>
  )
}

Dashboard.propTypes = {}

export default Dashboard
