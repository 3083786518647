import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import styles from './BoxLink.module.scss'

function BoxLink({ to, icon, label, className }) {
  return (
    <Link className={cx(styles.wrapper, className)} to={to}>
      <img className={cx(styles.icon)} src={icon} alt="" />
      <span className={cx(styles.label)}>{label}</span>
    </Link>
  )
}

BoxLink.propTypes = {
  to: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string
}

export default BoxLink
