import React, { useEffect, useMemo, useRef, useState } from 'react'
import cx from 'classnames'
import styles from './Home.module.scss'
import { Button, Input, Layout, SimpleSelect } from 'components'
import PrescriptionCard from './PrescriptionCard'
import OrderCard from './OrderCard'
// import DashboardCard from './DashboardCard'
// import ConfigCard from './ConfigCard'
import AppointmentCard from './AppointmentCard'
import PerformanceCard from './PerformanceCard'
import TopPerformersCard from './TopPerformers'
import PopUpDialog from 'components/pop-up/PopUp'
import { selectShowOptomSelectionMenu, selectToken, selectUser, updateLoginData, updateShowOptomSelectionMenu, updateToken, updateUser } from 'features/login/loginSlice'
import { useDispatch, useSelector } from 'react-redux'
import { selectDefaults } from 'appSlice'
import useLogin, { EG_ROLE_NAMES, EG_SWITCHED_USER } from 'common-hooks/useLogin'
import { useLazySwitchStoreQuery, useLazyUsersQuery, useLazyWarehousesQuery } from 'app/service'
import EyeClosed from './../../icons/Eye_Closed.svg'
import EyeOpen from './../../icons/Eye_Open.svg'
import { Bounce, toast } from 'react-toastify'
import { Link } from 'react-router-dom'

function Home() {
  const dialogRef = useRef(null)
  const oldStoreId = useRef(null)
  const user = useSelector(selectUser)
  const defaultData = useSelector(selectDefaults)
  const showOptomSelectionMenu = useSelector(selectShowOptomSelectionMenu);
  const dispatch = useDispatch()

  const [fetchEgUsers, { data: optoms = [] }] = useLazyUsersQuery();
  const [fetchEyewaStores, { data: eyewaStores = [] }] = useLazyWarehousesQuery();
  const [switchStore] = useLazySwitchStoreQuery();

  const [dialogContentType, setDialogContentType] = useState(null)
  const [selectedOptom, setSelectedOptom] = useState(null)
  const [selectedStore, setSelectedStore] = useState(null)
  const [showPassword, setShowPassword] = useState(false);

  const { handleManualLogin, loading } = useLogin({
    onSignedIn: () => {
      dispatch(updateShowOptomSelectionMenu(true))
      const checkLoggedIn = () => {
        setTimeout(() => {
          if (window.localStorage.getItem('eg_token')) {
            dialogRef.current?.close?.()
            handleStoreChange(oldStoreId.current)
          } else {
            checkLoggedIn()
          }
        }, 100)
      }
      checkLoggedIn()
    }
  });

  const handleContinueClick = () => {
    dispatch(updateShowOptomSelectionMenu(true))
    dialogRef.current?.close?.()
  }

  const handleStoreChange = async (storeId) => {
    const res = await switchStore(storeId ? storeId : selectedStore);
    dispatch(updateLoginData(res?.data));
  }

  const handleStoreChangeEvent = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(updateShowOptomSelectionMenu(true))
    dialogRef.current?.close?.()
    handleStoreChange(selectedStore)
  }

  const handleLoginClick = (e) => {
    e.preventDefault()
    const { username, password } = e.target.elements;
    if (!username.value || !password.value) {
      toast.error('Username or password can\'t be empty', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }
    const oldDefaultStoreId = defaultData.storeId
    oldStoreId.current = oldDefaultStoreId
    window.localStorage.removeItem('eg_token');
    window.localStorage.removeItem(EG_SWITCHED_USER);
    dispatch(updateToken(null));
    dispatch(updateUser({}));
    // signOut()
    handleManualLogin(e)
  }

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  }

  const getOptomDropdownValues = useMemo(() => {
    return optoms.map(op => ({ label: op.username, value: op.email }))
  }, [optoms])

  const getEyewaStoresDropdownValues = useMemo(() => {
    return eyewaStores.map(store => ({ label: store.warehouseName, value: store.id }))
  }, [eyewaStores])

  const defaultWarehouse = useMemo(() => user?.warehouses?.find?.(x => x.default)?.storeCode, [user])

  useEffect(() => {
    if (!showOptomSelectionMenu) {
      if (user?.roleList?.[0]?.roleName === EG_ROLE_NAMES.EG_STORE) {
        fetchEgUsers('4,5') // roleIds = 4,5 EG_USER and EG_OPTOM
        setDialogContentType(EG_ROLE_NAMES.EG_STORE)
      } else if (user?.roleList?.[0]?.roleName === EG_ROLE_NAMES.EG_OPTOM || user?.roleList?.[0]?.roleName === EG_ROLE_NAMES.EG_USER) {
        fetchEyewaStores()
        setDialogContentType(EG_ROLE_NAMES.EG_OPTOM)
      }
    }
  }, [showOptomSelectionMenu])

  useEffect(() => {
    if (dialogContentType) {
      dialogRef.current.addEventListener('cancel', (event) => {
        event.preventDefault();
      });
      dialogRef.current.showModal()
    }
  }, [dialogContentType])

  const PopUpLoginForm = () => {
    return (
      <>
        {dialogContentType === EG_ROLE_NAMES.EG_STORE ? (
          <>
            <p>{'Continue as'}</p>
            <div className={styles.loginForm}>
              <Input disabled={true} value={defaultData.storeNameFull}></Input>
              <Button onClick={handleContinueClick} className={styles.formButton}>{'Continue'}</Button>
            </div>
            <div className={cx(styles.or)}>{''}</div>
            <p>{'Login as Optom'}</p>
            <form onSubmit={handleLoginClick} className={styles.loginForm}>
              <div>
                <SimpleSelect isSearchable className={styles['users-dropdown']} label={'Select User'} value={selectedOptom} onChange={setSelectedOptom} options={getOptomDropdownValues}></SimpleSelect>
                <Input type='hidden' value={selectedOptom} name="username" />
                <Input value={null} name="password" type={showPassword ? "text" : "password"} label={'Password'} icon={showPassword ? EyeClosed : EyeOpen} onIconClick={handlePasswordToggle} />
              </div>
              <Button type='submit' className={cx(styles.formButton, styles.center)} loading={loading} disabled={loading}>{'Login'}</Button>
            </form>
          </>
        ) : (
          <>
            <p>{'Continue in'}</p>
            <div className={styles.loginForm}>
              <Input disabled={true} value={defaultData.storeNameFull}></Input>
              <Button onClick={handleContinueClick} className={styles.formButton}>{'Continue'}</Button>
            </div>
            <div className={cx(styles.or)}>{''}</div>
            <p>{'Change store'}</p>
            <form onSubmit={handleStoreChangeEvent} className={styles.loginForm}>
              <SimpleSelect isSearchable className={styles['users-dropdown']} label={''} value={selectedStore} onChange={setSelectedStore} options={getEyewaStoresDropdownValues}></SimpleSelect>
              <Button type='submit' className={styles.formButton} loading={loading} disabled={loading}>{'Change Store'}</Button>
            </form>
          </>
        )}

      </>
    )
  }

  return (
    <Layout>
      {user &&
        <div>
          <p className={styles['slots-exhausted']}>{'Please update your eyetest appointment schedule . Please update '}
            <Link to={`/availability/${defaultWarehouse}/list`} className={styles['slots-exhausted-link']}>{'here.'}</Link>
          </p>
        </div>
      }
      <div className={styles.home}>
        <div className={cx(styles.cards, styles['appointment-wrapper'])}>
          <AppointmentCard />
        </div>
        <div className={styles.cards}>
          <PerformanceCard />
          <TopPerformersCard />
          <OrderCard />
          <PrescriptionCard />
        </div>
      </div>
      <PopUpDialog ref={dialogRef} title={dialogContentType === EG_ROLE_NAMES.EG_STORE ? 'User Selection' : 'Store Selection'} backdropClose={false} showCloseButton={false}>
        <PopUpLoginForm />
      </PopUpDialog>
    </Layout>
  )
}

Home.propTypes = {}

export default Home
