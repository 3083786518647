export const convertDataForInfoCard = (data = {}) => {
  const output = {};
  Object.keys(data).filter(key => typeof data[key] !== 'object' || !Array.isArray(data[key])).forEach(key => {
    let niceName = key.replace(/([A-Z])/g, " $1");
    niceName = niceName.charAt(0).toUpperCase() + niceName.slice(1);

    output[niceName] = data[key];
  })

  return output;
}

export const isOrderValid = (id = '') => {
  if(!id) return false
  const term = id.toUpperCase();
  const prefixes = ['SO-SPAE', 'SO-INBH', 'SO-INKS', 'SO-OPKW', 'SO-INOM', 'EC-SPAE', 'EC-INBH', 'EC-INKS', 'EC-OPKW', 'EC-INOM']
  const prefixInTerm = prefixes.find(prefix => term.startsWith(prefix));

  if (term.startsWith('MOE')) {
    const number = term.split('-').reverse()[0];
    if (number?.length !== 10) return false;
    if (isNaN(number)) return false;
    return true;
  }

  if (prefixInTerm) {
    const number = term.split('-').reverse()[0];
    if (number?.length !== 9) return false;
    if (isNaN(number)) return false;
    return true;
  }

  if (isNaN(term)) return false;
  if (term.length === 11) return true;

  return true;
}
