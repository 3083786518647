const dayjs = require('dayjs');

const TOC_PACKAGES = ['essential', 'exceptional', 'digital protect'];

export const isTocOrder = (orderId) => orderId?.toString?.()?.startsWith('25') || orderId?.toString?.()?.startsWith('26');

export const isTocPackage = (addOnPackage) => TOC_PACKAGES.includes(addOnPackage?.toLowerCase?.());

const isWeekend = (date) => {
  const day = date.day();
  return  day === 0; // day === 6 | 6 is Saturday, 0 is Sunday
}

export const calculateDeliveryDate = (daysToAdd, startDate) => {
  let currentDate = startDate ? dayjs(startDate) : dayjs();
  let workingDaysAdded = 0;

  while (workingDaysAdded < daysToAdd) {
    currentDate = currentDate.add(1, 'day');
    if (!isWeekend(currentDate)) {
      workingDaysAdded++;
    }
  }

  return currentDate.format('dddd, MMM D, YYYY');
}