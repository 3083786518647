import React, { useState } from 'react';
import { Button, Checkbox, FormSectionTitle, Input } from 'components';
import { useOutletContext } from 'react-router-dom';
import styles from './../Questionnaire.module.scss'
import cx from 'classnames';
import { validate } from './validations';

const Step2 = () => {
  const [formData, handleNext] = useOutletContext();
  const [stepData, setStepData] = useState(formData.step2);
  const [validations, setValidations] = useState({});

  const handleChange = (e) => {
    setStepData(prev => ({ ...prev, [e.target.name]: e.target.value, 'None': '' }));
    setValidations(prev => ({ ...prev, [e.target.name]: '', button: '' }))
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationObj = validate(stepData, 2)
    if (validationObj) {
      setValidations(validationObj)
      return
    }
    handleNext(stepData, 2);
  };

  const boolQuestion = (name, checkboxLabel) => {
    if (name === 'None') {
      return (
        <div className={styles.boolQuestion}>
          <Checkbox
            className={styles.input}
            name={name}
            value={name}
            checked={!!stepData[checkboxLabel]}
            label={checkboxLabel}
            error={validations[name]}
            onChange={e => {
              setStepData(prev => ({[checkboxLabel]: e }));
              setValidations(prev => ({ [checkboxLabel]: '', button: '' }))
            }
            } />
        </div>
      )
    }
    return (
      <div className={styles.boolQuestion}>
        {name !== 'Other' &&
          <Checkbox
            className={styles.input}
            name={name}
            value={name}
            checked={!!stepData[checkboxLabel]}
            label={checkboxLabel}
            error={validations[name]}
            onChange={e => {
              if (!e) {
                handleChange({
                  target: {
                    name: `${checkboxLabel}-duration`,
                    value: ''
                  }
                })
              } else {
                handleChange({
                  target: {
                    name: 'None',
                    value: ''
                  }
                })
              }
              handleChange({
                target: {
                  name: checkboxLabel,
                  value: !!e
                }
              })
            }
            } />
        }
        {name === 'Other' &&
          <Input label='Other (if any)'
            type='text'
            className={styles.input}
            name={checkboxLabel}
            placeholder='symptom'
            onChange={e => {
              if (!e.target.value) {
                handleChange({
                  target: {
                    name: `${checkboxLabel}-duration`,
                    value: ''
                  }
                })
              } else {
                handleChange({
                  target: {
                    name: 'None',
                    value: ''
                  }
                })
              }
              handleChange(e)
            }}
            value={stepData[checkboxLabel]}
          />
        }
        <Input
          type='number'
          className={cx([styles.input, name === 'Other' && styles.padTop])}
          name={`${checkboxLabel}-duration`}
          placeholder='duration in days'
          onChange={handleChange}
          value={stepData[checkboxLabel] ? stepData[`${checkboxLabel}-duration`] : ''}
          error={validations[`${checkboxLabel}-duration`]}
        />
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormSectionTitle>{'Chief complaints and their duration :'}</FormSectionTitle>
      <div className={styles.questionContainer}>
        {boolQuestion('Blurred Vision', 'Blurred Vision')}
        {boolQuestion('Dryness', 'Dryness')}
        {boolQuestion('Headache', 'Headache')}
        {boolQuestion('Eye Fatigue', 'Eye Fatigue')}
        {boolQuestion('Burning Sensation', 'Burning Sensation')}
        {boolQuestion('Floaters', 'Floaters')}
        {boolQuestion('Halos', 'Halos')}
        {boolQuestion('Starbursts', 'Starbursts')}
        {boolQuestion('Other', 'Other')}
        {boolQuestion('None', 'None')}
      </div>
      <Button type='submit' >{'Next'}</Button>
      {validations['button'] && <div className={styles.errorMessage}>{validations['button']}</div>}
    </form>
  );
};

export default Step2;
