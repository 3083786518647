import React, { useEffect, useMemo, useState } from 'react'
import { Events, Layout, UserBar } from 'components'
import useI18n from 'common-hooks/useI18n'
import { useLocation, useNavigate } from 'react-router';
import cx from 'classnames';
import styles from './Appointments.module.scss'
import { useSelector } from 'react-redux';
import { selectDefaults } from 'appSlice';
import { useFetchAppointmentsMutation } from 'app/service';
import dayjs from 'dayjs';

function Appointments() {
  const { i18n } = useI18n();
  const [current, setCurrent] = useState('upcoming');
  const [page, setPage] = useState(0);
  const { storeName: storeId, country } = useSelector(selectDefaults);
  const [fetch, { data = {}, isLoading }] = useFetchAppointmentsMutation();
  const { search} = useLocation()

  let appointmentId = useMemo(() => {
    const params = new URLSearchParams(search);
    let appointmentId = params.get('appointmentId')
    return appointmentId
  }, [search])

  useEffect(() => {
    const dateToGet = current === 'upcoming' ? 'fromTime' : 'toTime';
    if (storeId && country) fetch({ storeId, country, [dateToGet]: dayjs().format('YYYY-MM-DDTHH:mm:ss'), size: 20, page: page, appointmentId })
  }, [storeId, current, country, page]);

  return (
    <Layout title={i18n('Scheduled Appointments')} narrow>
      <div className={cx(styles.wrapper)}>
        <UserBar />
        <div className={cx(styles.appointments)}>
          <div className={cx(styles.tabs)}>
            <button onClick={() => {setCurrent('upcoming'); setPage(0);}} className={cx(styles.tab, { [styles.current]: current === 'upcoming' })}>{i18n('Upcoming')}</button>
            <button onClick={() => {setCurrent('past'); setPage(0);}} className={cx(styles.tab, { [styles.current]: current === 'past' })}>{i18n('Past')}</button>
          </div>
          <Events events={data} loading={isLoading} />
          <div className={styles.pagination}>
            <div className={styles.paginationButtonWrapper}>
              <button className={styles.paginationButton} disabled={!page} onClick={() => setPage(page - 1)}>{i18n('Previous')}</button>
              <button className={styles.paginationButton} onClick={() => setPage(page + 1)}>{i18n('Next')}</button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

Appointments.propTypes = {}

export default Appointments
