import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './Events.module.scss'
import Event from './Event'
import dayjs from 'dayjs'
import Skeleton from 'components/skeleton/Skeleton'

function Events({ events, loading }) {
  const eventsAsArray =  Object.keys(events);
  if (!loading && !eventsAsArray.length) return (
    <div className={cx(styles.empty)}>
      <h2 className={cx(styles.title)}>No events found</h2>
    </div>
  );

  return loading ? <Skeleton type="events" /> : (
    <ul className={cx(styles.sections)}>
      {eventsAsArray.map(date => (
        <li className={cx(styles.section)} key={date}>
          <h2 className={cx(styles.title)}>{dayjs(date).format('dddd, DD MMMM YYYY')}</h2>
          <ul className={cx(styles.events)}>
            {events[date].map(event => (
              <Event key={event.appointmentId} {...event} />
            ))}
          </ul>
        </li>
      ))}
    </ul>
  )
}

Events.propTypes = {
  events: PropTypes.object
}

export default Events
