import { useUpdateWarehouseSetupMutation, useWarehouseSetupQuery } from 'app/service';
import { Button, Input, Skeleton } from 'components';
import React, { useEffect, useState } from 'react'
import JsonView from 'react18-json-view'
import 'react18-json-view/src/style.css'
import styles from './Settings.module.scss'

function AdminConfig() {
  const { data = [], isLoading, isError, error } = useWarehouseSetupQuery();
  let [update] = useUpdateWarehouseSetupMutation();
  let sourceJSON = data?.setup ? JSON.parse(JSON.stringify(data?.setup)) : null;
  const [sourceJSON2, setSourceJSON2] = useState(sourceJSON);

  const handleSubmit = async () => {
    if (sourceJSON2) {
      await update({ data: sourceJSON2 });
    }
  }

  useEffect(() => {
    if (sourceJSON) {
      setSourceJSON2(sourceJSON);
    }
  }, [data])

  if (isLoading) {
    return (
      <>
        <Skeleton type="prescriptions" />
        <Skeleton type="prescriptions" />
      </>
    )
  }

  if (isError) {
    return (
      <p>
        {'Something went wrong, please try again! (' + error?.data?.error + ')'}
      </p>
    )
  }

  return (
    <div>
      <div className={styles.configWrapper}>
        {sourceJSON2 && (
          sourceJSON2?.map((item, outerIndex) => {
            return (
              <div key={outerIndex} className={styles.configItemWrapper}>
                <h3>{item.setupKey}</h3>
                <JsonView src={item}
                  editable={true}
                  onEdit={e => {
                    const newSourceJSON = JSON.parse(JSON.stringify(sourceJSON2));
                    newSourceJSON[outerIndex] = e.src;
                    setSourceJSON2(newSourceJSON);
                  }}
                  customizeNode={params => {
                    if (params.indexOrName === 'setupKey') return { add: false, edit: false, delete: false, enableClipboard: false }
                    if (params.indexOrName === 'values') return { add: true, edit: true, delete: false, enableClipboard: false }
                    return { add: false, edit: true, delete: false, enableClipboard: false }
                  }}
                  onAdd={params => {
                    console.log('[jv onAdd]', params)
                  }}
                />
              </div>
            )
          })
        )}

      </div>
      <div className={styles.configSubmitWrapper}>
        <Button theme='secondary' type="button" onClick={() => setSourceJSON2(sourceJSON)}>Cancel</Button>
        <Button type="submit" onClick={handleSubmit}>Submit</Button>
      </div>
    </div>
  )
}

export default AdminConfig;
