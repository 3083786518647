import React from 'react'
import styles from './Users.module.scss'
import cx from 'classnames'
import useI18n from 'common-hooks/useI18n'
import { useTable } from 'react-table'
import { Link, useNavigate } from 'react-router-dom'
import useUsers from './useUsers'
import FilterBar from 'components/filter-card/FilterBar'

export default function Users() {
  const { i18n } = useI18n();
  const { items, columns, setFilters } = useUsers();
  const navigate = useNavigate();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: items });

  const redirectToUser = (id) => {
    navigate(`${id}`);
  }

  return (
    <div className={cx(styles.wrapper)}>
      <div className={styles.heading}>
        <h1>{i18n('Users')}</h1>
        <Link to={'add'} className={cx(styles.link)}>{i18n('Create User')}</Link>
      </div>
      <FilterBar heading={i18n('Search')} onChange={setFilters} searchPlaceholder={i18n('Name, email')} useSearchOnly />
      <div className={styles.tableHeadFixed}>
        <table {...getTableProps()}>
          <thead className={styles.tableHeaderRow}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className={styles.tableRow} onClick={() => redirectToUser(row.original.id)}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}> {cell.render('Cell')} </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
