import { useState } from 'react';
import { useSelector } from 'react-redux';
import useI18n from 'common-hooks/useI18n'
import { useNavigate } from 'react-router';
import cx from 'classnames';
import { Button, Input, Message, SimpleSelect, SplashScreen } from 'components';
import styles from './Login.module.scss';
import useLogin from 'common-hooks/useLogin';
import { selectAlerts } from 'appSlice';
import EyeClosed from './../../icons/Eye_Closed.svg'
import EyeOpen from './../../icons/Eye_Open.svg'

function Login() {
  const { i18n } = useI18n();
  const alerts = useSelector(selectAlerts);
  const { signIn, loading, selectUsersToSwitch, usersInStore = [], onUserSelect, handleManualLogin, clearAlert } = useLogin({ onSignedIn: () => navigate('/') });
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  }

  return selectUsersToSwitch ? (
    <SplashScreen title={i18n('You are logged in to a store')} description={i18n('Please select a user to continue')}>
      <SimpleSelect isSearchable onChange={onUserSelect} className={cx(styles.users)} options={usersInStore.map(x => ({ label: x.name, value: x.email }))} />
    </SplashScreen>
  ) : (
    <SplashScreen title={i18n('Login to your account')} description={i18n('You can make transactions quickly and practically by logging in with your eyewa.com account.')}>
      <form onSubmit={handleManualLogin} className={cx(styles.form)}>
        <Input value={null} name="username" label={i18n('Username or email')}  />
        <Input value={null} name="password" type={showPassword ? "text" : "password"}  label={i18n('Password')} icon={showPassword ? EyeClosed : EyeOpen} onIconClick={handlePasswordToggle} />
        <Button className={cx(styles.submit)} type="submit" theme="primary">{i18n('Login')}</Button>
        {alerts?.[0] && <Message message={i18n(alerts[0].message || 'Invalid username or password')} onClick={clearAlert} cta={i18n('Retry')} />}
      </form>
      <div className={cx(styles.or)}>{i18n('or')}</div>
      <button className={cx(styles.button, { [styles.loading]: loading })} onClick={signIn} disabled={loading}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M23.49 12.27C23.49 11.48 23.42 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.33 17.24 16.07 18.09V21.09H19.93C22.19 19 23.49 15.92 23.49 12.27Z" fill="#4285F4"/>
          <path d="M11.9991 23.9991C15.2391 23.9991 17.9491 22.9191 19.9291 21.0891L16.0691 18.0891C14.9891 18.8091 13.6191 19.2491 11.9991 19.2491C8.86906 19.2491 6.21906 17.1391 5.26906 14.2891H1.28906V17.3791C3.25906 21.2991 7.30906 23.9991 11.9991 23.9991Z" fill="#80C8C1"/>
          <path d="M5.27 14.2911C5.02 13.5711 4.89 12.8011 4.89 12.0011C4.89 11.2011 5.03 10.4311 5.27 9.71109V6.62109H1.29C0.470001 8.24109 0 10.0611 0 12.0011C0 13.9411 0.470001 15.7611 1.29 17.3811L5.27 14.2911Z" fill="#FBBC05"/>
          <path d="M11.9991 4.75C13.7691 4.75 15.3491 5.36 16.5991 6.55L20.0191 3.13C17.9491 1.19 15.2391 0 11.9991 0C7.30906 0 3.25906 2.7 1.28906 6.62L5.26906 9.71C6.21906 6.86 8.86906 4.75 11.9991 4.75Z" fill="#EA4335"/>
        </svg>
        {i18n(loading ? 'Signing in....' : 'Continue with Google')}
      </button>
    </SplashScreen>
  )
}

Login.propTypes = {}

export default Login
