import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './FormElements.module.scss'

function FormSectionTitle({ className, children }) {
  return (
    <h2 className={cx(styles['form-section-title'], className)}>{children}</h2>
  )
}

FormSectionTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  className: PropTypes.string
}

export default FormSectionTitle
