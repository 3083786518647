import Button from 'components/button/Button';
import Input from 'components/form-elements/Input';
import React, { useState } from 'react';
import styles from './Table.module.scss';
import cx from 'classnames';

const EditableCell = ({ value: initialValue, row: { index }, column: { id }, onUpdateCell, editInput }) => {
  const [value, setValue] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);

  const onChange = e => {
    setValue(e.target.value);
  };

  const onEdit = () => {
    setIsEditing(true);
  };

  const onConfirm = () => {
    setIsEditing(false);
    onUpdateCell(index, id, value);
  };

  const onBlur = () => {
    if (!isEditing) {
      onUpdateCell(index, id, value);
    }
  };

  const onCancel = () => {
    setValue(initialValue); // Revert to initial value
    setIsEditing(false);
  };

  return (
    <div>
      {isEditing ? (
        <>
          {editInput ? editInput({ value, onChange, onBlur }) :
            <input
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              autoFocus
              className={styles.editInput}
            />
          }
            <button className={styles.editButton} onClick={onConfirm}>Confirm</button>
            <button className={cx(styles.editButton, styles.secondary)} theme='tertiary' onClick={onCancel}>Cancel</button>
        </>
      ) : (
        <>
          <span>{value}</span>
          <button onClick={onEdit} style={{ marginLeft: '8px', border: 'none', background: 'none' }}>✏️</button>
        </>
      )}
    </div>
  );
};

export default EditableCell;