import React, { createContext, useContext, useEffect } from 'react'
import OrdersNav from '../OrdersNav'
import { Outlet, Route, Routes, useLocation, useParams } from 'react-router'
import { Layout, Skeleton } from 'components'
import useI18n from 'common-hooks/useI18n'
import { useLazyFetchOrderDetailsQuery } from 'app/service'
import TrackingDetails from '../tracking-details/TrackingDetails'
import ReturnOrders from '../return-orders/ReturnOrders'
import ProductDetails from '../product-details/ProductDetails'
import Invoices from '../Invoices'
import RefundDetails from '../RefundDetails'

export const OrderDetailsContext = createContext({});

export const SUB_PAGES = [
  { path: '', element: ProductDetails },
  { path: 'tracking-details', element: TrackingDetails },
  { path: 'return-orders', element: ReturnOrders },
  { path: 'fulfillment-workflow', element: 'Fullfilment' },
  { path: 'invoices', element: Invoices },
  { path: 'refund-details', element: RefundDetails },
]

function OrderDetails() {
  const { pathname } = useLocation();
  const { orderid } = useParams();
  const [fetch, { data = {}, isLoading, error }] = useLazyFetchOrderDetailsQuery();
  let activeIndex = 1;
  if (pathname.includes(SUB_PAGES[1].path)) {
    activeIndex = 2
  } else if (pathname.includes(SUB_PAGES[2].path)) {
    activeIndex = 3
  } else if (pathname.includes(SUB_PAGES[3].path)) {
    activeIndex = 4
  } else if (pathname.includes(SUB_PAGES[4].path)) {
    activeIndex = 4
  } else if (pathname.includes(SUB_PAGES[5].path)) {
    activeIndex = 5
  } else if (pathname.includes('orders')) {
    activeIndex = 0
  }

  useEffect(() => {
    fetch(orderid);
  }, []);

  return (
    <Layout wide title={orderid}>
      <OrdersNav active={activeIndex} />
      <OrderDetailsContext.Provider value={{ ...data, isLoading, error}} >
        {isLoading && (
          <>
            <Skeleton type="prescriptions" />
            <Skeleton type="prescriptions" />
          </>
        )}
        <Outlet />
      </OrderDetailsContext.Provider>
    </Layout>
  )
}

export default OrderDetails
