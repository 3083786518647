import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './Home.module.scss'
import { Link } from 'react-router-dom'

const ARROW_RIGHT = <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M0.779297 10.7402L5.75977 5.75977L0.779297 0.779297" stroke="#31395F" strokeOpacity="0.5" strokeWidth="1.40625" strokeLinecap="round" strokeLinejoin="round" /> </svg>


function HomeCard({ children, icon, title, description, focused, to, isHighlighted, wrapperClassname, handleContentScroll = () => {} }) {
  let linkContent = (
    <>
      <img className={cx(styles.icon)} src={icon} alt="" />
      <div className={cx(styles['title-description-wrapper'])}>
        <h2 className={cx(styles.title)}>{title}</h2>
        <p className={cx(styles.description)}>{description}</p>
      </div>
    </>
  )
  return (
    <>
      {focused && <div className={cx(styles.overlay)} />}
      <section className={cx(styles.card, wrapperClassname, { [cx(styles.highlighted)]: isHighlighted })}>
        {to ?
          (<Link to={to} className={cx(styles.header)}>
            {linkContent}
            {ARROW_RIGHT}
          </Link>)
          : (
            <div className={cx(styles.header)}>
              {linkContent}
            </div>
          )
        }
        <div className={styles.cardContent} onScroll={handleContentScroll}>
          {children}
        </div>
      </section>
    </>
  )
}

HomeCard.propTypes = {
  icon: PropTypes.string,
  focused: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  wrapperClassname: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  handleContentScroll: PropTypes.func,
}

export default HomeCard
