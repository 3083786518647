import cx from 'classnames'
import buttonStyles from 'components/button/Button.module.scss'
import styles from './UploadPrescription.module.scss'
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import useI18n from 'common-hooks/useI18n';
import { FIELDS } from 'common-hooks/useFields';
import { useEffect } from 'react';

function UploadPrescription({ customLabel }) {
  const { i18n } = useI18n();
  const { control, trigger } = useFormContext();
  const prescriptionImageUpload = useWatch({ name: "prescriptionImageUpload" });
  const prescriptionImageUrl = useWatch({ name: "prescriptionUrl" });
  const isExternal = useWatch({ name: FIELDS.EXTERNAL });

  const renderImage = defaultVal => {
    let val = defaultVal;
    if (prescriptionImageUpload) {
      val = URL.createObjectURL(prescriptionImageUpload);
    }

    return val ? (
      <a href={val} target="_blank" rel="noreferrer"><img className={cx(styles.image)} src={val} alt="" /></a>
    ) : null;
  }

  const validate = {
    require: () => {
      if (!isExternal) return true;
      return !prescriptionImageUpload && !prescriptionImageUrl ? i18n('Please upload prescription') : true;
    }
  }

  useEffect(() => {
    trigger('prescriptionImageUpload', 'prescriptionUrl')
  }, [isExternal])

  return (
    <div className={cx(styles.wrapper)}>
      <Controller
        control={control}
        rules={{ validate }}
        name="prescriptionImageUpload"
        render={({ field: { name, value, onChange }, fieldState: { error }, formState: { isValid } }) => (
          <div>
            <label className={cx(buttonStyles.button, buttonStyles.secondary, styles.file, buttonStyles.small, { [buttonStyles.error]: error?.message && !isValid })}>
              {customLabel ? customLabel : i18n('Upload Prescription')}
              <input name={name} type="file" onChange={e => onChange(e.target.files[0])} />
            </label>
            {error?.message && !isValid && <label className={cx(styles.message)}>{error?.message}</label>}
          </div>
        )} />
      <Controller
        rules={{ validate }}
        control={control}
        name="prescriptionUrl"
        render={({ field: { value } }) => renderImage(value)}
      />
    </div>
  )
}

export default UploadPrescription;
