import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { FormProvider, useForm } from 'react-hook-form'
import AddFields from './AddFields'
import { useAddPrescriptionMutation } from 'app/service'
import { DEFAULT_VALUES } from 'components/prescription/prescription-constants'
import { useLocation, useNavigate } from 'react-router'
import { Message } from 'components'
import prescriptionIcon from './prescription.png'
import useI18n from 'common-hooks/useI18n'
import { selectUser } from 'features/login/loginSlice'
import { useSelector } from 'react-redux'
import { selectDefaults } from 'appSlice'
import { toast } from 'react-toastify'

function Add({ customer }) {
  const { i18n } = useI18n();
  const methods = useForm({ mode: 'all', defaultValues: { left: DEFAULT_VALUES, right: DEFAULT_VALUES } });
  const [isImagesUploading, setIsImagesUploading] = useState(false);
  const [addPrescription, { data, isLoading: isSubmitting }] = useAddPrescriptionMutation();
  const { name: staffName } = useSelector(selectUser);
  const { country } = useSelector(selectDefaults);
  const navigate = useNavigate();
  const { state } = useLocation();

  const uploadPrescription = async (data) => {
    setIsImagesUploading(true);

    if (!data.prescriptionImageUpload) {
      return Promise.resolve('')
    };

    const body = new FormData();
    body.append("file", data.prescriptionImageUpload);

    const resp = await fetch(`${`${process.env.REACT_APP_API_BASE_URL}/api/v1/customer-prescription/upload-prescription?file`}`, {
      method: 'POST',
      body,
    });

    return resp.json();
  }

  const handleSubmit = async formData => {
    try {
      const { url: prescriptionUrl } = await uploadPrescription(formData);
      const { data: response } =  await addPrescription({ ...customer, countryCode: country, prescriptions: [{...formData, prescriptionUrl}] });
      if (response){
        navigate(`/delivery-promise?prescriptionId=${response?.saveCustomerPrescriptions?.latestPrescriptionId}`);
      }
    } catch (error) {
      toast.error(i18n('Failed to add prescription'));
    } finally {
      setIsImagesUploading(false);
    }
  }

  useEffect(() => {
    if (staffName) {
      methods.setValue('staffName', staffName);
    }
  }, [staffName])

  useEffect(() => {
    if (state?.appointmentId) {
      methods.setValue('appointmentId', state.appointmentId);
    }
  }, [state?.appointmentId])

  return (
    <FormProvider {...methods}>
      <AddFields loading={isSubmitting || isImagesUploading} onSubmit={methods.handleSubmit(handleSubmit)} />
      {data?.saveCustomerPrescriptions?.id && (
        <Message icon={prescriptionIcon} message={i18n('Prescription added successfully')} cta={i18n('Done')} onClick={() => navigate(`/customers/${data.saveCustomerPrescriptions.id}/prescriptions`, { state: { dontRedirect: true } })}  />
      )}
    </FormProvider>
  )
}

Add.propTypes = {
  customer: PropTypes.object
}

export default Add
