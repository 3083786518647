import cx from 'classnames';
import styles from './GetPrescription.module.scss';
import { Button } from 'components';
import useI18n from 'common-hooks/useI18n';
import { useState } from 'react';
import GetPrescriptionsModal from './GetPrescriptionsModal';

function GetPrescription({ email, onSelect, phone, customerId, onModalClose, onModalOpen }) {
  const { i18n } = useI18n();
  const [isOpened, setIsOpened] = useState(false);
  const handleOpen = () => {
    onModalOpen?.();
    setIsOpened(true);
  }

  const handleClose = () => {
    onModalClose?.();
    setIsOpened(false);
  }

  const handleSelect = () => {
    onSelect();
    setIsOpened(false);
  }

  return (
    <>
      <Button className={cx(styles.button)} onClick={handleOpen} size="small">{i18n('Get Prescription')}</Button>
      {isOpened && <GetPrescriptionsModal onSelect={handleSelect} email={email} phone={phone} customerId={customerId} onClose={handleClose} />}
    </>
  )
}

export default GetPrescription;
