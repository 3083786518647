import React, { useState } from 'react'
import { Layout, Message, UserBar, UserStores } from 'components'
import { useNavigate } from 'react-router'
import useI18n from 'common-hooks/useI18n';
import cx from 'classnames';
import styles from './Admin.module.scss'
import BulkUpload from './BulkUpload';
import { useBulkUploadSkuIdsMutation, useBulkUploadUsersMutation } from 'app/service';
import successIcon from './success.png';

function Admin() {
  const navigate = useNavigate();
  const { i18n } = useI18n();
  const [messageProps, setMessageProps] = useState({});
  const [bulkUploadSkuIDs] = useBulkUploadSkuIdsMutation();
  const [bulkUploadUsers] = useBulkUploadUsersMutation();

  const handleUploadSkuIdsFileLoaded = fileData => {
    let working = [...fileData];
    const invalidMessageProps = {
      error: true,
      message: i18n('Data not uploaded'),
      info: i18n('At least one SKU should be added in the correct format in a single column')
    }

    if (!working?.[0]?.[0]?.toLowerCase?.().includes?.('sku')) {
      setMessageProps(invalidMessageProps);
      return;
    };

    working.shift();

    if (!working.length) {
      setMessageProps(invalidMessageProps);
      return;
    };

    working = working.map(x => x[0]);

    handleSkusUpload(working);
  }

  const handleUploadUsersFileLoaded = fileData => {
    const columns = "email,name,roles,stores";
    let working = { columns: fileData[0]?.map(x => x.toLowerCase()) || [] };
    const invalidMessageProps = {
      error: true,
      message: i18n('Data not uploaded'),
      info: i18n('Email, Name, Roles, Stores columns should be present in the same order')
    }

    if (columns !== working.columns.join(',')) {
      setMessageProps(invalidMessageProps);
      return;
    };

    fileData.shift();

    if (!fileData.length) {
      setMessageProps(invalidMessageProps);
      return;
    };

    working.rows = fileData;
    handleUsersUpload(working);
  }

  const handleSkusUpload = async (data) => {
    const res = await bulkUploadSkuIDs(data);
    const messageProps = {}

    if (res?.data?.bulkUploadSkuIDs) {
      messageProps.icon = successIcon;
      messageProps.message = i18n('SKUs uploaded successfully');
    } else {
      messageProps.message = i18n('Data not uploaded');
      messageProps.error = true;
      messageProps.info = i18n('Something went wrong. Please try again later');
    }

    setMessageProps(messageProps);
  }

  const handleUsersUpload = async (data) => {
    const res = await bulkUploadUsers(data);
    const messageProps = {}

    if (res?.data?.bulkUploadUsers) {
      messageProps.icon = successIcon;
      messageProps.message = i18n('Users updated successfully');
    } else {
      messageProps.message = i18n('Data not uploaded');
      messageProps.error = true;
      messageProps.info = i18n('Something went wrong. Please try again later');
    }

    setMessageProps(messageProps);
  }

  return (
    <Layout narrow  title={i18n('Admin')}>
      <div className={cx(styles.wrapper)}>
        <UserBar />
        {/* <UpcomingAppointments /> */}
        <BulkUpload label={i18n('Bulk order list SKU’s upload')} ctaText={i18n('Upload SKUs')} template="bulk-skus-template.csv" onChange={handleUploadSkuIdsFileLoaded} />
        <BulkUpload label={i18n('Bulk users upload')} ctaText={i18n('Upload users')} template="bulk-users-template.csv" onChange={handleUploadUsersFileLoaded} />
        <UserStores mode="default"  />
      </div>
      {!!messageProps.message && <Message {...messageProps} cta={i18n('Close')} onClick={() => setMessageProps({})} />}
    </Layout>
  )
}

Admin.propTypes = {}

export default Admin
