import React, { useEffect, useState } from 'react';
import { useGoogleLogin, useGoogleLogout } from 'react-google-login'
import { useDispatch } from 'react-redux';
import useI18n from 'hooks/useI18n';
import { addAlert, clearAlerts } from 'src/appSlice';
import { fetchTokenAsync, updateLoginData, updateToken, updateUser } from 'features/login/loginSlice';
import { useNavigate } from 'react-router';
import { useLoginMutation } from 'app/service';
import { persistor } from 'app/store';

export const EG_SWITCHED_USER = 'eg-switched-user';
export const EG_STORE_KEY = 'eg-store';

export const EG_ROLE_NAMES = {
  ADMIN: 'ADMIN',
  EG_ADMIN: 'EG_ADMIN',
  EG_STORE: 'EG_STORE',
  EG_OPTOM: 'EG_OPTOM',
  EG_USER: 'EG_USER',
  FIELD_EXECUTIVE: 'FIELD_EXECUTIVE',
}

export const EG_ROLES = [
  {
    'roleId': 1,
    'roleName': 'ADMIN'
  },
  {
    'roleId': 2,
    'roleName': 'EG_ADMIN'
  },
  {
    'roleId': 3,
    'roleName': 'EG_STORE'
  },
  {
    'roleId': 4,
    'roleName': 'EG_OPTOM'
  },
  {
    'roleId': 5,
    'roleName': 'FIELD_EXECUTIVE'
  }
]

function useLogin(props) {
  const { onSignedIn } = props || {};
  const { i18n } = useI18n();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectUsersToSwitch, setSelectUsersToSwitch] = useState(false);
  const [usersInStore, setUsersInStore] = useState([]);
  const [googleToeken, setGoogleToken] = useState(null);
  const [manualLogin] = useLoginMutation();
  const { signIn } = useGoogleLogin({
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    onSuccess: e => onSuccess(e),
    onFailure: e => onFailure(e),
    onRequest: () => setLoading(true),
    hostedDomain: 'eyewa.com',
    isSignedIn: true,
    fetchBasicProfile: true,
    onAutoLoadFinished: e => redirectToLoginIfNotLoggedIn(e)
  });

  const { signOut: logout } = useGoogleLogout({
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    onLogoutSuccess: () => onLogout()
  })

  const onSuccess = async ({ tokenId }, email) => {
    const res = await dispatch(fetchTokenAsync(tokenId, window.localStorage.getItem(EG_SWITCHED_USER) || email));
    if (res?.isStore) {
      setSelectUsersToSwitch(true)
      setUsersInStore(res.users);
      setGoogleToken(res.token);
    } else {
      onSignedIn?.();
    };
  }

  const onFailure = e => {
    console.log(e);
    dispatch(addAlert({ message: i18n(e.error) }));
    setLoading(false);
  }

  const onLogout = () => {
    window.localStorage.removeItem('eg_token');
    navigate('/login');
    persistor?.purge?.();
  }

  const onUserSelect = async email => {
    window.localStorage.setItem(EG_SWITCHED_USER, email);
    onSuccess({ tokenId: googleToeken }, email);
  }

  const signOut = () => {
    window.localStorage.removeItem('eg_token');
    window.localStorage.removeItem(EG_SWITCHED_USER);
    dispatch(updateToken(null));
    dispatch(updateUser({}));
    persistor?.purge?.();
    logout();
  }

  const redirectToLoginIfNotLoggedIn = (didLoad) => {
    if (!didLoad) {
      try {
        const token = window.localStorage.getItem('eg_token');
        if (!token) {
          navigate('/login')
        }
        // handleManualLogin({ target: { elements: { username: { value: JSON.parse(atob(token))?.username }, password: { value: JSON.parse(atob(token))?.password } } } });
      } catch {
        navigate('/login')
      }
    };
  }

  const handleManualLogin = async e => {
    e?.preventDefault?.();
    const { username, password } = e.target.elements;
    window.localStorage.setItem('eg_token', btoa(JSON.stringify({ username: username.value, password: password.value })));
    const res = await manualLogin({ email: username.value, password: password.value });
    if (res?.data) {
      dispatch(updateLoginData(res?.data));
      onSignedIn?.();
    } else {
      window.localStorage.removeItem('eg_token');
      navigate('/login')
      dispatch(addAlert({ message: i18n(res?.error?.data?.message) || 'Invalid credentials' }));
      persistor?.purge?.();
    }
  }

  return { signIn, signOut, loading, selectUsersToSwitch, usersInStore, onUserSelect, handleManualLogin, clearAlert: () => dispatch(clearAlerts()) };
}

export default useLogin;
