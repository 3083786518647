import { useEffect, useState } from 'react'
import { selectToken } from 'features/login/loginSlice';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useLazyGetPendingPrescriptionOrdersQuery } from 'app/service';
import { selectDefaults } from 'appSlice';

const DEFAULT_FILTERS = { term: '', storeName: '', status: '', createdAtFrom: '2022-04-05', createdAtTo: dayjs().format('YYYY-MM-DD'), page: 1 };

function usePendingPrescriptionOrders() {
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const { storeName } = useSelector(selectDefaults);
  const [getOrders, { data: currentData, isLoading, isUninitialized }] = useLazyGetPendingPrescriptionOrdersQuery();
  const [data, setData] = useState([]);
  const token = useSelector(selectToken);

  const buildQuery = () => {
    return {
      term: filters.term,
      storeName: filters.storeName,
      status: filters.status,
      createdAtFrom: filters.createdAtFrom,
      createdAtTo: filters.createdAtTo,
      page: filters.page
    };
  }

  const filter = () => {
    const query = buildQuery();
    getOrders(query);
  }

  const onFilterReset = () => setFilters(DEFAULT_FILTERS);

  const handleFilterChange = filters => {
    setFilters({...filters, page: 1});
  }

  const handleNextPage = () => {
    setFilters({...filters, page: filters.page + 1 });
  }

  useEffect(() => {
    setFilters({ ...filters, storeName });
  }, [storeName]);

  useEffect(() => {
    setData(filters.page === 1 ? (currentData || []) : [...data, ...(currentData || [])]);
  }, [currentData])

  useEffect(() => {
    if (token) filter();
  }, [token, filters.page, filters.term, filters.status, filters.storeName, filters.createdAtFrom, filters.createdAtTo])

  return {
    data,
    filters,
    onFilterChange: handleFilterChange,
    loading: isLoading,
    isUninitialized,
    onFilterReset,
    handleNextPage
  }
}

export default usePendingPrescriptionOrders
