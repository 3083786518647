import cx from 'classnames';
import styles from './GetPrescription.module.scss';
import { Button } from 'components';
import useI18n from 'common-hooks/useI18n';
import { useLazyGetCustomerQuery } from 'app/service';
import { useEffect } from 'react';
import StaticLayout from 'components/layout/StaticLayout';
import { useFormContext, useWatch } from 'react-hook-form';
import { LENS_TYPE_MAPPINGS } from 'components/prescription-fields/prescription-fields-constants';
import { Skeleton } from 'components';

function GetPrescriptionsModal({ onClose, email, onSelect, phone, customerId }) {
  const { i18n } = useI18n();
  const [getCustomerPrescriptions, { data, isLoading }] = useLazyGetCustomerQuery();
  const { setValue, trigger } = useFormContext();
  const lensType = useWatch({ name: 'lensType' });

  useEffect(() => {
    getCustomerPrescriptions(customerId);
  }, [])

  const renderRow = (key, lens) => (
    <tr>
      <td>{i18n(key)}</td>
      <td>{lens.right[key]}</td>
      <td>{lens.left[key]}</td>
    </tr>
  )

  const handleSelect = lens => {
    setValue('storeName', lens.storeName);
    setValue('staffName', lens.staffName);
    Object.keys(lens.left).forEach(k => setValue(`left.${k}`, lens.left[k]))
    Object.keys(lens.right).forEach(k => setValue(`right.${k}`, lens.right[k]))
    trigger(['right.pupilDiameter', 'left.pupilDiameter']);
    onSelect();
  }

  const handleClose = () => {
    onClose();
    trigger(['right.pupilDiameter', 'left.pupilDiameter']);
  }

  return (
    <div className={cx(styles.modal)}>
      <StaticLayout title={i18n('Get user prescriptions')} narrow backTo={handleClose}>
        <Button className={cx(styles.back)} theme="primary" onClick={handleClose}>{i18n('Back')}</Button>
        {isLoading ? <Skeleton type="get-prescriptions" /> : (<ul className={cx(styles.cards)}>
          {data?.prescriptions?.map(x => (
            <li className={cx(styles.card)} key={x.id}>
              <div className={cx(styles['title-cta-row'])}>
                <h3 className={cx(styles.name)}>{i18n(x.name)}</h3>
                <Button className={cx(styles.cta)} disabled={lensType ? lensType.toLowerCase() !== x.lensType.toLowerCase() : false} size="small" theme="secondary" onClick={() => handleSelect(x)}>{i18n('Select')}</Button>
              </div>
              <p className={cx(styles['lens-row'])}>
                <span className={cx(styles['lens-type-label'])}>{i18n('Lens type')}</span>
                <span className={cx(styles['lens-type-value'])}>{x.lensType}</span>
              </p>
              <table className={cx(styles.table)}>
                <thead>
                  <tr>
                    <th></th>
                    <th>{i18n('Right (OD)')}</th>
                    <th>{i18n('Left (OS)')}</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(LENS_TYPE_MAPPINGS[x.lensType] ?? {
                    sphere: { enabled: true },
                    cylinder: { enabled: true },
                    axis: { enabled: true },
                    pupilDiameter: { enabled: true },
                  }).map(lens => renderRow(lens, x))}
                </tbody>
              </table>
            </li>
          ))}
        </ul>)}
      </StaticLayout>
    </div>
  )
}

export default GetPrescriptionsModal;
