import React from 'react'
import cx from 'classnames'
import styles from './order/Order.module.scss'
import useI18n from 'common-hooks/useI18n'

export function Resync () {
  const { i18n } = useI18n();

  return (
    <div className={cx(styles.sync)}>
      <p className={cx(styles['sync-message'])}>{i18n('Syncing order, please wait')}</p>
      <div className={cx(styles['sync-loader'])} />
    </div>
  )
}
