import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './ResetPassword.module.scss'
import useI18n from 'common-hooks/useI18n'
import { Button, Input, Layout } from 'components'
import CopyIcon from './../../icons/Copy_Icon.svg'
import { useChangePasswordUserMutation } from 'app/service'
import PasswordInput from 'components/form-elements/PasswordInput'
import { useNavigate } from 'react-router'


function ResetPassword() {
  const [oldPassword, setOldPassword] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const { i18n } = useI18n();
  const [changePassword] = useChangePasswordUserMutation();
  const navigate = useNavigate();

  const handleCopyPassword = async (e) => {
    e.stopPropagation();
    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(password);
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = password;
      textArea.style.position = "absolute";
      textArea.style.left = "-999999px";

      document.body.prepend(textArea);
      textArea.select();

      try {
        document.execCommand('copy');
      } catch (error) {
        console.error(error);
      } finally {
        textArea.remove();
      }
    }
  }

  const OnUpdatePasswordClick = async () => {
    if (password !== confirmPassword) {
      alert('Password and confirm password do not match');
      return;
    }
    let res = await changePassword({ data: { oldPassword, password } });
    if (res.data.status === 'true') {
      navigate('/')
    } else {
      alert('Failed to update password');
    }
  }

  return (
    <Layout title={i18n('Settings')}>
      <h4 className={cx(styles.title)}>{i18n(`Change your password`)}</h4>
      <div className={styles.inputs}>
        <PasswordInput className={styles.input} name="oldPassword" label='Old password' type="text" value={oldPassword} onChange={e => setOldPassword(e.target.value)} />
      </div>
      <div className={styles.inputs}>
        <PasswordInput className={styles.input} name="newPassword" label='New password' type="text" value={password} onChange={e => setPassword(e.target.value)} />
      </div>
      <div className={styles.inputs}>
        <PasswordInput className={styles.input} name="confirmPassword" label='Confirm password' type="text" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
        <img src={CopyIcon} title="Copy password to clipboard" className={styles.copyIcon} onClick={handleCopyPassword} />
      </div>
      <div className={cx(styles.actions)}>
        {/* <Button theme="secondary" className={cx(styles.action)}>{i18n('Cancel')}</Button> */}
        <Button onClick={OnUpdatePasswordClick}>{i18n('Update')}</Button>
      </div>
    </Layout>
  )
}

ResetPassword.propTypes = {}

export default ResetPassword
