import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { useNavigate, useParams } from 'react-router';
import { useLazyFetchWorkingHoursQuery } from 'app/service';
import { Button, Layout, SimpleSelect, UserBar } from 'components';
import useI18n from 'common-hooks/useI18n';
import WeeklyHours from './WeeklyHours';
import cx from 'classnames'
import styles from './List.module.scss'
import { useSelector } from 'react-redux';
import { selectToken } from 'features/login/loginSlice';
import ViewToggle from '../ViewToggle';
import { createWeekIdString } from '../utils';
import { selectDefaults } from 'appSlice';

dayjs.extend(weekOfYear);

function List() {
  const { current: today } = useRef(dayjs().day());
  const { current: days } = useRef(['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY']);
  const { current: shortDays } = useRef(['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']);
  const {i18n} = useI18n();
  const { country } = useSelector(selectDefaults);
  const token = useSelector(selectToken);
  const { current: year } = useRef(dayjs().year());
  const { store: storeId } = useParams();
  const [isCustom, setIsCustom] = useState(false);
  const { current: currentWeek } = useRef(dayjs().week());
  const [week, setWeek] = useState(currentWeek);
  const [fetchWorkingHours, { data: workingHours, isLoading, isUninitialized, fulfilledTimeStamp }] = useLazyFetchWorkingHoursQuery();

  const getFormattedWeekValue = weekNo => {
    const dayjsWeek = dayjs().year(year).week(weekNo);
    const firstDay = dayjsWeek.startOf("week");
    const lastDay = dayjsWeek.endOf("week");
    const firstMonth = firstDay.month();
    const lastMonth = lastDay.month();

    const firstDayString = firstDay.format(firstMonth === lastMonth ? 'D' : 'D MMM');
    const lastDayString = lastDay.format('D MMM');

    return `${firstDayString} - ${lastDayString}, ${lastDay.format('YYYY')}`;
  };

  const compileWeekOptions = () => {
    const thirdWeekIdx = currentWeek + 2;
    const fourthWeekIdx = currentWeek + 3;

    return [
      { label: i18n('This week'), value: currentWeek },
      { label: i18n('Next week'), value: currentWeek + 1 },
      { label: getFormattedWeekValue(thirdWeekIdx), value: thirdWeekIdx },
      { label: getFormattedWeekValue(fourthWeekIdx), value: fourthWeekIdx },
    ]
  };

  const { current: weekOptions } = useRef(compileWeekOptions());
  const title = useMemo(() => {
    if (isCustom) {
      const suffix = weekOptions.find(x => x.value === week)?.label;
      return `${i18n('Override hours for')} ${suffix}`;
    } else {
      return i18n('Set default hours')
    }
  }, [isCustom, week]);

  const selectedWeekHours = useMemo(() => {
    if (isCustom) {
      return workingHours?.customWorkingHours?.[createWeekIdString({ week, year })]?.workingHours || []
    } else {
      return workingHours?.defaultWorkingHours?.workingHours || []
    }
  }, [isCustom, week, workingHours])

  const buildHoursObject = hoursData => {
    const array = [];

    days.forEach((day, i) => {
      const hoursForDay = hoursData.filter(x => x.day === day).map(x => ({ fromTime: x.fromTime, toTime: x.toTime }));
      array.push({ day, hours: hoursForDay, label: shortDays[i], disabled: isCustom && currentWeek === week ? i < today : false });
    });

    return array;
  }

  useEffect(() => {
    if (token && country) fetchWorkingHours({
      storeId,
      country,
      weekIds: [currentWeek, currentWeek + 1, currentWeek + 2, currentWeek + 3]
        .map(x => createWeekIdString({week: x, year}))
        .join(',')
    });
  }, [week, token, isCustom, country])

  return (
    <Layout narrow title={i18n('Edit availability')} >
      <div className={cx(styles.wrapper)}>
        <UserBar />
        <div className={cx(styles.selector)}>
          <ViewToggle current="list" />
          <div className={cx(styles.cols)}>
            <div className={cx(styles.col)}>
              <h2 className={cx(styles.title)}>{title}</h2>
              <WeeklyHours
                key={isCustom ? `${fulfilledTimeStamp}-${week}` : `${fulfilledTimeStamp}-default`}
                isCustom={isCustom}
                data={buildHoursObject(selectedWeekHours)}
                loading={isUninitialized || isLoading}
                weekId={createWeekIdString({week, year})}
              />
            </div>
            <div className={cx(styles.col)}>
              <h2 className={cx(styles.title)}>{i18n('Add date overrides')}</h2>
              <p className={cx(styles.description)}>{i18n('You can optionally override dates for the next 4 weeks')}</p>
              {isCustom && <SimpleSelect label={i18n('Select week')} options={weekOptions} value={week} onChange={setWeek} />}
              <Button onClick={() => setIsCustom(!isCustom)} className={cx(styles['week-trigger'])} theme="secondary">
                {i18n(isCustom ? 'Edit default dates' : 'Override dates')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

List.propTypes = {}

export default List
