import { useEffect, useMemo, useState } from 'react'
import { useLazyGetCustomersQuery, useLazyGetSummaryQuery } from 'src/app/service';
import { selectToken } from 'features/login/loginSlice';
import { useSelector } from 'react-redux';
import useI18n from 'hooks/useI18n';
import dayjs from 'dayjs';
import { selectDefaults } from 'appSlice';


const DEFAULT_FILTERS = { term: '', storeName: '', createdAtFrom: '2022-04-05', createdAtTo: dayjs().format('YYYY-MM-DD') };

function useDashboard() {
  const { i18n } = useI18n();
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const { storeName } = useSelector(selectDefaults);
  const [getCustomers, { data = [], isLoading, isUninitialized }] = useLazyGetCustomersQuery();
  const [getSummary, { data: summary = {} }] = useLazyGetSummaryQuery();
  const token = useSelector(selectToken);
  const columns = useMemo(() => [
    { Header: i18n('Date'), accessor: 'date' },
    { Header: i18n('Staff Email'), accessor: 'staffEmail' },
    { Header: i18n('First Name'), accessor: 'firstName' },
    { Header: i18n('Last Name'), accessor: 'lastName' },
    { Header: i18n('Customer Email'), accessor: 'email' },
    { Header: i18n('Mobile No'), accessor: 'phone' },
  ], []);

  const buildQuery = () => {
    return {
      filter: {
        term: filters.term,
        storeName: filters.storeName,
        createdAtFrom: filters.createdAtFrom,
        createdAtTo: filters.createdAtTo
      },
      perPage: 10000
    };
  }

  const filter = () => {
    const query = buildQuery();
    getCustomers(query);
    getSummary(query);
  }

  const onFilterReset = () => setFilters(DEFAULT_FILTERS);

  useEffect(() => {
    setFilters({ ...filters, storeName });
  }, [storeName])

  useEffect(() => {
    if (token) filter();
  }, [token, filters.term, filters.storeName, filters.createdAtFrom, filters.createdAtTo])

  return {
    columns,
    data,
    filters,
    onFilterChange: setFilters,
    summary,
    loading: isLoading,
    isUninitialized,
    onFilterReset
  }
}

export default useDashboard
