import React from 'react'
import cx from 'classnames'
import styles from './StoreAnalytics.module.scss'
import { Outlet, useLocation } from 'react-router'
import StoreAnalyticsNav from './StoreAnalyticsNav';
import { Layout } from 'components';

export default function StoreAnalytics() {
  const { pathname } = useLocation();

  let activeIndex = 0;
  if (pathname.includes('eyetest-schedule')) {
    activeIndex = 0;
  } else if (pathname.includes('store-info')) {
    activeIndex = 1;
  }
  return (
    <Layout title={'Store analytics'}>
      <div className={cx(styles.wrapper)}>
        <StoreAnalyticsNav active={activeIndex} />
        <Outlet />
      </div>
    </Layout>
  )
}
