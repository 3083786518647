import { combineReducers, configureStore, isRejectedWithValue } from '@reduxjs/toolkit';
import { api, rest } from 'src/app/service';
import appReducer from 'src/appSlice';
import loginReducer from 'features/login/loginSlice';
import { Bounce, toast } from 'react-toastify';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    console.warn('action', action)
    // action.meta.baseQueryMeta.request.url
    if (action?.payload?.data === "Success") return;
    const message = action?.payload?.data?.message || 'something went wrong' //'data' in action.error ? action.error.data.message : action.error.message;
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }

  return next(action)
}

const rootReducer = combineReducers({
  app: appReducer,
  login: loginReducer,
  api: api.reducer,
  rest: rest.reducer
})

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['login', 'app'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PURGE, PERSIST, REGISTER],
      },
    }).concat(rtkQueryErrorLogger).concat(api.middleware).concat(rest.middleware),
});

export const persistor = persistStore(store)
