import useI18n from 'common-hooks/useI18n';
import { InfoCard } from 'components';
import React, { useCallback, useMemo } from 'react'
import cx from 'classnames';
import styles from './ReadOnlySku.module.scss';
import { expandAddons } from '../sku-form/utils';

export function ReadOnlySku({ power, parentItemId, childItemId, children }) {
  const { i18n } = useI18n();

  const prescriptionDetail = useMemo(() => {
    return {
      [i18n('Parent id')]: parentItemId,
      [i18n('Children')]: children?.map?.(x => x.name).join(', '),
      [i18n('Lens id')]: childItemId,
      [i18n('Lens type')]: power.lensType,
    }
  }, [power, childItemId, parentItemId, i18n]);

  const glassProperties = useMemo(() => {
    return {
      [i18n('Index')]: power.index,
      [i18n('Index type')]: power.addOn8,
      [i18n('Corridor')]: power.corridor,
      [i18n('Lens diameter')]: power.lensDiameter,
      [i18n('Lens shape')]: power.lensShape,
      [i18n('Base curve')]: power.baseCurve,
      [i18n('Add on')]: expandAddons([power.addOn1, power.addOn2, power.addOn3, power.addOn5, power.addOn6, power.addOn7].filter(Boolean)).join(', '),
      [i18n('Add on color')]: power.addOnColor,
      [i18n('Package')]: power.addOnPackage,
      [i18n('Comment')]: power.comment?.comment,
    }
  }, [power, i18n]);

  const renderEye = useCallback((eye) => {
    return {
      [i18n('Sphere (SPH)')]: power[eye].sphere,
      [i18n('Cylinder (CYL)')]: power[eye].cylinder,
      [i18n('Axis (AXIS)')]: power[eye].axis,
      [i18n('Pupil Distance (IPD)')]: power[eye].pupidDiameter,
      [i18n('Additional Power (ADD)')]: power[eye].addiionalPower,
      [i18n('Segment Height (SH)')]: power[eye].segmentHeight,
    }
  }, [power, i18n]);

  return (
    <>
      <InfoCard title={i18n('Prescription Detail')} data={prescriptionDetail} />
      <div className={cx(styles.prescriptions)}>
        <InfoCard title={i18n('Right eye (OD)')} data={renderEye('right')} />
        <InfoCard title={i18n('Left eye (OS)')} data={renderEye('left')} />
      </div>
      <InfoCard title={i18n('Glass properties')} data={glassProperties} />
    </>
  )
}

export default ReadOnlySku;
