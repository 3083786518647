import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './Cta.module.scss'

const ARROW = <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M0.779297 10.7402L5.75977 5.75977L0.779297 0.779297" stroke="#31395F" strokeOpacity="0.5" strokeWidth="1.40625" strokeLinecap="round" strokeLinejoin="round"/> </svg>

function Cta({ icon, title, description, onClick }) {
  return (
    <button className={cx(styles.wrapper)} onClick={onClick}>
      {icon}
      <div className={cx(styles.content)}>
        <div className={cx(styles.title)}>{title}</div>
        {!!description && <div className={cx(styles.description)}>{description}</div>}
      </div>
      {ARROW}
    </button>
  )
}

Cta.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func
}

export default Cta
