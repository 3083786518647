import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './ChangePasswordModal.module.scss'
import useI18n from 'common-hooks/useI18n'
import { Button, Input } from 'components'

const COPY_ICON = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 330 330" xmlSpace="preserve"><path fill="currentColor" d="M35 270h45v45c0 8.284 6.716 15 15 15h200c8.284 0 15-6.716 15-15V75c0-8.284-6.716-15-15-15h-45V15c0-8.284-6.716-15-15-15H35c-8.284 0-15 6.716-15 15v240c0 8.284 6.716 15 15 15zm245 30H110V90h170v210zM50 30h170v30H95c-8.284 0-15 6.716-15 15v165H50V30z"/><path fill="currentColor" d="M155 120c-8.284 0-15 6.716-15 15s6.716 15 15 15h80c8.284 0 15-6.716 15-15s-6.716-15-15-15h-80zM235 180h-80c-8.284 0-15 6.716-15 15s6.716 15 15 15h80c8.284 0 15-6.716 15-15s-6.716-15-15-15zM235 240h-80c-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15h80c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15z"/></svg>

function ChangePasswordModal({loading, onClose, onSubmit}) {
  const [password, setPassword] = useState();
  const {i18n} = useI18n();

  const handleCopyPassword = async (e) => {
    e.stopPropagation();
    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(password);
    } else {
        const textArea = document.createElement("textarea");
        textArea.value = password;
        textArea.style.position = "absolute";
        textArea.style.left = "-999999px";

        document.body.prepend(textArea);
        textArea.select();

        try {
            document.execCommand('copy');
        } catch (error) {
            console.error(error);
        } finally {
            textArea.remove();
        }
    }
  }

  const OnUpdatePasswordClick = () => {
    onSubmit({newPassword: password});
  }

  return (
    <div className={cx(styles.overlay)}>
      <div className={cx(styles.modal)}>
        <h4 className={cx(styles.title)}>{i18n(`Change password`)}</h4>
        <div className={styles.inputs}>
          <Input className={styles.input} name="newPassword" label='New password' type="text" value={password} onChange={e => setPassword(e.target.value)} />
          <span title="Copy password to clipboard" className={styles.copyIcon} onClick={handleCopyPassword}>{COPY_ICON}</span>
        </div>
        <div className={cx(styles.actions)}>
          <Button loading={loading} onClick={onClose} theme="secondary" className={cx(styles.action)}>{i18n(`Cancel`)}</Button>
          <Button onClick={OnUpdatePasswordClick} className={cx(styles.action)}>{i18n('Update')}</Button>
        </div>
      </div>
    </div>
  )
}

ChangePasswordModal.propTypes = {
  onClose: PropTypes.func,
  date: PropTypes.object,
  workingHours: PropTypes.arrayOf(PropTypes.shape({
    day: PropTypes.string,
    fromTime: PropTypes.string,
    toTime: PropTypes.string,
    available: PropTypes.bool
  }))
}

export default ChangePasswordModal
