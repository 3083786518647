import { useLazyUsersQuery } from "app/service";
import useI18n from "common-hooks/useI18n";
import { useEffect, useMemo, useState } from "react";

function useDrivers() {
  const { i18n } = useI18n();
  const [filters, setFilters] = useState({});
  const [fetch, { data: items = [] }] = useLazyUsersQuery();

  const columns = useMemo(() => [
    { Header: i18n('Id'), accessor: 'id' },
    { Header: i18n('User Name'), accessor: 'username' },
    { Header: i18n('First Name'), accessor: 'firstName' },
    { Header: i18n('Last Name'), accessor: 'lastName' },
    { Header: i18n('Email'), accessor: 'email' },
    {
      Header: i18n('Role'), accessor: 'roles', Cell: ({ cell: { row: { original } } }) => {
        const roles = original.roles.map(role => role.roleName).join(', ');
        return roles;
      }
    },
  ], []);

  const searchedItems = useMemo(() => {
    if (!filters.term) return items;
    return items.filter(item => {
      const { username, email } = item;
      const searchValue = filters.term.toLowerCase();
      return username.toLowerCase().includes(searchValue)
        || email.toLowerCase().includes(searchValue)
    });
  }, [filters, items]);

  useEffect(() => {
    fetch(5);
  }, [])

  return { items: searchedItems, columns, setFilters }
}

export default useDrivers;
