import React from 'react'
import { Button, FilterCard, Layout } from 'components'
import useI18n from 'common-hooks/useI18n'
import useOrders from './useOrders';
import cx from 'classnames';
import styles from './Orders.module.scss';
import noResultsIcon from 'components/message/error.png'
import tableStyles from '../dashboard/Dashboard.module.scss'
import { useTable, useFlexLayout } from 'react-table';

function Orders() {
  const { i18n } = useI18n();
  const { data, columns, handleNextPage, isUninitialized, filters, onFilterChange, onFilterReset, loading, hasMore, hasError, error } = useOrders();
  const hasNoResults = !isUninitialized && !loading && !data.length;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data
  }, useFlexLayout);

  return (
    <Layout title={i18n('Orders')} preContent={<FilterCard showSearchByCustomerId value={filters} onChange={onFilterChange} onReset={onFilterReset} newOrderStatuses showAssemblyFilter />} wide >
      <div className={cx(styles.wrapper)}>
        {hasNoResults ? (
          <div className={cx(styles['no-results'])}>
            <img className={cx(styles['no-results-icon'])} src={noResultsIcon} alt="" />
            <p className={cx(styles['no-results-message'])}>{i18n('No results')}</p>
            <p className={cx(styles['no-results-info'])}>{i18n('Please try refining your search')}</p>
          </div>
        ) : (
          <>
            <div className={cx(tableStyles['table-wrapper'], styles['table-wrapper'])} id="tableContainer">
              <div {...getTableProps()} className={cx(tableStyles.table, styles.table)}>
                <div>
                  {headerGroups.map(headerGroup => (
                    <div {...headerGroup.getHeaderGroupProps()} className={cx(tableStyles['table-header'])}>
                      {headerGroup.headers.map(column => {
                        return <div {...column.getHeaderProps([{ className: cx(tableStyles[column.className], tableStyles.th, styles.th) }])}>{column.render('Header')}</div>
                      })}
                    </div>
                  ))}
                </div>
                <div {...getTableBodyProps()}>
                  {rows.map(row => {
                    prepareRow(row);
                    return (
                      <div {...row.getRowProps()} className={cx(tableStyles.row)}>
                        {row.cells.map((cell) => {
                          return <div {...cell.getCellProps([{ className: cx(tableStyles.cell, tableStyles[cell.column.className]) }])}>
                            {cell.render('Cell')}
                          </div>;
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            {!!hasMore && <Button className={styles.load} size="small" onClick={handleNextPage}>Load More</Button>}
          </>
        )}
      </div>
      {hasError && (
        <div className={cx(styles['no-results'])}>
          <span className={styles['error-info']}>{'Error: ' + error?.data?.message?.substring(0, 100) + '...'}</span>
        </div>
      )}
    </Layout>
  )
}

Orders.propTypes = {}

export default Orders
