import React, { useState } from 'react';
import { Button, Checkbox, FormSectionTitle, Input } from 'components';
import { useOutletContext } from 'react-router-dom';
import styles from './../Questionnaire.module.scss'
import { validate } from './validations';

const Step3 = () => {
  const [formData, handleNext] = useOutletContext();
  const [stepData, setStepData] = useState(formData.step3);
  const [validations, setValidations] = useState({});

  const handleChange = (e) => {
    setStepData(prev => ({ ...prev, [e.target.name]: e.target.value, 'None': '' }));
    setValidations(prev => ({ ...prev, [e.target.name]: '', button: '' }))
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let validationObj = validate(stepData, 3)
    if (validationObj) {
      setValidations(validationObj)
      return;
    }
    handleNext(stepData, 3);
  };

  const boolQuestion = (name, checkboxLabel) => {
    if (name === 'None') {
      return (
        <div className={styles.boolQuestion}>
          <Checkbox
            className={styles.input}
            name={name}
            value={name}
            checked={!!stepData[checkboxLabel]}
            label={checkboxLabel}
            error={validations[name]}
            onChange={e => {
              setStepData(prev => ({[checkboxLabel]: e }));
              setValidations(prev => ({ [checkboxLabel]: '', button: '' }))
            }} />
        </div>
      )
    }
    return (
      <div className={styles.boolQuestion}>
        <Checkbox
          className={styles.input}
          name={name}
          value={name}
          checked={!!stepData[checkboxLabel]}
          label={checkboxLabel}
          error={validations[name]}
          onChange={e => handleChange({
            target: {
              name: checkboxLabel,
              value: !!e
            }
          })} />
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormSectionTitle>{'Medical History: '}</FormSectionTitle>
      <div className={styles.questionContainer}>
        {boolQuestion('Diabetes', 'Diabetes')}
        {boolQuestion('Hypertension', 'Hypertension')}
        {boolQuestion('Headache', 'Headache')}
        {boolQuestion('Pregnancy', 'Pregnancy')}
        {boolQuestion('Medication for Acne', 'Medication for Acne')}
        {boolQuestion('Steroids', 'Steroids')}
        <Input
          className={styles.input}
          name='Other'
          onChange={handleChange}
          value={stepData['Other']}
          label='Other illness (if any)'
          placeholder=''
        />
        {boolQuestion('None', 'None')}
      </div>
      <Button type='submit'>{'Next'}</Button>
      {validations['button'] && <div className={styles.errorMessage}>{validations['button']}</div>}
    </form>
  );
};

export default Step3;
