import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import NavItem from './NavItem';
import styles from './SidebarNav.module.scss'
import cx from 'classnames'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from './eyewa-logo.svg'

const SidebarNav = () => {
  const [navItems, setNavItems] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    fetch('/navItems.json')
      .then(response => response.json())
      .then(data => setNavItems(data));
  }, []);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  return (
    <div className={styles['sidebar-container']}>
      <div className={cx(styles['header-col'])}>
        <Link to="/"><img className={cx(styles.logo, { [styles.open]: isSidebarOpen }, { [styles.collapsed]: !isSidebarOpen })} src={logo} alt="Eyewa EG tool" /></Link>
        <button className={styles['toggle-button']} onClick={toggleSidebar}>
          {isSidebarOpen ? '<<' : '>>'}
        </button>
      </div>
      <Sidebar>
        <div className={cx(styles.sidebar, { [styles.open]: isSidebarOpen }, { [styles.collapsed]: !isSidebarOpen })}>
          {navItems.map((navItem, index) => (
            <NavItem key={index} summary={isSidebarOpen ? navItem.summary : navItem.shortSummary}>
              <ul className={styles.list}>
                {navItem.items.map((item, idx) => (
                  <li onClick={() => navigate(item.link)} key={idx} className={cx(styles.listItem, { [styles.active]: pathname.includes(item.link ) })}>
                    <Link to={item.link} className={styles.link}>{isSidebarOpen ? item.name : item.shortName}</Link>
                  </li>
                ))}
              </ul>
            </NavItem>
          ))}
        </div>
      </Sidebar>
    </div>
  );
};

export default SidebarNav;
