import useI18n from 'common-hooks/useI18n';
import { IndexSelector, InfoCard, SimpleSelect } from 'components';
import React from 'react'
import { Controller, useWatch } from 'react-hook-form';
import cx from 'classnames';
import styles from './DeliveryPromiseCalculator.module.scss'
import prescriptionFieldsStyles from '../../components/prescription/Prescription.module.scss'
import { CYLINDER, DEFAULT_VALUES, SPHERE } from 'components/prescription/prescription-constants';

function DeliveryPromiseCalculatorFormFields() {
  const { i18n } = useI18n();
  const [addon] = useWatch({ name: ['addon'] });

  const renderSphere = side => (
    <Controller
      name={`${side}.sphere`}
      defaultValue={DEFAULT_VALUES.sphere}
      render={({ field: { value, onChange }}) => (
        <IndexSelector className={cx(styles.field)} value={value} onChange={onChange} label={i18n('Sphere (SPH)')} options={SPHERE} title={i18n('Select Sphere (SPH)')} />
      )}
    />
  )

  const renderCylinder = side => (
    <Controller
      name={`${side}.cylinder`}
      defaultValue={DEFAULT_VALUES.cylinder}
      render={({ field: { value, onChange }}) => (
        <IndexSelector className={cx(styles.field)} value={value} onChange={onChange} label={i18n('Cylinder (CYL)')} options={CYLINDER} title={i18n('Select Cylinder (CYL)')} />
      )}
    />
  )

  return (
    <>
      <InfoCard title={i18n('Lens details')}>
        <div className={cx(styles.fields)}>
          <Controller
            name="frameType"
            defaultValue="Rimless"
            render={({ field: { value, onBlur, onChange } }) => (
              <SimpleSelect
                label={i18n('Frame type')}
                className={cx(styles['frame-type'])}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                options={[
                  { label: 'Rimless', value: 'Rimless' },
                  { label: 'Non Rimless', value: 'Non Rimless' },
                ]}
              />
            )}
          />
          <Controller
            name="lensType"
            defaultValue="Single Vision"
            render={({ field: { value, onBlur, onChange } }) => (
              <SimpleSelect
                label={i18n('Lens type')}
                className={cx(styles['lens-type'])}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                options={['Single Vision', 'Progressive', 'Office lens', 'Mioeye', 'Drive', 'Anti-fatigue', 'Progressives', 'Office', 'Transition', 'Polaroid'].map(x => ({ label: x, value: x }))}
              />
            )}
          />
          <Controller
            name="addon"
            defaultValue="Blue Light"
            render={({ field: { value, onBlur, onChange } }) => (
              <SimpleSelect
                label={i18n('AddOn')}
                className={cx(styles['addon'])}
                onBlur={onBlur}
                onChange={onChange}
                placeholder="No AddOn"
                value={value}
                options={[
                  { label: 'No AddOn', value: '' },
                  { label: 'Blue Light', value: 'BL' },
                  { label: 'Photochromic', value: 'TR' },
                  { label: 'Tinting', value: 'TN' },
                  { label: 'Transitions', value: 'TS' },
                ]}
              />
            )}
          />
          <Controller
            name="color"
            render={({ field: { value, onBlur, onChange } }) => (
              <SimpleSelect
                label={i18n('Color')}
                className={cx(styles['addon-color'])}
                onBlur={onBlur}
                onChange={onChange}
                disabled={addon !== 'TR'}
                value={value}
                options={[
                  { label: i18n('Select'), value: '' },
                  { label: i18n('Grey'), value: 'Grey' },
                  { label: i18n('Green'), value: 'Green' },
                  { label: i18n('Brown'), value: 'Brown' }
                ]}
              />
            )}
          />
          <Controller
            name="package"
            defaultValue=""
            render={({ field: { value, onBlur, onChange } }) => (
              <SimpleSelect
                label={i18n('Package')}
                className={cx(styles['lens-type'])}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                options={['Standard', 'Premier', 'Elite', 'Ultra', 'Essential', 'Digital Protect', ].map(x => ({ label: x, value: x }))}
              />
            )}
          />
        </div>
      </InfoCard>
      <InfoCard title={i18n('Prescription details')}>
        <div className={cx(prescriptionFieldsStyles.cols)}>
          <div className={cx(prescriptionFieldsStyles.col)}>
            <h3 className={cx(prescriptionFieldsStyles.heading)}>{i18n('Right eye (OD)')}</h3>
            <div className={cx(styles.fields)}>
              {renderSphere('right')}
              {renderCylinder('right')}
            </div>
          </div>
          <div className={cx(prescriptionFieldsStyles.col)}>
            <h3 className={cx(prescriptionFieldsStyles.heading)}>{i18n('Left eye (OS)')}</h3>
              <div className={cx(styles.fields)}>
                {renderSphere('left')}
                {renderCylinder('left')}
              </div>
          </div>
        </div>
      </InfoCard>
    </>
  )
}

export default DeliveryPromiseCalculatorFormFields;
