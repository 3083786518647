import { useEffect, useMemo, useState } from 'react'
import { selectToken, selectUser } from 'features/login/loginSlice';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useGetOrdersViaRestMutation } from 'app/service';
import useI18n from 'common-hooks/useI18n';
import { useLocation } from 'react-router';
import styles from './Orders.module.scss'

const DEFAULT_FILTERS = { term: '', storeName: '', status: '', assemblyStatus: '', createdAtFrom: dayjs().subtract(7, 'days').format('YYYY-MM-DD'), createdAtTo: dayjs().format('YYYY-MM-DD'), page: 1 };

function useOrders() {
  const { pathname, search } = useLocation();
  const { warehouses } = useSelector(selectUser);
  const [filters, setFilters] = useState({ ...DEFAULT_FILTERS, storeName: warehouses?.find?.(x => x.default)?.storeCode });
  const [getOrders, { data: currentData, isLoading, isUninitialized, isError, error }] = useGetOrdersViaRestMutation();
  const [data, setData] = useState([]);
  const token = useSelector(selectToken);
  const [hasMore, setHasMore] = useState(true);
  const { i18n } = useI18n();
  const renderBool = cell => {
    return cell.value ? i18n('Yes') : i18n('No')
  };

  const renderGlazing = cell => {
    return <div>
      <div>Glazing: {cell.row.original.isGlazing ? 'Yes' : 'No'}</div>
      <div>Repair: {cell.row.original.isRepair ? 'Yes' : 'No'} </div>
    </div>
  }

  const renderErpStatus = cell => {
    return <div className={styles.erpStatusCell}>
    {cell.row.original?.erpStatus3 && <div>Status3: {cell.row.original?.erpStatus3}</div>}
    {cell.row.original?.erpStatus4 && <div>Status4: {cell.row.original?.erpStatus4}</div>}
    {cell.row.original?.erpStatus5 && <div>Status5: {cell.row.original?.erpStatus5}</div>}
  </div>
  }

  const renderLink = cell => {
    const base = window.location.href.split(pathname)[0];
    return <a className={styles.link} href={`${base}/orders/${cell.row.original.magentoIncrementId}`}>{cell.value}</a>
  }
  const columns = useMemo(() => [
    { Header: i18n('Store'), accessor: 'storeName', width: 100 },
    { Header: i18n('ERP order id'), accessor: 'erpOrderId', Cell: renderLink },
    { Header: i18n('Prescription Update in ERP'), accessor: 'prescriptionUpdateInErp', Cell: renderBool },
    { Header: i18n('Order date'), accessor: 'orderDate', width: 120 },
    { Header: i18n('Glazing and Repair'), accessor: 'isGlazing', Cell: renderGlazing },
    { Header: i18n('Order status'), accessor: 'orderStatus' },
    { Header: i18n('Assembly status'), accessor: 'assemblyStatus' },
    { Header: i18n('Delivery Mode'), accessor: 'dlvMode' },
    { Header: i18n('Ops comment'), accessor: 'opsComment' },
    { Header: i18n('Status 3,4,5 in ERP'), accessor: 'erpStatus5', Cell: renderErpStatus },
  ], []);

  const buildQuery = () => {
    let customerId
    if (search) {
      const params = new URLSearchParams(search);
      customerId = params.get('customerId')
    }
    return {
      term: filters.term,
      storeName: filters.storeName,
      status: filters.status,
      createdAtFrom: filters.createdAtFrom,
      createdAtTo: filters.createdAtTo,
      page: filters.page,
      assemblyStatus: filters.assemblyStatus,
      customerId,
    };
  }

  const filter = () => {
    const query = buildQuery();
    getOrders(query);
  }

  const onFilterReset = () => setFilters(DEFAULT_FILTERS);

  const handleFilterChange = filters => {
    setFilters({ ...filters, page: 1 });
  }

  const handleNextPage = () => {
    setFilters({ ...filters, page: filters.page + 1 });
  }

  useEffect(() => {
    setData(filters.page === 1 ? (currentData || []) : [...data, ...(currentData || [])]);
    setHasMore(currentData?.length);
  }, [currentData])

  useEffect(() => {
    if (token) filter();
  }, [token, filters.assemblyStatus, filters.page, filters.term, filters.status, filters.storeName, filters.createdAtFrom, filters.createdAtTo, search])

  return {
    data,
    columns,
    filters,
    onFilterChange: handleFilterChange,
    loading: isLoading,
    isUninitialized,
    onFilterReset,
    handleNextPage,
    hasMore,
    hasError: isError,
    error
  }
}

export default useOrders
