import { createSlice } from '@reduxjs/toolkit';
import { addAlert } from 'src/appSlice';
import { fetchToken } from './loginAPI';
import { addStores, updateDefaults } from 'appSlice';
import { PURGE } from 'redux-persist';

const initialState = {
  token: null,
  user: {},
  showOptomSelectionMenu: false,
};

export const loginSlick = createSlice({
  name: 'login',
  initialState,
  reducers: {
    updateToken: (state, action) => {
      state.token = action.payload;
    },
    updateUser: (state, action) => {
      state.user = action.payload;
    },
    updateShowOptomSelectionMenu: (state, action) => {
      state.showOptomSelectionMenu = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const { updateToken, updateUser, updateShowOptomSelectionMenu } = loginSlick.actions;

export const updateLoginData = (res) => (dispatch) => {
  const { message, access_token, userDTO, switchAccount, warehouses, slotsExhausted } = res || {};
  const defaultWarehouse = warehouses?.find(x => x.default);
  const copyWarehouses = warehouses?.map(x => ({ ...x, label: x.name, value: x.name }));

  if (access_token) {
    dispatch(updateToken(access_token));
    dispatch(updateUser({...userDTO, warehouses, switched: switchAccount, slotsExhausted }));
    dispatch(updateDefaults({
      storeName: defaultWarehouse?.storeCode,
      country: defaultWarehouse?.countryCode?.toLowerCase(),
      staffName: userDTO?.name,
      city: userDTO?.city,
      loaded: true,
      storeNameFull: defaultWarehouse?.name,
      storeId: defaultWarehouse.id,
      magentoStoreCode: defaultWarehouse.magentoStoreCode,
      defaultWarehouse,
    }))
    dispatch(addStores(copyWarehouses));
  } else if (message) {
    dispatch(addAlert({ message }))
  }
}

export const fetchTokenAsync = (googleToken, email) => async (dispatch) => {
  try {
    const res = await fetchToken(googleToken, typeof email === 'string' ? email : undefined);
    dispatch(updateLoginData(res))
    return res
  } catch (e) {
    console.log(e);
  }
};

export const selectToken = (state) => state.login.token;
export const selectUser = (state) => state.login.user;
export const selectShowOptomSelectionMenu = state => state.login.showOptomSelectionMenu;
export default loginSlick.reducer;
