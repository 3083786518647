import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './StoresWithAvailability.module.scss'
import { useSelector } from 'react-redux'
import { selectToken, selectUser } from 'features/login/loginSlice'
import { useLazyFetchStoresWithAvailabilityQuery, useUpdateStoreActivationMutation } from 'app/service'
import useI18n from 'common-hooks/useI18n'
import { Link } from 'react-router-dom'
import { selectDefaults, selectStores } from 'appSlice'

const ARROW = <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6.1688 1.26413C6.16777 1.36687 6.18699 1.46879 6.22527 1.56403C6.2637 1.65962 6.32066 1.74678 6.3929 1.82032L6.39289 1.82034L6.39541 1.82284L10.8472 6.22742L1.26894 6.22742C1.06473 6.22742 0.869064 6.30882 0.72492 6.45345C0.580803 6.59805 0.500003 6.79398 0.500003 6.99811C0.500003 7.20224 0.580802 7.39817 0.724921 7.54277C0.837305 7.65554 0.981011 7.72986 1.13566 7.75714L1.14639 7.77134L1.27086 7.77134L10.8485 7.77134L6.39465 12.1856L6.39464 12.1856L6.39364 12.1866C6.32267 12.2577 6.2663 12.3422 6.22786 12.4352C6.18945 12.5282 6.16971 12.6278 6.16971 12.7284C6.16971 12.8289 6.18945 12.9286 6.22786 13.0215C6.26623 13.1144 6.32246 13.1988 6.39326 13.2698C6.46538 13.3426 6.55117 13.4005 6.6459 13.44C6.74065 13.4796 6.84234 13.5 6.94503 13.5C7.04776 13.5 7.14937 13.4796 7.24409 13.44C7.33845 13.4006 7.42413 13.343 7.49619 13.2704L13.2751 7.54286L13.2751 7.54286L13.2761 7.54186C13.3471 7.47064 13.4035 7.38606 13.4419 7.29312C13.4803 7.20017 13.5 7.10063 13.5 7.00004C13.5 6.89949 13.4803 6.79986 13.4419 6.70688C13.4034 6.61384 13.347 6.52936 13.2761 6.45822L13.2751 6.45722L7.49703 0.730403C7.49673 0.730104 7.49643 0.729805 7.49614 0.729507C7.42397 0.656903 7.33823 0.599309 7.2439 0.55994C7.14911 0.520379 7.04746 0.500001 6.94473 0.500001C6.84203 0.500001 6.7403 0.520365 6.64548 0.55994C6.55125 0.599265 6.46561 0.65677 6.39349 0.729256C6.32264 0.799234 6.2665 0.882435 6.228 0.974017C6.18944 1.06573 6.16923 1.16432 6.1688 1.26413ZM6.1688 1.26413C6.1688 1.26377 6.1688 1.26341 6.16881 1.26306L6.41879 1.26591L6.16879 1.26518C6.16879 1.26483 6.1688 1.26448 6.1688 1.26413Z" fill="#80C8C1" stroke="#80C8C1" strokeWidth="0.5"/> </svg>

function StoresWithAvailability() {
  const { i18n } = useI18n();
  const stores = useSelector(selectStores);
  const { country } = useSelector(selectDefaults)
  const { stores: userStores } = useSelector(selectUser);
  const [fetch, { data = [], isLoading: isFetching }] = useLazyFetchStoresWithAvailabilityQuery();
  const [updateActivation, { isLoading: isUpdating }] = useUpdateStoreActivationMutation();

  const handleActivation = async e => {
    await updateActivation({ storeId: e.target.name, active: e.target.checked, country })
    fetch({ storeIds: userStores, country });
  }

  useEffect(() => {
    if (userStores?.length && country) fetch({ storeIds: userStores, country });
  }, [userStores, country])

  return data.length && stores?.length ? (
    <div className={cx(styles.wrapper)}>
      <h2 className={cx(styles.title)}>{i18n('Stores with availability')}</h2>
      <ul className={cx(styles.stores)}>
        {data.map(store => (
          <li className={cx(styles.store, {[styles.active]: store.active})} key={store.storeId}>
            <Link to={`/availability/${store.storeId}/list`} className={cx(styles.name)}>{stores.find(x => x.value === store.storeId)?.label}</Link>
            <p className={cx(styles.description)}>{i18n('30 Min, One-on-One')}</p>
            <div className={cx(styles.actions)}>
              <Link className={cx(styles.cta)} to={`/bookings/${store.storeId}`}>
                {i18n('View bookings')}
                {ARROW}
              </Link>
              <label className={cx(styles.switch)}>
                <input className={cx(styles.checkbox)} disabled={isUpdating || isFetching} type="checkbox" name={store.storeId} checked={store.active} onChange={handleActivation} />
                <div className={cx(styles['switch-field'])} />
              </label>
            </div>
          </li>
        ))}
      </ul>
    </div>
  ) : null;
}

StoresWithAvailability.propTypes = {}

export default StoresWithAvailability
