import React, { useEffect } from 'react'
import { FormSectionTitle, Layout } from 'components'
import { useNavigate } from 'react-router'
import useI18n from 'common-hooks/useI18n';
import { FormProvider, useForm } from 'react-hook-form';
import CustomerFields from '../CustomerFields';
import { useCreateCustomerMutation } from 'app/service';

function Add() {
  const methods = useForm({ mode: 'all' });
  const navigate = useNavigate();
  const { i18n } = useI18n();
  const [createCustomer, { data, isLoading }] = useCreateCustomerMutation();

  useEffect(() => {
    const id = data?.saveCustomerPrescriptions?.id;
    // if (id) navigate(`/customers/${id}/prescriptions/add`)
    if (id) navigate(`/customers/${id}/historyInfo`, {
      state: {
        customerData: data?.saveCustomerPrescriptions
      }
    })
  }, [navigate, data])

  return (
    <Layout narrow  title={i18n('Customer Information')}>
      <FormSectionTitle>{i18n('Customer Information')}</FormSectionTitle>
      <FormProvider {...methods}><CustomerFields onSubmit={methods.handleSubmit(createCustomer)} submitting={isLoading} /></FormProvider>
    </Layout>
  )
}

Add.propTypes = {}

export default Add
