import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './PredictiveSearch.module.scss'
import { useDebouncedCallback } from 'use-debounce'
import noResulctIcon from './no-results-icon.png'
import useI18n from 'common-hooks/useI18n'
import Button from 'components/button/Button'

const ICON_SEARCH = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M1.66602 8.74984C1.66602 4.83782 4.83733 1.6665 8.74935 1.6665C12.6614 1.6665 15.8327 4.83782 15.8327 8.74984C15.8327 12.6619 12.6614 15.8332 8.74935 15.8332C4.83733 15.8332 1.66602 12.6619 1.66602 8.74984ZM8.74935 3.33317C5.75781 3.33317 3.33268 5.75829 3.33268 8.74984C3.33268 11.7414 5.75781 14.1665 8.74935 14.1665C11.7409 14.1665 14.166 11.7414 14.166 8.74984C14.166 5.75829 11.7409 3.33317 8.74935 3.33317Z" fill="#fff"/> <path fillRule="evenodd" clipRule="evenodd" d="M12.5781 12.5776C12.9035 12.2521 13.4311 12.2521 13.7566 12.5776L18.0899 16.9109C18.4153 17.2363 18.4153 17.764 18.0899 18.0894C17.7645 18.4149 17.2368 18.4149 16.9114 18.0894L12.5781 13.7561C12.2526 13.4306 12.2526 12.903 12.5781 12.5776Z" fill="#fff"/> </svg>
const ICON_CANCEL = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M10 0.25C4.62391 0.25 0.25 4.62391 0.25 10C0.25 15.3761 4.62391 19.75 10 19.75C15.3761 19.75 19.75 15.3761 19.75 10C19.75 4.62391 15.3761 0.25 10 0.25ZM14.0608 13L13 14.0608L10 11.0608L7 14.0608L5.93922 13L8.93922 10L5.93922 7L7 5.93922L10 8.93922L13 5.93922L14.0608 7L11.0608 10L14.0608 13Z" fill="#A0A4B7"/> </svg>

function PredictiveSearch({ onFocus, onBlur, placeholder, onSearch, results, onSelect, onNoResultsCtaClick, loading, noResultsMessage, noResultsCtaLabel }) {
  const inputRef = useRef();
  const [focused, setFocused] = useState(false);
  const [inputBlurred, setInputBlurred] = useState(false);
  const handleSearch = (e) => {
    e.preventDefault();
    onSearch(inputRef.current.value);
    handleBlur(true);
  };

  const handleFocus = () => {
    setFocused(true)
  };

  const handleBlur = (val) => {
    setInputBlurred(val)
  };

  const handleCancel = () => {
    setFocused(false);
    inputRef.current.value = '';
    onSearch('');
  };

  useEffect(() => {
    if (focused) {
      onFocus();
    } else {
      onBlur();
    }
  }, [focused, onFocus, onBlur])

  useEffect(() => {
    if (results?.length === 1) {
      onSelect(results[0].value);
    }
  }, [results]);

  return (
    <div className={cx(styles.wrapper)}>
      <form className={cx(styles.field)} onSubmit={handleSearch} onReset={handleCancel}>
        <input onChange={() => handleBlur(false)} placeholder={placeholder} className={cx(styles.input)} onFocus={handleFocus} ref={inputRef} type="search" />
        {focused && <button type="reset" className={cx(styles.cancel)}>{ICON_CANCEL}</button>}
        <button type="submit" className={cx(styles.search)}>{ICON_SEARCH}</button>
      </form>
      {!!results.length && focused && <ul className={cx(styles.results)}>
        {results.map(result => (
          <li key={result.value} onClick={() => onSelect(result.value)} className={cx(styles.result)}>{result.label}</li>
        ))}
      </ul>}
      {focused && !!inputRef.current.value && !results.length && !loading && inputBlurred && (
        <p className={cx(styles.results, styles['no-results'])}>
          <img className={cx(styles['no-results-icon'])} src={noResulctIcon} alt="" />
          <strong className={cx(styles['no-results-message'])}>{noResultsMessage}</strong>
          {!!noResultsCtaLabel && <Button onClick={onNoResultsCtaClick} className={cx(styles['no-results-cta'])}>{noResultsCtaLabel}</Button>}
        </p>
      )}
    </div>
  )
}

PredictiveSearch.propTypes = {
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  onSearch: PropTypes.func,
  results: PropTypes.arrayOf(PropTypes.shape({
    fullName: PropTypes.string,
    email: PropTypes.string
  })),
  onSelect: PropTypes.func,
  onNoResultsCtaClick: PropTypes.func,
  loading: PropTypes.bool,
  noResultsMessage: PropTypes.string,
  noResultsCtaLabel: PropTypes.string
}

export default PredictiveSearch
