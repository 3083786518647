import React from 'react'
import { FilterCard, Layout, OrderStatus } from 'components'
import useI18n from 'common-hooks/useI18n'
import useOrders from './useOrders';
import cx from 'classnames';
import styles from './Orders.module.scss';
import TabularSummary from 'components/tabular-summary/TabularSummary';
import InfiniteScroll from 'react-infinite-scroll-component';
import noResultsIcon from 'components/message/error.png'
import usePendingPrescriptionOrders from './usePendingPrescriptionOrders';

function PendingPrescriptions() {
  const {i18n} = useI18n();
  const { data, handleNextPage, isUninitialized, filters, onFilterChange, onFilterReset, loading } = usePendingPrescriptionOrders();
  const hasNoResults = !isUninitialized && !loading && !data.length;

  return (
    <Layout title={i18n('Orders')} preContent={<FilterCard value={filters} onChange={onFilterChange} onReset={onFilterReset} searchPlaceholder='Search with order id'/>} narrow >
      <div className={cx(styles.wrapper)}>
        {hasNoResults ? (
          <div className={cx(styles['no-results'])}>
            <img className={cx(styles['no-results-icon'])} src={noResultsIcon} alt="" />
            <p className={cx(styles['no-results-message'])}>{i18n('No results')}</p>
            <p className={cx(styles['no-results-info'])}>{i18n('Please try refining your search')}</p>
          </div>
        ) : (
          <>
            <h2 className={cx(styles.title)}>{i18n('Latest Orders')}</h2>
            <div className={cx(styles.orders)}>
              <InfiniteScroll
                dataLength={data.length}
                next={handleNextPage}
                hasMore={true}
              >
                {data.map((order, i) => (
                  <TabularSummary
                    to={`/orders/${order.id}`}
                    toState={{ backTo: '/orders' }}
                    className={cx(styles.pendingOrder)}
                    title={order.id}
                    key={order.id+i}
                    items={[
                      { term: i18n('Order Date'), def: order.date },
                      { term: i18n('Customer'), def: order.customer },
                      { term: i18n('Fullfilment'), def: order.fulfilmentCentre?.name },
                      { term: i18n('Store'), def: order.store },
                    ]}
                  />
                ))}
              </InfiniteScroll>
            </div>
          </>
        )}
      </div>
    </Layout>
  )
}

PendingPrescriptions.propTypes = {}

export default PendingPrescriptions
