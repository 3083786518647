import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { QrReader } from 'react-qr-reader';
import cx from 'classnames'
import styles from './QrScanner.module.scss'

function QrScanner(props) {
  const [data, setData] = useState('No result');
  console.log(data)

  return (
    <QrReader
        onResult={(result, error) => {
          if (!!result) {
            setData(result?.text);
          }

          if (!!error) {
            console.info(error);
          }
        }}
        containerStyle={{ width: '100vw', height: '100vh'}}
        ViewFinder={() => <div className={cx(styles.viewfinder)}></div>}
        style={{ width: '100%' }}
      />
  )
}

QrScanner.propTypes = {}

export default QrScanner
