import React from 'react'
import PropTypes from 'prop-types'
import background from './background.jpg'
import logo from './logo.svg'
import cx from 'classnames'
import styles from './SplashScreen.module.scss'

function SplashScreen({ children, title, description, dir }) {
  return (
    <div dir={dir} className={cx(styles.wrapper)}>
      <img src={background} alt="" className={cx(styles.background)} />
      <div className={cx(styles.card)}>
        <img className={cx(styles.logo)} src={logo} alt="Eyewa" />
        <h1 className={cx(styles.title)}>{title}</h1>
        {!!description && <div className={cx(styles.description)}>{description}</div>}
        {children}
      </div>
    </div>
  )
}

SplashScreen.defaultProps = {
  dir: 'ltr'
}

SplashScreen.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.element, PropTypes.node]),
  dir: PropTypes.oneOf(['rtl', 'ltr'])
}

export default SplashScreen
