import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { IndexSelector, Input } from 'components';
import cx from 'classnames';
import styles from './Prescription.module.scss';
import { ADDITIONAL_POWER, AXIS, CYLINDER, SPHERE, LENS_TYPE_MAPPINGS, DEFAULT_VALUES, ADDITIONAL_POWER_AFY } from './prescription-constants';
import useI18n from 'common-hooks/useI18n';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FIELDS } from 'common-hooks/useFields';
import VisualAcuity from './VisualAcuity';
import { LENS_TYPES } from 'common-hooks/useValidation';

function PrescriptionFields({ label, className, readonly, disabled, addOn6, noValidate }) {
  const { i18n } = useI18n();
  const lensType = useWatch({ name: FIELDS.LENS_TYPE });
  const addOnPackage = useWatch({ name: FIELDS.ADDON_PACKAGE });
  const sku = useWatch({ name: 'sku' });

  const renderPrescriptionFields = side => (
    <div className={cx(styles.fields)}>
      {!!LENS_TYPE_MAPPINGS[lensType]?.sphere && <Controller
        name={`${side}.sphere`}
        defaultValue={DEFAULT_VALUES.sphere}
        render={({ field: { value, onChange } }) => (
          <IndexSelector disabled={readonly || disabled} className={cx(styles.field)} value={readonly ? '0.00' : value} onChange={onChange} label={i18n('Sphere (SPH)')} options={SPHERE} title={i18n('Select Sphere (SPH)')} />
        )}
      />}
      {!!LENS_TYPE_MAPPINGS[lensType]?.cylinder && <Controller
        name={`${side}.cylinder`}
        defaultValue={DEFAULT_VALUES.cylinder}
        render={({ field: { value, onChange } }) => (
          <IndexSelector disabled={readonly || disabled} className={cx(styles.field)} value={readonly ? '0.00' : value} onChange={onChange} label={i18n('Cylinder (CYL)')} options={CYLINDER} title={i18n('Select Cylinder (CYL)')} />
        )}
      />}
      {!!LENS_TYPE_MAPPINGS[lensType]?.axis && <Controller
        name={`${side}.axis`}
        defaultValue={DEFAULT_VALUES.axis}
        render={({ field: { value, onChange } }) => (
          <IndexSelector disabled={readonly || disabled} className={cx(styles.field)} value={readonly ? '0.00' : value} onChange={onChange} label={i18n('Axis (AXIS)')} options={AXIS} title={i18n('Select Axis (AXIS)')} />
        )}
      />}
      {!!LENS_TYPE_MAPPINGS[lensType]?.pupilDiameter && <Controller
        name={`${side}.pupilDiameter`}
        defaultValue={DEFAULT_VALUES.pupilDiameter}
        rules={{
          validate: value => {
            if (noValidate) return true
            if(!readonly && !disabled && !!LENS_TYPE_MAPPINGS[lensType]?.pupilDiameter && Number(value) <= 0) {
              return 'Pupil Diameter is required'
            }
            return true
        }}}
        render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
          <Input disabled={readonly || disabled} className={cx(styles.field)} placeholder={i18n('Type')} label={i18n('Pupil Distance (IPD)')} onBlur={onBlur} onChange={onChange} error={error?.message} type="number" value={readonly ? '0.00' : value} step="0.01" />
        )}
      />}
      {!!LENS_TYPE_MAPPINGS[lensType]?.additionalPower && <Controller
        name={`${side}.additionalPower`}
        defaultValue={DEFAULT_VALUES.additionalPower}
        render={({ field: { value, onChange } }) => (
          <IndexSelector disabled={readonly || disabled} className={cx(styles.field)} value={readonly ? '0.00' : value} onChange={onChange} label={i18n('Additional Power (ADD)')} options={ADDITIONAL_POWER} title={i18n('Select Axis (AXIS)')} />
        )}
      />}
      {!LENS_TYPE_MAPPINGS[lensType]?.additionalPower && lensType === LENS_TYPES.SINGLE_VISION && addOn6?.toLowerCase() === 'afy' && <Controller
        name={`${side}.additionalPower`}
        defaultValue={0.5}
        render={({ field: { value, onChange } }) => (
          <IndexSelector disabled={readonly || disabled} className={cx(styles.field)} value={readonly ? '0.00' : value} onChange={onChange} label={i18n('Additional Power (ADD)')} options={ADDITIONAL_POWER_AFY} title={i18n('Select Axis (AXIS)')} />
        )}
      />}
      {(!!LENS_TYPE_MAPPINGS[lensType]?.segmentHeight || addOn6?.toLowerCase() === 'afy' || addOn6?.toLowerCase() === 'ndy' || addOnPackage?.toLowerCase() === 'ultra') && <Controller
        name={`${side}.segmentHeight`}
        defaultValue={DEFAULT_VALUES.segmentHeight}
        rules={{
          validate: value => {
            if(!(readonly || disabled) && (addOn6?.toLowerCase() === 'ndy' || addOnPackage?.toLowerCase() === 'ultra') && Number(value) < 0) {
              return 'Segment Height is required'
            }
            return true
          }
        }}
        render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
          <Input className={cx(styles.field)} placeholder={i18n('Segment Height (SH)')} label={i18n('Segment Height (SH)')} onBlur={onBlur} onChange={onChange} type="number" value={readonly ? '0.00' : value} error={error?.message}/>
        )}
      />}
      {!sku && <VisualAcuity side={side} readonly={readonly} disabled={disabled} />}
    </div>
  )

  return (
    <div className={cx(styles.wrapper, className)}>
      <h2 className={cx(styles.label)}>{label}</h2>
      <div className={cx(styles.cols)}>
        <div className={cx(styles.col)}>
          <h3 className={cx(styles.heading)}>{i18n('Right eye (OD)')}</h3>
          {renderPrescriptionFields('right')}
        </div>
        <div className={cx(styles.col)}>
          <h3 className={cx(styles.heading)}>{i18n('Left eye (OS)')}</h3>
          {renderPrescriptionFields('left')}
        </div>
      </div>
    </div>
  )
}

PrescriptionFields.defaultProps = {

}

PrescriptionFields.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string
}

export default PrescriptionFields
