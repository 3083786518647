import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'common-hooks/useI18n';
import { Button, Input, SplashScreen } from 'components';
import { useController, useFormState } from 'react-hook-form';
import cx from 'classnames'
import styles from './Register.module.scss'
import useFields, { FIELDS } from 'common-hooks/useFields';

function FullName({ onSubmit }) {
  const { i18n, dir } = useI18n();
  const { isValid } = useFormState({ name: FIELDS.FULL_NAME });
  const { renderField } = useFields();

  const _onSubmit = e => {
    e.preventDefault();
    onSubmit();
  }

  return (
    <form onSubmit={_onSubmit}>
      <SplashScreen dir={dir} title={i18n('Customer details')} description={i18n('Please enter your full name')}>
        {renderField(FIELDS.FULL_NAME, cx(styles.field))}
        <Button className={cx(styles.submit)} type="submit" disabled={!isValid}>{i18n('Next')}</Button>
      </SplashScreen>
    </form>
  )
}

FullName.propTypes = {
  onSubmit: PropTypes.func
}

export default FullName
