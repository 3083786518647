import React, { useEffect, useMemo } from 'react'
import { InfoCard, Layout } from 'components'
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router'
import { useLazyGetCustomerByCountryQuery, useLazyGetCustomersAppointmentsQuery, useLazyGetCustomerHistoryInformationQuery, useLazyGetComprehensivePrescriptionByCustomerIdQuery } from 'app/service';
import { useSelector } from 'react-redux';
import { selectToken } from 'features/login/loginSlice';
import useI18n from 'common-hooks/useI18n';
import Add from './add/Add';
import List from './list/List';
import CustomerInfoCards from '../CustomerInfoCards';
import { selectDefaults } from 'appSlice';
import { Bounce, toast } from 'react-toastify';
import listStyles from './list/List.module.scss'
import cx from 'classnames'
import AddCee from './add-cee/AddCee';

function Prescriptions() {
  const navigate = useNavigate();
  const { i18n } = useI18n();
  const { pathname } = useLocation();
  const token = useSelector(selectToken);
  // TODO: update param name, this is actually customerId
  // it works because we send the filter as "term"
  const { email } = useParams();
  const customerIdFromParam = email
  const [getCustomer, { data = {}, isLoading, isUninitialized }] = useLazyGetCustomerByCountryQuery();
  const [getCustomerHistoryInfo, { data: customerHistoryData, isLoading: isCustomerHistoryInfoLoading, isSuccess, isFetching }] = useLazyGetCustomerHistoryInformationQuery()
  const [getPastAppointments, { data: pastAppointments, isFetching: isFetchingPastAppointments }] = useLazyGetCustomersAppointmentsQuery();
  const [getComprehensivePrescription, { data: comprehensivePrescription, isLoading: isComprehensivePrescriptionLoading }] = useLazyGetComprehensivePrescriptionByCustomerIdQuery();
  const { country } = useSelector(selectDefaults);

  useEffect(() => {
    if (token && country) {
      getCustomer({ email: customerIdFromParam, country })
      getCustomerHistoryInfo(customerIdFromParam)
      getPastAppointments({ customerId: email, storeCode: country })
      getComprehensivePrescription(email)
    };
  }, [token, pathname, country]);

  useEffect(() => {
    if (data?.id && !data.mobileNumber) {
      toast.info('Please add customer phone number first', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      navigate(`/customers/${data.id}/edit/info`)
    }
  }, [data])

  const customerHistoryInfo = useMemo(() => {
    if (!isFetching && isSuccess && customerHistoryData && customerHistoryData.useGlasses) {
      return {
        [i18n('Currently use glasses')]: customerHistoryData.useGlasses,
        [i18n('Hobbies')]: customerHistoryData.hobbies,
        [i18n('Occupation')]: customerHistoryData.occupation,
        [i18n('Medical History')]: customerHistoryData.medicalHistory?.filter(_ => _.value !== 'false' && !!_.value)?.map(_ => _.name !== 'Other' ? _.name : '(Other) ' + _.value)?.join(', ') ?? [],
        [i18n('Ocular History')]: customerHistoryData.ocularHistory?.filter(_ => _.value !== 'false' && !!_.value)?.map(_ => _.name !== 'Other' ? _.name : '(Other) ' + _.value)?.join(', ') ?? [],
        [i18n('Recent Symptoms (in days)')]: customerHistoryData.symptoms?.filter(_ => _.value !== 'false' && !!_.value)?.map(_ => (`${_.name !== 'Other' ? _.name : '(Other) ' + _.value}${_.name !== 'None' ? ': ' + _.duration + 'd' : ''}`))?.join(', ') ?? [],
      }
    } else if (!isFetching && isSuccess && !customerHistoryData.useGlasses) {
      // toast.info('Please fill customer history info', {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   transition: Bounce,
      // });
      return {
        [i18n('NA')]: i18n(''),
      }
    }

  }, [customerHistoryData, isFetching, isSuccess]);

  return (
    <Layout wide title={i18n('Customer Information')}>
      <CustomerInfoCards data={data} loading={isLoading || isUninitialized} />
      <InfoCard data={customerHistoryInfo} loading={isCustomerHistoryInfoLoading || isFetching}
        title={i18n('Customer History Info')} cta={customerHistoryData?.useGlasses ? i18n('Edit') : i18n('Add')}
        onCtaClick={() => navigate(`/customers/${data.id}/historyInfo`, {
          state: {
            customerData: data
          }
        })}
      />
      <InfoCard title={i18n('Eye Test Appointments')} cta={'Add'} onCtaClick={() => window.open(window.location.origin + `/booking/${data.id}`)}>
        <div className={listStyles.items}>
          {pastAppointments?.data?.map(appointment => (
            <div key={appointment.appointmentId} className={cx(listStyles.item)}>
              <div className={listStyles.header}>
                <a target='_blank' rel="noreferrer" className={listStyles.appointmentLink} href={`/appointments?appointmentId=${appointment.appointmentId}`} >{appointment.appointmentId}</a>
              </div>
              <div className={listStyles.content}>
                <p className={listStyles.name}>{appointment.appointmentFor}</p>
                <p className={cx(listStyles.staff)}>{appointment.status}</p>
                <p className={cx(listStyles.date, { [listStyles['past-appointment']]: appointment.pastAppointment }, { [listStyles['future-appointment']]: !appointment.pastAppointment })}>{appointment.uiDateTime}</p>
                <p className={listStyles.staff}>{appointment.store}</p>
              </div>
            </div>
          ))}
          {(!pastAppointments?.data || pastAppointments.data?.length <= 0) && (
            <div>
              <p>{i18n('No appointments found')}</p>
              <p>{i18n('-')}</p>
            </div>
          )}
        </div>
      </InfoCard>
      <Routes>
        <Route path='/add' element={<Add customer={data} />} />
        <Route path='/add-cee' element={<AddCee customer={data} />} />
        <Route path='/:id' element={<List email={data.id} items={data.prescriptions} customer={data} />} />
        <Route path='/' element={<List email={data.id} items={data.prescriptions} comprehensiveItems={isComprehensivePrescriptionLoading ? [] : comprehensivePrescription} customer={data} />} />
        <Route path='/:id/cee' element={<AddCee customer={data} />} />
      </Routes>
    </Layout>
  )
}

Prescriptions.propTypes = {}

export default Prescriptions
