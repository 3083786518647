import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'common-hooks/useI18n';
import { Button, SplashScreen } from 'components';
import cx from 'classnames'
import styles from './Register.module.scss'
import useFields, { FIELDS } from 'common-hooks/useFields';
import { useFormState } from 'react-hook-form';

function ContactDetails({ onSubmit, onBack }) {
  const { i18n, dir } = useI18n();
  const { touchedFields, errors } = useFormState({ name: [FIELDS.PHONE, FIELDS.EMAIL] });
  const { renderField } = useFields();

  const _onSubmit = e => {
    e.preventDefault();
    onSubmit();
  }

  return (
    <form onSubmit={_onSubmit}>
      <SplashScreen dir={dir} title={i18n('Customer details')} description={i18n('Please share your contact details')}>
        {renderField(FIELDS.EMAIL, cx(styles.field, styles.email))}
        {renderField(FIELDS.PHONE, cx(styles.phone, styles.field))}
        <small className={cx(styles.disclaimer)}>{i18n('We collect phone number for delivery questions.')}</small>
        <div className={cx(styles.actions)}>
          <Button onClick={onBack} theme="secondary" className={cx(styles.back)}>{i18n('Back')}</Button>
          <Button className={cx(styles.submit)} type="submit" disabled={(!touchedFields[FIELDS.EMAIL] || errors[FIELDS.EMAIL]) && (!touchedFields[FIELDS.PHONE] || errors[FIELDS.PHONE])}>{i18n('Next')}</Button>
        </div>
      </SplashScreen>
    </form>
  )
}

ContactDetails.propTypes = {
  onSubmit: PropTypes.func,
  onBack: PropTypes.func
}

export default ContactDetails
