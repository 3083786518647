import React, { useState } from 'react';
import { Button, Checkbox, FormSectionTitle, Input } from 'components';
import { useOutletContext } from 'react-router-dom';
import styles from './../Questionnaire.module.scss'
import { validate } from './validations';

const Step5 = () => {
  const [formData, handleNext] = useOutletContext();
  const [stepData, setStepData] = useState(formData.step5);
  const [validations, setValidations] = useState({});

  const handleChange = (e) => {
    setStepData(prev => ({ ...prev, [e.target.name]: e.target.value, 'None': '' }));
    setValidations(prev => ({ ...prev, [e.target.name]: '', button: '' }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let validationObj = validate(stepData, 5);
    if (validationObj) {
      setValidations(validationObj);
      return;
    }
    handleNext(stepData, 5);
  };

  const boolQuestion = (name, checkboxLabel) => {
    if (name === 'None') {
      return (
        <div className={styles.boolQuestion}>
          <Checkbox
            className={styles.input}
            name={name}
            value={name}
            checked={!!stepData[checkboxLabel]}
            label={checkboxLabel}
            error={validations[name]}
            onChange={e => {
              setStepData(prev => ({[checkboxLabel]: e }));
              setValidations(prev => ({ [checkboxLabel]: '', button: '' }))
            }} />
        </div>
      )
    }

    return (
      <div className={styles.boolQuestion}>
        <Checkbox
          className={styles.input}
          name={name}
          value={name}
          checked={!!stepData[name]}
          label={checkboxLabel}
          error={validations[name]}
          onChange={e =>
            handleChange({
              target: {
                name: name,
                value: !!e
              }
            })}
        />
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormSectionTitle>{'Ocular History: '}</FormSectionTitle>
      <div className={styles.questionContainer}>
        {boolQuestion('Lasik', 'Lasik')}
        {boolQuestion('Cataract', 'Cataract, ( IOL implants )')}
        {boolQuestion('ICR', 'Intra corneal rings (ICR)')}
        {boolQuestion('C3R', 'Corneal collagen crosslinking ( C3R )')}
        {boolQuestion('Diplopia', 'Diplopia')}
        {boolQuestion('Family History', 'Family History')}
        <Input
          className={styles.input}
          name='Other'
          onChange={handleChange}
          value={stepData['Other']}
          label='Other treatment (if any)'
          placeholder=''
        />
        {boolQuestion('None', 'None')}
      </div>
      <Button type='submit'>{'Next'}</Button>
      {validations['button'] && <div className={styles.errorMessage}>{validations['button']}</div>}
    </form>
  );
};

export default Step5;
