import React, { useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'common-hooks/useI18n';
import { Button, Input, SplashScreen } from 'components';
import { useController, useFormState, useWatch } from 'react-hook-form';
import cx from 'classnames'
import styles from './Register.module.scss'
import useFields, { FIELDS } from 'common-hooks/useFields';
import dayjs from 'dayjs';

function DOB({ onSubmit, loading }) {
  const { i18n, dir } = useI18n();
  const { current: today } = useRef(dayjs());
  const { touchedFields, errors } = useFormState({ name: FIELDS.DOB });
  const { renderField } = useFields();
  const dob = useWatch({name: FIELDS.DOB});
  const age = useMemo(() => {
    return today.diff(dob, 'year') || '-';
  }, [dob, today]);

  const _onSubmit = e => {
    e.preventDefault();
    onSubmit();
  }

  return (
    <form onSubmit={_onSubmit}>
      <SplashScreen dir={dir} title={i18n('Customer details')} description={i18n('Please share your date of birth')}>
        {renderField(FIELDS.DOB, cx(styles.field))}
        <span className={cx(styles.age)}>{`Age: ${age}`}</span>
        <Button loading={loading} className={cx(styles.submit)} type="submit" disabled={!touchedFields[FIELDS.DOB] || errors[FIELDS.DOB]}>{i18n('Submit')}</Button>
      </SplashScreen>
    </form>
  )
}

DOB.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool
}

export default DOB
