import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useSetWorkingHoursMutation } from 'app/service';
import cx from 'classnames';
import styles from './List.module.scss'
import useI18n from 'common-hooks/useI18n';
import { useParams } from 'react-router';
import { Skeleton, TimeSlotPicker } from 'components';
import { selectDefaults } from 'appSlice';
import { useSelector } from 'react-redux';

const CHECK_ICON = <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M15.2559 0.275328C15.5814 0.600765 15.5814 1.1284 15.2559 1.45384L6.08926 10.6205C5.76382 10.9459 5.23618 10.9459 4.91074 10.6205L0.744078 6.45384C0.418641 6.1284 0.418641 5.60076 0.744078 5.27533C1.06951 4.94989 1.59715 4.94989 1.92259 5.27533L5.5 8.85274L14.0774 0.275328C14.4028 -0.0501092 14.9305 -0.0501092 15.2559 0.275328Z" fill="white"/> </svg>
const TRASH_ICON = <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M13.5 3H12.5V14.5C12.5 14.7083 12.4609 14.9036 12.3828 15.0859C12.3047 15.2682 12.1979 15.4271 12.0625 15.5625C11.9271 15.6979 11.7682 15.8047 11.5859 15.8828C11.4036 15.9609 11.2083 16 11 16H3C2.79167 16 2.59635 15.9609 2.41406 15.8828C2.23177 15.8047 2.07292 15.6979 1.9375 15.5625C1.80208 15.4271 1.69531 15.2682 1.61719 15.0859C1.53906 14.9036 1.5 14.7083 1.5 14.5V3H0.5V2H4.5V1C4.5 0.859375 4.52604 0.729167 4.57812 0.609375C4.63021 0.489583 4.70182 0.384115 4.79297 0.292969C4.88411 0.201823 4.98958 0.130208 5.10938 0.078125C5.22917 0.0260417 5.35938 0 5.5 0H8.5C8.64062 0 8.77083 0.0260417 8.89062 0.078125C9.01042 0.130208 9.11589 0.201823 9.20703 0.292969C9.29818 0.384115 9.36979 0.489583 9.42188 0.609375C9.47396 0.729167 9.5 0.859375 9.5 1V2H13.5V3ZM5.5 2H8.5V1H5.5V2ZM11.5 3H2.5V14.5C2.5 14.6354 2.54948 14.7526 2.64844 14.8516C2.7474 14.9505 2.86458 15 3 15H11C11.1354 15 11.2526 14.9505 11.3516 14.8516C11.4505 14.7526 11.5 14.6354 11.5 14.5V3ZM5.5 13H4.5V5H5.5V13ZM7.5 13H6.5V5H7.5V13ZM9.5 13H8.5V5H9.5V13Z" fill="#31395F"/> </svg>
const PLUS_ICON = <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M7 1V12.3137" stroke="#31395F" strokeWidth="2" strokeLinecap="round"/> <path d="M12.6569 6.65685H1.34314" stroke="#31395F" strokeWidth="2" strokeLinecap="round"/> </svg>

function WeeklyHours({ isCustom, data, loading, weekId }) {
  const { current: defaultHour } = useRef({ fromTime: '', toTime: '' });
  const { i18n } = useI18n();
  const { store: storeId } = useParams();
  const { country } = useSelector(selectDefaults);
  const [hours, setHours] = useState(data);
  const [setWorkingHours] = useSetWorkingHoursMutation();
  const commonPayload = { storeId, weekId: isCustom ? weekId : null };

  const handleCheck = (day, idx, checked) => {
    const clone = JSON.parse(JSON.stringify(hours));
    if (!checked) {
      const hoursWithTime = clone[idx].hours.filter(x => x.fromTime && x.toTime);
      if (hoursWithTime.length) {
        setWorkingHours({
          ...commonPayload,
          country,
          hours: hoursWithTime.map(x => ({ day, ...x, active: false }))
        })
      }
    }
    clone[idx].hours = checked ? [defaultHour] : [];
    setHours(clone);
  }

  const handleDelete = (dayIdx, hourIdx, day) => {
    const clone = JSON.parse(JSON.stringify(hours));
    if (clone[dayIdx].hours[hourIdx].fromTime && clone[dayIdx].hours[hourIdx].toTime) {
      const hoursToSend = clone[dayIdx].hours.map(x => ({...x, available: true, day}));
      hoursToSend[hourIdx].available = false;
      setWorkingHours({
        ...commonPayload,
        country,
        hours: hoursToSend
      })
    }
    clone[dayIdx].hours.splice(hourIdx, 1);
    setHours(clone);
  }

  const handleAddHourSlot = (dayIdx) => {
    const clone = JSON.parse(JSON.stringify(hours));
    clone[dayIdx].hours.push(defaultHour);
    setHours(clone);
  }

  const handleChange = (time, dayIdx, hoursIdx, day) => {
    const clone = JSON.parse(JSON.stringify(hours));
    clone[dayIdx].hours[hoursIdx] = {...clone[dayIdx].hours[hoursIdx], fromTime: `${time.fromTime}:00`, toTime: `${time.toTime}:00`};
    if (clone[dayIdx].hours[hoursIdx].fromTime && clone[dayIdx].hours[hoursIdx].toTime) {
      setWorkingHours({
        ...commonPayload,
        country,
        hours: clone[dayIdx].hours.map(x => ({...x, available: true, day}))
      })
    }
    setHours(clone);
  }

  const renderHours = (day, i) => day.hours.length ? (
    <>
      <div className={cx(styles.times)}>
        {day.hours.map((hour, j) => (
          <div className={cx(styles.time)} key={j}>
            <TimeSlotPicker
              fromValue={hour.fromTime.split(':').filter((_, i) => i <= 1).join(':')}
              toValue={hour.toTime.split(':').filter((_, i) => i <= 1).join(':')}
              onChange={e => handleChange(e, i, j, day.day)}
            />
            <button disabled={day.disabled} className={cx(styles.delete)} onClick={() => handleDelete(i, j, day.day)}>{TRASH_ICON}</button>
          </div>
        ))}
      </div>
      <button disabled={isCustom ? false : day.disabled} className={cx(styles.add)} onClick={() => handleAddHourSlot(i, day.day)}>{PLUS_ICON}</button>
    </>
  ) : (
    <span className={cx(styles['day-message'])}>{i18n(isCustom ? 'Uses default' : 'Unavailable')}</span>
  )

  const renderDay = (day, i) => {
    const isChecked = !!day.hours.length;

    return (
      <div key={`${day.day}-${i}`} className={cx(styles.day, { [styles.checked]: isChecked, [styles.disabled]: day.disabled })}>
        <label className={cx(styles['checkbox-day'])}>
          <input disabled={day.disabled} type="checkbox" className={cx(styles['checkbox-input'])} checked={isChecked} onChange={e => handleCheck(day.day, i, e.target.checked)} />
          <div className={cx(styles.checkbox)}>
            {isChecked ? CHECK_ICON : null}
          </div>
          <h3 className={cx(styles['day-label'])}>{i18n(day.label)}</h3>
        </label>
        {loading ? <div className={cx(styles.loader)}><Skeleton /></div> : renderHours(day, i)}
      </div>
    )
  }

  return (
    <div className={cx(styles['weekly-hours'])}>
      {hours.map(renderDay)}
    </div>
  )
}

WeeklyHours.propTypes = {
  isCustom: PropTypes.bool
}

export default WeeklyHours
