import React, { useRef } from 'react'
import styles from './Drivers.module.scss'
import cx from 'classnames'
import useI18n from 'common-hooks/useI18n'
import useDrivers from './useDrivers'
import { Button, SimpleSelect } from 'components'
import { useDebouncedCallback } from 'use-debounce'
import PopUpDialog from 'components/pop-up/PopUp'
import Table from 'components/table/Table'
import EditUser from 'pages/users/edit/Edit'


const SEARCH_ICON = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M1.66602 8.75008C1.66602 4.83806 4.83733 1.66675 8.74935 1.66675C12.6614 1.66675 15.8327 4.83806 15.8327 8.75008C15.8327 12.6621 12.6614 15.8334 8.74935 15.8334C4.83733 15.8334 1.66602 12.6621 1.66602 8.75008ZM8.74935 3.33341C5.75781 3.33341 3.33268 5.75854 3.33268 8.75008C3.33268 11.7416 5.75781 14.1667 8.74935 14.1667C11.7409 14.1667 14.166 11.7416 14.166 8.75008C14.166 5.75854 11.7409 3.33341 8.74935 3.33341Z" fill="#7A8AB2" /> <path fillRule="evenodd" clipRule="evenodd" d="M12.5781 12.5773C12.9035 12.2519 13.4311 12.2519 13.7566 12.5773L18.0899 16.9107C18.4153 17.2361 18.4153 17.7637 18.0899 18.0892C17.7645 18.4146 17.2368 18.4146 16.9114 18.0892L12.5781 13.7558C12.2526 13.4304 12.2526 12.9028 12.5781 12.5773Z" fill="#7A8AB2" /> </svg>

export default function Drivers() {
  const { i18n } = useI18n();
  const searchRef = useRef();
  const dialogRef = useRef(null);
  const { items, columns, setFilters } = useDrivers();
  const activeFilterOptions = [
    { label: 'Active', value: 'Active' },
    { label: 'Inactve', value: 'Inactve' },
  ]

  const handleChange = (e) => {
    setFilters(prev => ({ ...prev, active: e }))
  }

  const handleSearch = useDebouncedCallback(e => {
    handleChange('term', e.target.value);
  }, 300);

  const handleDialog = () => {
    if (dialogRef.current) {
      console.log(dialogRef.current)
      dialogRef.current.showModal();
    }
  }

  return (
    <>
      <PopUpDialog ref={dialogRef}>
        <>
          <EditUser fieldExecutiveForm />
        </>
      </PopUpDialog>
      <div className={cx(styles.wrapper)}>
        <div className={styles.heading}>
          <h1>{i18n('Drivers')}</h1>
        </div>
        <div className={styles.createBar}>
          <Button onClick={handleDialog} className={cx(styles.link)}>{i18n('Add field Executive')}</Button>
        </div>
        <div className={styles.filterBar}>
          <SimpleSelect
            label={i18n('Active')}
            options={activeFilterOptions}
            onChange={handleChange}
          />
          <div className={cx(styles.search)}>
            <label className={styles.label} htmlFor='search-driver'>Search</label>
            {SEARCH_ICON}
            <input id='search-driver' ref={searchRef} onChange={handleSearch} className={cx(styles['search-field'])} type="search" placeholder={'search'} />
          </div>
        </div>
        <Table columns={columns} items={items} />
      </div>
    </>
  )
}
