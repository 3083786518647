import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames';
import styles from './OrderStatus.module.scss';

export const ORDER_STATUSES = ["Duplicate", "In_Stock", "Partially_In_Stock", "Ordered", "OOS", "On_Hold", "AWB_Generated", "Ready_to_Ship", "Shipped", "RTO", "Delivered", "CIR", "Edited", "Cancelled", "Special_Order", "Exchange", "Return", "Shipped_to_Store", "Ready_to_Collect", "Shipped_to_WH_IP", "Shipped_to_WH_HD", "Received_from_Store", "Ready_for_Supplier_Pickup", "Shipped_to_Supplier", "Received_from_Supplier", "Reverse_AWB_Created", "Reverse_Shipment_Received", "Reverse_QC_Completed", "Reverse_QC_Failed", "RTO_Refunded", "Partially_Delivered"];
export const ORDER_STATUSES_NEW = ["Delivered", "Open order", "Invoiced", "Canceled", "Returned order"];
export const TRANSFER_ORDER_STATUSES_NEW = ["Shipped", "Canceled", "Received", "Ready For Customer Pickup", "Created" , "Customer Visited Store", "Delayed For Customer Pickup" ];

function OrderStatus({ status }) {
  return status ? (
    <span className={cx(styles[status.toLowerCase()])}>{status.split('_').join(' ')}</span>
  ) : null;
}

OrderStatus.propTypes = {
  status: PropTypes.oneOf(ORDER_STATUSES)
}

export default OrderStatus
