import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './FormElements.module.scss'
import { useId } from 'react'
import Skeleton from '../skeleton/Skeleton'

function Textarea({ label, name, type, className, onChange, value, onBlur, onFocus, error, required, autoFocus, loading, step, placeholder }) {
  const id = useId();

  return (
    <div className={cx(styles.field, className, { [styles.error]: error })}>
      <label className={cx(styles.label)} htmlFor={id}>{label}</label>
      {loading ? <Skeleton type="input" /> : (
        <textarea autoFocus={autoFocus} required={required} onChange={onChange} value={value} onBlur={onBlur} onFocus={onFocus} className={cx(styles.input, styles.textarea)} id={id} name={name} type={type} placeholder={placeholder} />
      )}
      {error && <label htmlFor={id} className={cx(styles.message)}>{error}</label>}
    </div>
  )
}

Textarea.defaultProps = {
  type: 'text',
  value: ''
}

Textarea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'email', 'tel', 'date']),
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  loading: PropTypes.bool,
  step: PropTypes.string,
  placeholder: PropTypes.string
}

export default Textarea
