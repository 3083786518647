import React, { forwardRef, useEffect } from 'react';
import { useFlexLayout, useTable } from 'react-table'
import cx from 'classnames'
import styles from './Table.module.scss';


const Table = ({ columns, items }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: items }, useFlexLayout);

  return (
    <div className={cx(styles['table-wrapper'])} id="tableContainer">
      <div {...getTableProps()} className={cx(styles.table, styles.table)}>
        <div>
          {headerGroups.map(headerGroup => (
            <div {...headerGroup.getHeaderGroupProps()} className={cx(styles['table-header'])}>
              {headerGroup.headers.map(column => {
                return <div {...column.getHeaderProps([{ className: cx(styles[column.className], styles.th, styles.th) }])}>{column.render('Header')}</div>
              })}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className={cx(styles.row)}>
                {row.cells.map((cell) => {
                  return <div {...cell.getCellProps([{ className: cx(styles.cell, styles[cell.column.className]) }])}>
                    {cell.render('Cell')}
                  </div>;
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>)
}

export default Table