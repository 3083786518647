
import React, { useState } from 'react';
import { Button, Checkbox, FormSectionTitle, Input } from 'components';
import { useOutletContext } from 'react-router-dom';
import styles from './../Questionnaire.module.scss'
import { validate } from './validations';

const Step4 = () => {
  const [formData, handleNext] = useOutletContext();
  const [stepData, setStepData] = useState(formData.step4);
  const [validations, setValidations] = useState({});

  const handleChange = (e) => {
    setStepData(prev => ({ ...prev, [e.target.name]: e.target.value }));
    setValidations(prev => ({ ...prev, [e.target.name]: '' }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationObj = validate(stepData, 4);
    if (validationObj) {
      setValidations(validationObj);
      return;
    }
    handleNext(stepData, 4);
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormSectionTitle>{'Occupation Information: '}</FormSectionTitle>
      <div className={styles.questionContainer}>
        <Input
          className={styles.input}
          name='occupation'
          onChange={handleChange}
          value={stepData['occupation']}
          label='Occupation'
          placeholder=''
          error={validations['occupation']}
        />
      </div>
      <Button type='submit'>{'Next'}</Button>
    </form>
  );
};

export default Step4;
