import React, { useMemo, useState } from 'react'
import useI18n from 'common-hooks/useI18n';
import styles from './../StoreAnalytics.module.scss'
import { useDispatch, useSelector } from 'react-redux';
import { selectDefaults, selectStores } from 'appSlice';
import Table from 'components/table/Table';
import EditableCell from 'components/table/EditableCell';
import { useLazySwitchStoreQuery, useUpdateWarehouseMutation } from 'app/service';
import { toast } from 'react-toastify';
import { updateLoginData } from 'features/login/loginSlice';

function StoreInfo() {
  const { i18n } = useI18n();
  const stores = useSelector(selectStores);
  const defaults = useSelector(selectDefaults);
  const dispatch = useDispatch()
  const [update] = useUpdateWarehouseMutation();
  const [switchStore] = useLazySwitchStoreQuery();
  const [data, setData] = useState(stores);

  const handleStoreChange = async () => {
    const res = await switchStore(defaults.storeId);
    dispatch(updateLoginData(res?.data));
    const warehouses = res?.data?.warehouses;
    const copyWarehouses = warehouses?.map(x => ({ ...x, label: x.name, value: x.name }));
    setData(copyWarehouses);
  }

  const handlePhoneUpdate = async formData => {
    delete formData.value;
    delete formData.label;
    delete formData.name;

    try {
      const updateResponse = await update({ data: { warehouseContact: formData.phoneNumber, id: formData.id } });
      if (updateResponse.error?.status > 400 || updateResponse.data?.status === 'false') {
        toast.error(i18n('Failed to update phone number'));
        setData([...stores]);
      } else {
        // login again to get the updated data, i used switch store as hack
        // to get the updated data
        toast.success(i18n('Phone number updated successfully'));
        handleStoreChange()
      }
    }
    catch (error) {
      console.error(error);
      toast.error(i18n('Failed to update phone number'));
    }
  }

  const handleUpdateCell = (rowIndex, columnId, value) => {
    const row = data[rowIndex];
    handlePhoneUpdate({ ...row, [columnId]: value });
  };

  const columns = useMemo(() => [
    { Header: 'Store ID', accessor: 'storeCode' },
    { Header: 'Name', accessor: 'name' },
    { Header: 'Address', accessor: 'address' },
    {
      Header: 'Phone', accessor: 'phoneNumber', Cell: ({ value, row, column }) => (
        <EditableCell
          value={value}
          row={row}
          column={column}
          onUpdateCell={handleUpdateCell}
        />
      ),
    },
  ], [data]);

  return (
    <div>
      <h1 className={styles.heading}>{i18n('Store info')}</h1>
      <Table columns={columns} items={stores} />
    </div>
  )
}

StoreInfo.propTypes = {}

export default StoreInfo
