import { useLazyWarehousesQuery } from "app/service";
import useI18n from "common-hooks/useI18n";
import { useEffect, useMemo, useState } from "react";

const TICK_ICON = <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 1024 1024"><path fill="#80C8C1" d="M760 380.4l-61.6-61.6-263.2 263.1-109.6-109.5L264 534l171.2 171.2L760 380.4z"/></svg>
const CROSS_ICON = <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24"> <path d="M6.99486 7.00636C6.60433 7.39689 6.60433 8.03005 6.99486 8.42058L10.58 12.0057L6.99486 15.5909C6.60433 15.9814 6.60433 16.6146 6.99486 17.0051C7.38538 17.3956 8.01855 17.3956 8.40907 17.0051L11.9942 13.4199L15.5794 17.0051C15.9699 17.3956 16.6031 17.3956 16.9936 17.0051C17.3841 16.6146 17.3841 15.9814 16.9936 15.5909L13.4084 12.0057L16.9936 8.42059C17.3841 8.03007 17.3841 7.3969 16.9936 7.00638C16.603 6.61585 15.9699 6.61585 15.5794 7.00638L11.9942 10.5915L8.40907 7.00636C8.01855 6.61584 7.38538 6.61584 6.99486 7.00636Z" fill="#E96C5D"/> </svg>

function useWarehouses() {
  const { i18n } = useI18n();
  const [filters, setFilters] = useState({});
  const [fetch, { data: items = [] }] = useLazyWarehousesQuery();

  const columns = useMemo(() => [
    { Header: i18n('S code'), accessor: 'storeCode' },
    { Header: i18n('W code'), accessor: 'warehouseCode' },
    { Header: i18n('Magento code'), accessor: 'magentoStoreCode' },
    { Header: i18n('Group'), accessor: 'warehouseGroup' },
    { Header: i18n('Tier'), accessor: 'tier' },
    { Header: i18n('Name EN'), accessor: 'warehouseName' },
    { Header: i18n('Name AR'), accessor: 'warehouseNameArabic' },
    { Header: i18n('Site'), accessor: 'site' },
    { Header: i18n('Address'), accessor: 'warehouseAddress', Cell: ({ cell: { row: { original } } }) => {
      return [original.warehouseAddress, original.area, original.city, original.country, original.warehouseLatitude, original.warehouseLongitude].filter(Boolean).join(', ')
    } },
    { Header: i18n('Pickup'), accessor: 'clickAndCollectEnabled', Cell: ({ cell: { value } }) => value ? TICK_ICON : CROSS_ICON },
    { Header: i18n('Booking'), accessor: 'eyeTestBookingEnabled', Cell: ({ cell: { value } }) => value ? TICK_ICON : CROSS_ICON },
    { Header: i18n('Phone'), accessor: 'warehouseContact' },
    { Header: i18n('Siview'), accessor: 'siviewEnabled', Cell: ({ cell: { value } }) => value ? TICK_ICON : CROSS_ICON },
  ], []);
  const searchedItems = useMemo(() => {
    if (!filters.term) return items;
    return items.filter(item => {
      const { warehouseCode, warehouseName, site, city, country, area, warehouseAddress, warehouseContact } = item;
      const searchValue = filters.term.toLowerCase();
      return warehouseCode.toLowerCase().includes(searchValue)
        || warehouseName.toLowerCase().includes(searchValue)
        || site.toLowerCase().includes(searchValue)
        || city.toLowerCase().includes(searchValue)
        || country.toLowerCase().includes(searchValue)
        || area.toLowerCase().includes(searchValue)
        || warehouseAddress.toLowerCase().includes(searchValue)
        || warehouseContact?.includes(searchValue)
    });
  }, [filters, items]);

  useEffect(() => {
    fetch();
  }, [])

  return { items: searchedItems, columns, setFilters, filters }
}

export default useWarehouses;
