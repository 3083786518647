import { useLazyUsersQuery } from "app/service";
import useI18n from "common-hooks/useI18n";
import { useEffect, useMemo, useState } from "react";

function useUsers() {
  const { i18n } = useI18n();
  const [filters, setFilters] = useState({});
  const [fetch, { data: items = [] }] = useLazyUsersQuery();

  const columns = useMemo(() => [
    { Header: i18n('User Name'), accessor: 'username' },
    { Header: i18n('Email'), accessor: 'email' },
    { Header: i18n('Group Name'), accessor: 'groupName' },
    { Header: i18n('Role'), accessor: 'roles', Cell: ({ cell: { row: { original } } }) => {
      const roles = original.roles.map(role => role.roleName).join(', ');
      return roles;
    }
     },
    { Header: i18n('Assigned Warehouses'), accessor: 'warehouses', Cell: ({ cell: { row: { original } } }) => {
      let warehouseString =  original.warehouses.map(warehouse => warehouse.name).join(', ');
      if (warehouseString.length > 50) {
        warehouseString = warehouseString.substring(0, 50) + '...';
      }
      return warehouseString;
    } },
    
  ], []);

  const searchedItems = useMemo(() => {
    if (!filters.term) return items;
    return items.filter(item => {
      const { username, email } = item;
      const searchValue = filters.term.toLowerCase();
      return username.toLowerCase().includes(searchValue)
        || email.toLowerCase().includes(searchValue)
    });
  }, [filters, items]);

  useEffect(() => {
    fetch();
  }, [])

  return { items: searchedItems, columns, setFilters }
}

export default useUsers;
