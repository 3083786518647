import React, { useEffect, useMemo, useState } from 'react'
import HomeCard from './HomeCard'
import { BoxLink, Button } from 'components'
import orderIcon from './icons/order.svg'
import add from './icons/add.svg'
import useI18n from 'common-hooks/useI18n'
import cx from 'classnames'
import styles from './Home.module.scss'
import TabularSummary from 'components/tabular-summary/TabularSummary'
import { useSelector } from 'react-redux'
import { selectToken } from 'features/login/loginSlice'
import defaultAvatar from './icons/dashboard.svg'
import { selectDefaults } from 'appSlice'
import { useGetPerformanceMatrixQuery, useLazyGetPerformanceMatrixQuery, useLazyGetPerformanceRankingQuery } from 'app/service'

function TopPerformersCard() {
  const { i18n } = useI18n();
  const token = useSelector(selectToken)
  const { storeName, country } = useSelector(selectDefaults);
  const [focused, setFocused] = useState(false);
  const handleFocus = () => setFocused(true);
  const handleBlur = () => setFocused(false);
  const [isTotalView, setIsTotalView] = useState(false);
  const [getPerformanceRanking, { isLoading, isError, error, data: topPerformers = [] }] = useLazyGetPerformanceRankingQuery()

  useEffect(() => {
    if(token) {
      getPerformanceRanking({ country })
    }
  }, [token])

  return (
    <HomeCard focused={focused}
      icon={defaultAvatar} title={i18n('Top performers')}
      description={i18n('')}>
      <div>
      <div className={cx(styles['newest-orders'])}>
          {topPerformers.map((user, index) => (
            <TabularSummary
              key={index}
              title={index + 1}
              items={[
                { term: i18n('Name'), def: user.name?.substring?.(0, 15) },
                { term: i18n('Eyetest count'), def: user.total_eye_test },
                { term: i18n('AOV'), def: user.average_order_value },
                { term: i18n('Conversion'), def: user.conversion },
              ]}
            />
          ))}
        </div>
      </div>
    </HomeCard>
  )
}

TopPerformersCard.propTypes = {}

export default TopPerformersCard
