import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'common-hooks/useI18n'
import { Button, LanguageSwitcher, SplashScreen } from 'components';
import cx from 'classnames'
import styles from './Register.module.scss'
import { useSelector } from 'react-redux';
import { selectLanguage } from 'appSlice';
import { Controller } from 'react-hook-form';

function Understand({ onUnderstand }) {
  const { i18n, update: updateLanguage, dir } = useI18n();
  const language = useSelector(selectLanguage);
  const handleLanguageChange = (e, cb) => {
    updateLanguage(e);
    cb(e);
  }

  return (
    <SplashScreen dir={dir} title={i18n('Customer details')} description={i18n('Eyewa collects your personal information to enhance your shopping experience. We are extremely secure with your personal details and will never share them.')}>
      <Button onClick={onUnderstand}>{i18n('I Understand')}</Button>
      <Controller name="language" defaultValue={language} render={({ field: { value, onChange } }) => (
        <LanguageSwitcher className={cx(styles.language)} value={value} onChange={e => handleLanguageChange(e, onChange)} />
      )} />
    </SplashScreen>
  )
}

Understand.propTypes = {
  onClick: PropTypes.func
}

export default Understand
