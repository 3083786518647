import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './language-switcher.module.scss'

const DIVIDER_ICON = <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect width="48" height="48" rx="24" fill="#80C8C1"/> <path fillRule="evenodd" clipRule="evenodd" d="M29.7144 15.2903L32.4645 17.8975C32.5002 17.8975 32.5359 17.9332 32.5359 17.9332C33.0716 18.5047 33.0359 19.3976 32.4645 19.9333L29.7144 22.5405C29.2858 22.9691 28.6073 22.9334 28.2144 22.5048C27.7858 22.0762 27.8215 21.3976 28.2501 21.0047L29.3216 20.0047H16.5001C16.2858 20.0047 16.1429 20.1476 16.1429 20.3619V23.8977C16.1429 24.5048 15.6786 24.9691 15.0715 24.9691C14.4643 24.9691 14 24.5048 14 23.8977V20.3262C14 18.9333 15.1072 17.8261 16.5001 17.8261H29.3216L28.2501 16.8261C27.8215 16.4332 27.8215 15.7546 28.2144 15.326C28.6073 14.8975 29.2858 14.8975 29.7144 15.2903ZM31.8571 23.9336C31.8571 23.3264 32.3214 22.8621 32.9285 22.8621C33.5357 22.8621 34 23.3264 34 23.9336V27.5765C34 28.9694 32.8928 30.0766 31.4999 30.0766H18.6784L19.7499 31.0766C20.1785 31.4695 20.1785 32.148 19.7856 32.5766C19.3927 33.0052 18.7142 33.0052 18.2856 32.6123L15.5355 30.0051L15.4641 29.9337C14.9284 29.3623 14.9641 28.4694 15.5355 27.9337L18.2856 25.3265C18.7142 24.8979 19.357 24.9336 19.7856 25.3622C20.2142 25.7908 20.1785 26.4694 19.7499 26.8622L18.6784 27.8622H31.4999C31.7142 27.8622 31.8571 27.7194 31.8571 27.5051V23.9336Z" fill="white"/> </svg>


const LanguageSwitcher = ({ value, onChange, className }) => {
  const renderButton = (label, val) => (
    <button
      className={cx(styles.button, { [styles.selected]: value === val })}
      onClick={onChange.bind(null, val)}
    >
      {label}
    </button>
  );

  return (
    <div className={cx(styles.wrapper, className)}>
      {renderButton('English', 'en')}
      {DIVIDER_ICON}
      {renderButton('عربى', 'ar')}
    </div>
  )
}

LanguageSwitcher.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string
}

export default LanguageSwitcher
