import React, { useState } from 'react'
import cx from 'classnames'
import styles from './TimeSlotPicker.module.scss'
import RadioGroup from 'components/form-elements/RadioGroup';
import Button from 'components/button/Button';

const HOURS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
const MINS = ['00', '30'];
const AMPM = ['AM', 'PM'];

function TimeSlotPicker({ fromValue, toValue, onChange }) {
  const [isSelecting, setIsSelecting] = useState(false);
  const [fromHour, setFromHour] = useState();
  const [fromMin, setFromMin] = useState();
  const [fromAmpm, setFromAmpm] = useState();
  const [toHour, setToHour] = useState();
  const [toMin, setToMin] = useState();
  const [toAmpm, setToAmpm] = useState();
  const canSave = fromHour && fromMin && fromAmpm && toHour && toMin && toAmpm;

  const convertTo24 = (hour, min, ampm) => {
    if (ampm === 'AM') {
      if (hour === '12') {
        return `00:${min}`;
      }
      return `${hour}:${min}`;
    }
    if (hour === '12') {
      return `${hour}:${min}`;
    }
    return `${parseInt(hour) + 12}:${min}`;
  }

  const handleSave = () => {
    setIsSelecting(false);
    const fromTime = convertTo24(fromHour, fromMin, fromAmpm);
    const toTime = convertTo24(toHour, toMin, toAmpm);
    onChange({ fromTime, toTime });
  }

  const renderCol = ({ name, onHourChange, onMinChange, onAmPmChange, label }) => (
    <div className={cx(styles.col)}>
      <div className={cx(styles.label)}>{label}</div>
      <div className={cx(styles.times)}>
        <RadioGroup name={`${name}Hour`} onChange={e => onHourChange(e.target.value)} options={HOURS.map(x => ({ label: x, value: x }))} className={cx(styles.radios)} />
        <RadioGroup name={`${name}Min`} onChange={e => onMinChange(e.target.value)} options={MINS.map(x => ({ label: x, value: x }))} className={cx(styles.radios)} />
        <RadioGroup name={`${name}AmPm`} onChange={e => onAmPmChange(e.target.value)} options={AMPM.map(x => ({ label: x, value: x }))} className={cx(styles.radios)} />
      </div>
    </div>
  )

  return (
    <>
      <div className={cx(styles.wrapper)}>
        <button onClick={() => setIsSelecting(true)} type="button" className={cx(styles.button)}>{fromValue}</button>
        <div className={cx(styles.divider)}>-</div>
        <button onClick={() => setIsSelecting(true)} type="button" className={cx(styles.button)}>{toValue}</button>
      </div>
      {isSelecting && (
        <div className={cx(styles.overlay)} onClick={() => setIsSelecting(false)}>
          <div className={cx(styles.modal)} onClick={e => e.stopPropagation()}>
            <div className={cx(styles.content)}>
              <div className={cx(styles.cols)}>
                {renderCol({
                  label: 'From',
                  name: 'from',
                  onHourChange: setFromHour,
                  onMinChange: setFromMin,
                  onAmPmChange: setFromAmpm
                 })}
                {renderCol({
                  label: 'To',
                  name: 'to',
                  onHourChange: setToHour,
                  onMinChange: setToMin,
                  onAmPmChange: setToAmpm
                })}
              </div>
              <Button disabled={!canSave} onClick={handleSave} className={cx(styles.save)}>Save</Button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default TimeSlotPicker
