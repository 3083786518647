import { LENS_TYPES } from "hooks/useValidation";

const cimpilePrescriptionOptions = (start, end, interval = 0.25, decimals = 2) => {
  const items = [];

  for (let i = start; i <= end; i += interval) {
    items.push(i);
  }

  return items.map(x => ({ label: (Math.round(x * 100) / 100).toFixed(decimals), value: x }));
}

export const SPHERE = cimpilePrescriptionOptions(-20.00, 20.00);
export const CYLINDER = cimpilePrescriptionOptions(-12, 12);
export const AXIS = cimpilePrescriptionOptions(0, 180, 1, 0);
export const ADDITIONAL_POWER = cimpilePrescriptionOptions(0, 15.00);
export const LENS_TYPE_MAPPINGS = {
  [LENS_TYPES.SINGLE_VISION]: {
    sphere: { enabled: true },
    cylinder: { enabled: true },
    axis: { enabled: true },
    pupilDiameter: { enabled: true },
  },
  [LENS_TYPES.PLAN_O]: {
    sphere: { enabled: false },
    cylinder: { enabled: false },
    axis: { enabled: false },
    pupilDiameter: { enabled: false },
  },
  [LENS_TYPES.PROGRESSIVE]: {
    sphere: { enabled: true },
    cylinder: { enabled: true },
    axis: { enabled: true },
    pupilDiameter: { enabled: true },
    additionalPower: { enabled: true },
    segmentHeight: { enabled: true },
  },
  [LENS_TYPES.BI_FOCAL]: {
    sphere: { enabled: true },
    cylinder: { enabled: true },
    axis: { enabled: true },
    pupilDiameter: { enabled: true },
    additionalPower: { enabled: true },
    segmentHeight: { enabled: true },
  },
  [LENS_TYPES.PRESCRIPTION]: {
    sphere: { enabled: true },
    cylinder: { enabled: true },
    axis: { enabled: true },
  }
}
export const DEFAULT_VALUES = {
  sphere: 0,
  cylinder: 0,
  axis: 0,
  additionalPower: 0,
  pupilDiameter: 0,
  segmentHeight: 0
}
