import React, { useState } from 'react';
import styles from './SidebarNav.module.scss'

const NavItem = ({ summary, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <details open={isOpen}>
      <summary onClick={toggleOpen}>{summary}</summary>
      <div className={styles["nav-item-content"]}>{children}</div>
    </details>
  );
};

export default NavItem;
