import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './Dashboard.module.scss'
import { Skeleton } from 'components'

function SummaryCard({icon, number, label}) {
  return (
    <p className={cx(styles.summary)}>
      <img src={icon} className={cx(styles['summary-icon'])} alt="" />
      <em className={cx(styles['summary-number'])}>{isNaN(number) ? <Skeleton /> : number}</em>
      <span className={cx(styles['summary-label'])}>{label}</span>
    </p>
  )
}

SummaryCard.propTypes = {}

export default SummaryCard
