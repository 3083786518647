import React, { useEffect, useMemo, useState } from 'react'
import { Skeleton, Layout } from 'components'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router'
import useI18n from 'common-hooks/useI18n';
import { FormProvider, useForm } from 'react-hook-form';
import { useLazyGetCustomerHistoryInformationQuery, useSetCustomerHistoryInformationMutation } from 'app/service';
import { titleize } from './helpers';

function Questionnaire() {
  const navigate = useNavigate();
  const { state } = useLocation()
  const { i18n } = useI18n();
  const { customerId } = useParams();
  const [saveCustomerHistory, { isLoading, data }] = useSetCustomerHistoryInformationMutation()
  const [getOldData, { data: oldCustomerHistoryInfo, isFetching: isFetchingOldData, isSuccess: isFetchingOldDataSuccess, isUninitialized }] = useLazyGetCustomerHistoryInformationQuery()

  const customer = useMemo(() => {
    if (state?.customerData) {
      return state.customerData
    }
    return {}
  }, [state])

  const [formData, setFormData] = useState({
    customer,
    step1: {},
    step2: {},
    step3: {},
    step4: {},
    step5: {},
    step6: {},
  });
  const [readyForSubmit, setReadyForSubmit] = useState(false)
  const [oldStateRestored, setOldStateRestored] = useState(false)

  const handleFormSubmit = async () => {
    setReadyForSubmit(false)
    let symptomArray = []
    let medicalHistoryArray = []
    let ocularHistoryArray = []

    Object.keys(formData.step2).forEach(key => {
      if (!key.includes('-')) {
        if (key === 'Other' && titleize(formData.step2[key])) {
          symptomArray.push({
            name: 'Other',
            value: titleize(formData.step2[key]),
            duration: formData.step2[`${key}-duration`]
          })
          return;
        }
        symptomArray.push({
          name: key,
          value: !!formData.step2[key],
          duration: formData.step2[`${key}-duration`]
        })
      }
    })

    Object.keys(formData.step3).forEach(key => {
      if (key === 'Other' && titleize(formData.step3[key])) {
        medicalHistoryArray.push({
          name: 'Other',
          value: titleize(formData.step3[key])
        })
        return;
      }
      medicalHistoryArray.push({
        name: key,
        value: !!formData.step3[key]
      })
    })

    Object.keys(formData.step5).forEach(key => {
      if (key === 'Other' &&  titleize(formData.step5[key])) {
        ocularHistoryArray.push({
          name: 'Other',
          value: titleize(formData.step5[key])
        })
        return;
      }
      ocularHistoryArray.push({
        name: key,
        value: !!formData.step5[key]
      })
    })

    let payload = {
      'customerId': customerId,
      'useGlasses': formData.step1.useGlasses,
      'symptoms': symptomArray,
      'medicalHistory': medicalHistoryArray,
      'ocularHistory': ocularHistoryArray,
      'occupation': titleize(formData.step4.occupation),
      'hobbies': titleize(formData.step6.hobbies)
    }
    await saveCustomerHistory(payload)
  }

  const transformResponse = (res) => {
    let step2raw = {}
    let step3raw = {}
    let step5raw = {}

    res.symptoms.forEach(symptom => {
      let key = symptom.name
      let value = symptom.value === 'false' ? '' : symptom.value
      let duration = `${symptom.duration}`
      step2raw[key] = value
      step2raw[`${key}-duration`] = duration
    })

    res.medicalHistory.forEach(disease => {
      let key = disease.name
      let value = disease.value === 'false' ? '' : disease.value
      step3raw[key] = value
    })

    res.ocularHistory.forEach(ocu => {
      let key = ocu.name
      let value = ocu.value === 'false' ? '' : ocu.value
      step5raw[key] = value
    })

    let stateObj = {
      step1: {
        useGlasses: res.useGlasses
      },
      step2: {
        ...step2raw
      },
      step3: {
        ...step3raw
      },
      step4: {
        occupation: res.occupation
      },
      step5: {
        ...step5raw
      },
      step6: {
        hobbies: res.hobbies
      }
    }

    return stateObj
  }

  useEffect(() => {
    getOldData(customerId)
  }, [])

  useEffect(() => {
    if (!oldStateRestored && !isFetchingOldData && isFetchingOldDataSuccess) {
      if (oldCustomerHistoryInfo?.useGlasses) {
        let transformedRes = transformResponse(oldCustomerHistoryInfo)
        setFormData((prev) => ({
          ...prev,
          ...transformedRes
        }));
      }
      setOldStateRestored(true)
    } else {
      navigate('1', {
        replace: true
      })
    }
  }, [oldCustomerHistoryInfo, isFetchingOldData, isFetchingOldDataSuccess])

  useEffect(() => {
    if (!isLoading && data?.customerId) {
      navigate('thankyou')
    }
  }, [isLoading, data])

  useEffect(() => {
    if (readyForSubmit) {
      handleFormSubmit()
    }
  }, [readyForSubmit])

  useEffect(() => {
    if (oldStateRestored) {
      navigate('1', {
        replace: true
      })
    }
  }, [oldStateRestored])

  const handleNext = (stepData, stepNumber) => {
    setFormData((prev) => ({
      ...prev,
      [`step${stepNumber}`]: stepData
    }));

    const nextStep = stepNumber + 1;
    if (nextStep <= 6) {
      navigate(`${nextStep}`);
    } else {
      setReadyForSubmit(true)
    }
  };

  if (isUninitialized || isFetchingOldData || !oldStateRestored) {
    return (
      <Layout narrow title={i18n('Customer Information')}>
        <Skeleton type='prescriptions' />
      </Layout>
    )
  }

  return (
    <Layout narrow title={i18n('Customer Information')}>
      <Outlet context={[formData, handleNext]} />
    </Layout>
  )
}

Questionnaire.propTypes = {}

export default Questionnaire
