import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './IndexSelector.module.scss'
import useI18n from 'common-hooks/useI18n'
import RadioGroup from 'components/form-elements/RadioGroup'
import Select from 'react-select'
import selectStyles from '../form-elements/FormElements.module.scss'

const ARROW = <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M0.528514 0.528758C0.788864 0.268409 1.21097 0.268409 1.47132 0.528758L4.99992 4.05735L8.52851 0.528758C8.78886 0.268409 9.21097 0.268409 9.47132 0.528758C9.73167 0.789108 9.73167 1.21122 9.47132 1.47157L5.47132 5.47157C5.21097 5.73192 4.78886 5.73192 4.52851 5.47157L0.528514 1.47157C0.268165 1.21122 0.268165 0.789108 0.528514 0.528758Z" fill="#31395F"/> </svg>

function IndexSelector({ label, title, options, value, onChange, className, disabled }) {
  const { current: iOS } = useRef(/^iP/.test(navigator.platform) || /^Mac/.test(navigator.platform) && navigator.maxTouchPoints > 4)
  const { i18n } = useI18n();
  const [tab, setTab] = useState('+');
  const [isSelecting, setIsSelecting] = useState(false);
  const _value = value === undefined ? i18n('Select') : (value === '0' || value === 0 ? '0.00' : value);

  const onSelect = val => {
    onChange(val);
    setIsSelecting(false);
  }

  const field = iOS ? (
    <>
      <button disabled={disabled} onClick={() => setIsSelecting(true)} type="button" className={cx(styles.trigger, styles.disabled)}>
        {value === undefined ? i18n('Select') : (value === '0' || value === 0 ? '0.00' : value)}
        {ARROW}
      </button>
      {isSelecting && (
        <div className={cx(styles.overlay)} onClick={() => setIsSelecting(false)}>
          <div className={cx(styles.modal)} onClick={e => e.stopPropagation()}>
            <h3 className={cx(styles.title)}>{title}</h3>
            <div className={cx(styles.content)}>
              {!!(options['-']?.length && options['+']?.length) && <div className={cx(styles.tabs)}>
                <button type="button" onClick={() => setTab('+')} className={cx(styles.tab, {[styles.selected]: tab === '+'})}>{i18n('Positive (+ve)')}</button>
                <button type="button" onClick={() => setTab('-')} className={cx(styles.tab, {[styles.selected]: tab === '-'})}>{i18n('Negative (-ve)')}</button>
              </div>}
              <RadioGroup defaultValue={value} onChange={onSelect} options={options[tab]} className={cx(styles.radios)} name={tab} />
            </div>
          </div>
        </div>
      )}
    </>
  ) : (
    <Select
      options={(options['-'] || []).concat(options['+'] || [])}
      placeholder={i18n('Select')}
      isDisabled={disabled}
      value={{label: _value, value: _value}}
      onChange={({ value }) => onSelect(value)}
      components={{
        DropdownIndicator: () => ARROW,
      }}
      classNames={{
        control: state => cx(selectStyles['select-control'], styles['select-control'], selectStyles.input, { [styles.focused]: state.isFocused, [styles.disabled]: disabled}),
        menu: () => cx(selectStyles['select-menu']),
        option: state => cx(selectStyles['select-option'], styles['select-option'], { [selectStyles.selected]: state.isSelected, [styles.selected]: state.isSelected }),
        indicatorsContainer: () => cx(styles['indicators-container']),
        indicatorSeparator: () => cx(styles['indicator-separator']),
        input: () => cx(styles['select-input']),
        singleValue: () => cx(styles['select-single-value']),
      }}
    />
  )

  return (
    <div className={cx(styles.wrapper, className)}>
      <div className={cx(styles.label)}>{label}</div>
      {field}
    </div>
  )
}

IndexSelector.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string
}

export default IndexSelector
