import React from 'react'
import cx from 'classnames';
import styles from './SegmentTabs.module.scss';

function SegmentTabs({ items, active, setActive }) {
  return (
    <ul className={cx(styles.nav)}>
      {items.map((item, index) => (
        <li key={index} className={cx(styles.item, {[styles.active]: active === index})}>
          <div onClick={() => setActive(index)} className={cx(styles.link)}>{item.label}</div>
        </li>
      ))}
    </ul>
  )
}

export default SegmentTabs;
