import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'common-hooks/useI18n'
import StaticLayout from './StaticLayout'
import { useGoogleLogout } from 'react-google-login'
import { useNavigate } from 'react-router'
import Message from 'components/message/Message'
import { useDispatch, useSelector } from 'react-redux'
import { clearAlerts, selectAlerts } from 'appSlice'
import { persistor } from 'app/store'

function Layout({ children, backTo, title, narrow, wide, preContent, widest }) {
  const { dir } = useI18n();
  const dispatch = useDispatch();
  const alerts = useSelector(selectAlerts)
  const navigate = useNavigate();
  const { signOut: logout } = useGoogleLogout({
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    onLogoutSuccess: () => {
      window.localStorage.removeItem('eg_token')
      navigate('/login')
      persistor?.purge?.();
    }
  })

  return (
    <div dir={dir}>
      <StaticLayout onSignout={logout} backTo={backTo} title={title} narrow={narrow} wide={wide} preContent={preContent} widest={widest}>
        {alerts.length > 0 && <Message message={alerts[0].message} onClick={() => {
          dispatch(clearAlerts())
        }} cta={'Ok'} />}
        {children}
      </StaticLayout>
    </div>
  )
}

Layout.defaultProps = {
  backTo: '/'
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  backTo: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  narrow: PropTypes.bool,
  wide: PropTypes.bool,
  widest: PropTypes.bool,
  preContent: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
}

export default Layout
