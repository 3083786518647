import { useCreateCustomerMutation } from 'app/service';
import { QrScanner } from 'components';
import React, { useEffect, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import Choose from './Choose';
import ContactDetails from './ContactDettails';
import DOB from './DOB';
import FullName from './FullName';
import Gender from './Gender';
import Success from './Success';
import Understand from './Understand';

function Register() {
  const navigate = useNavigate();
  const methods = useForm({ mode: 'onChange', reValidateMode: 'onChange' });
  const { current: routes } = useRef({
    CHOOSE: 'choose',
    FULL_NAME: 'full-name',
    CONTACT_DETAILS: 'contact-details',
    GENDER: 'gender',
    DOB: 'dob',
    QR: 'qr',
    SUCCESS: 'success'
  });
  const [subRoute, setSubroute] = useState('');
  const [createCustomer, { data, isLoading }] = useCreateCustomerMutation();

  const handleUnderstood = () => setSubroute(routes.CHOOSE);
  const onFullNameSubmit = () => setSubroute(routes.CONTACT_DETAILS)
  const onContactDetailsSubmit = () => setSubroute(routes.GENDER)
  const onBackFromContactDetails = () => setSubroute(routes.FULL_NAME)
  const onGenderSubmit = () => setSubroute(routes.DOB)
  const onBackFromGender = () => setSubroute(routes.CONTACT_DETAILS)
  const handleSuccess = () => navigate(`/customers/${data.saveCustomerPrescriptions.email}/prescriptions/add`)

  useEffect(() => {
    const email = data?.saveCustomerPrescriptions?.email;
    if (email) setSubroute(routes.SUCCESS)
  }, [data])

  const renderComponent = () => {
    switch (subRoute) {
      case routes.CHOOSE:
        return <Choose onChoose={setSubroute} />;

      case routes.FULL_NAME:
        return <FullName onSubmit={onFullNameSubmit} />;

      case routes.CONTACT_DETAILS:
        return <ContactDetails onSubmit={onContactDetailsSubmit} onBack={onBackFromContactDetails} />;

      case routes.GENDER:
        return <Gender onSubmit={onGenderSubmit} onBack={onBackFromGender} />;

      case routes.DOB:
        return <DOB onSubmit={methods.handleSubmit(createCustomer)} loading={isLoading} />;

      case routes.SUCCESS:
        return <Success onUnderstand={handleSuccess} />;

      default:
        return <Understand onUnderstand={handleUnderstood} />;
    }
  }

  return (
    <FormProvider {...methods}>{renderComponent()}</FormProvider>
  )
}

Register.propTypes = {}

export default Register
