import { selectDefaults, selectStores } from "appSlice";
import { COUNTRIES } from "components/form-elements/Countries";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import useI18n from "./useI18n";

function useStore() {
  const { i18n } = useI18n();
  const stores = useSelector(selectStores);
  const { storeName } = useSelector(selectDefaults);
  const store = useMemo(() => stores.find(x => x.storeCode === storeName), [stores, storeName]);

  return store ? { label: store.name, city: store.city, country: COUNTRIES(i18n).find(x => x.value === store?.country?.substring?.(0, 2)?.toLowerCase?.())?.label } : {};
}

export default useStore;
