import React from 'react'
import cx from 'classnames';
import styles from './SectionNav.module.scss';
import { Link } from 'react-router-dom';

function SectionNav({ items, active }) {
  return (
    <ul className={cx(styles.nav)}>
      {items.map((item, index) => (
        <li key={index} className={cx(styles.item, {[styles.active]: active === index})}>
          <Link to={item.to} className={cx(styles.link)}>{item.label}</Link>
        </li>
      ))}
    </ul>
  )
}

export default SectionNav;
