import { useEffect, useMemo, useState } from 'react'
import { selectToken, selectUser } from 'features/login/loginSlice';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useGetTransferOrdersViaRestMutation } from 'app/service';
import useI18n from 'common-hooks/useI18n';
import { useLocation } from 'react-router';
import styles from './Orders.module.scss'

const DEFAULT_FILTERS = { term: '', page: 1 };

function useTransferOrdersStaff() {
  const { pathname } = useLocation();
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const { warehouses } = useSelector(selectUser);
  const [getOrders, { data: currentData, isLoading, isUninitialized, isError, error }] = useGetTransferOrdersViaRestMutation();
  const [data, setData] = useState([]);
  const token = useSelector(selectToken);
  const [hasMore, setHasMore] = useState(true);
  const { i18n } = useI18n();
  const searchOnly=true;
  const renderBool = cell => {
    return cell.value ? i18n('Yes') : i18n('No')
  };

  const renderLink = cell => {
    const base = window.location.href.split(pathname)[0];
    return <a className={styles.link} href={`${base}/orders/${cell.row.original.magentoIncrementId}`}>{cell.value}</a>
  }
  const columns = useMemo(() => [
    { Header: i18n('Store name'), accessor: 'storeName' },
    { Header: i18n('ERP order id'), accessor: 'erpOrderId' },
    { Header: i18n('order id'), accessor: 'magentoIncrementId' },
    { Header: i18n('Customer Name '), accessor: 'customerDTO.name' },
    { Header: i18n('Customer Mobile '), accessor: 'customerDTO.mobileNo' },
    { Header: i18n('Order date'), accessor: 'orderDate' },
    {Header: i18n('Store Delivery Date'), accessor: 'deliveryDate' },
    { Header: i18n('Order status'), accessor: 'orderStatus' },
    { Header: i18n('Customer Visited Store'), accessor: 'customerVisited' ,Cell: renderBool}
    
  ], []);

  const buildQuery = () => {
    return {
      qrData: filters.term,
      
    };
  }

  const filter = () => {
    const query = buildQuery();
    getOrders(query);
  }

  const onFilterReset = () => setFilters(DEFAULT_FILTERS);

  const handleFilterChange = filters => {
    setFilters({...filters, page: 1});
  }

  useEffect(() => {
    setData(filters.page === 1 ? (currentData || []) : [...data, ...(currentData || [])]);
    setHasMore(currentData?.length);
  }, [currentData])
 


  useEffect(() => {
    if (token && filters.term) {
      console.log(filters.term);
      filter();
    }
  }, [ filters.term])

  return {
    data,
    columns,
    filters,
    onFilterChange: handleFilterChange,
    loading: isLoading,
    isUninitialized,
    onFilterReset,
    hasMore,
    hasError: isError,
    error
  }
}

export default useTransferOrdersStaff
