import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './Add.module.scss'
import useFields, { FIELDS, LENS_TYPES } from 'common-hooks/useFields'
import { Button, Prescription, UploadPrescription } from 'components'
import useI18n from 'common-hooks/useI18n'
import { useFormContext } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router'

const DEFAULT_VALUES = {
  sphere: 0,
  cylinder: 0,
  axis: 0,
  additionalPower: 0,
  pupilDiameter: 0,
  segmentHeight: 0
}

function AddFields({ onSubmit, loading }) {
  const { i18n } = useI18n();
  const navigate = useNavigate();
  const location = useLocation();
  const { formState: { isValid }, setValue, trigger } = useFormContext();
  const { renderField, watchedFields } = useFields();
  const lensType = watchedFields?.[FIELDS.LENS_TYPE];

  useEffect(() => {
    if (lensType === 'Plano') {
      Object.keys(DEFAULT_VALUES).forEach(key => setValue(`left.${key}`, DEFAULT_VALUES[key]));
      Object.keys(DEFAULT_VALUES).forEach(key => setValue(`right.${key}`, DEFAULT_VALUES[key]));
    }
  }, [lensType]);

  useEffect(() => {
    if(!isValid) {
      trigger()
    }
  }, [isValid])

  return (
    <form onSubmit={onSubmit} className={cx(styles.form)}>
      <div className={cx(styles['name-upload'])}>
        {renderField(FIELDS.LENS_NAME, cx(styles.field))}
        <UploadPrescription />
      </div>
      <h4 className={cx(styles['external-source-title'])}>{i18n('Source')}</h4>
      <div className={cx(styles['external-source'])}>
        {renderField(FIELDS.EXTERNAL, cx(styles.field, styles['external-field']))}
        {renderField(FIELDS.EXTERNAL_SOURCE, cx(styles.field, styles['external-source-field']))}
      </div>
      {[FIELDS.RELATIONSHIP_TYPE, FIELDS.LENS_TYPE, FIELDS.STAFF_NAME, FIELDS.STORE_NAME].map(field => (
        <Fragment key={field}>{renderField(field, cx(styles.field))}</Fragment>
      ))}
      {lensType && lensType !== 'Plano' ? <Prescription readonly={lensType === 'Plano'} className={cx(styles.field)} label={i18n('Prescription')} value={DEFAULT_VALUES} /> : null}
      {lensType && lensType === LENS_TYPES.PROGRESSIVE &&
        <div className={cx(styles['external-field'])}>
          {[FIELDS.CORRIDOR].map(field => (
            <Fragment key={field}>{renderField(field, cx(styles.field1))}</Fragment>
          ))}
        </div>
      }
      {renderField(FIELDS.COMMENT, cx(styles.field))}
      <div className={styles.buttonWrapper}>
        <Button loading={loading} type="submit" className={cx(styles.submit)} disabled={!isValid}>{i18n('Save')}</Button>
        <Button loading={loading} type="button" className={cx(styles.submit)} theme='secondary' onClick={_ => navigate(location.pathname.replace('/add', ''))}>{i18n('Cancel')}</Button>
      </div>
    </form>
  )
}

AddFields.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool
}

export default AddFields
