
import React, { useState } from 'react';
import { Button, Checkbox, FormSectionTitle, Input } from 'components';
import { useOutletContext } from 'react-router-dom';
import styles from './../Questionnaire.module.scss'
import { validate } from './validations';

const Step6 = () => {
  const [formData, handleNext] = useOutletContext();
  const [stepData, setStepData] = useState(formData.step6);
  const [validations, setValidations] = useState({});
  const [loading, setLoading] = useState(false)

  const handleChange = (e) => {
    setStepData(prev => ({ ...prev, [e.target.name]: e.target.value }) );
    setValidations(prev => ({ ...prev, [e.target.name]: '' }) );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let validationObj = validate(stepData, 6);
    if (validationObj) {
      setValidations(validationObj);
      return;
    }
    handleNext(stepData, 6);
    setLoading(true)
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormSectionTitle>{'Hobby Information: '}</FormSectionTitle>
      <div className={styles.questionContainer}>
        <Input
          className={styles.input}
          name='hobbies'
          onChange={handleChange}
          value={stepData['hobbies']}
          label='Hobbies'
          placeholder=''
          error={validations['hobbies']}
        />
      </div>
      <Button type='submit' disabled={loading} loading={loading}>{'Submit'}</Button>
    </form>
  );
};

export default Step6;
