import React from 'react'
import { useLocation } from 'react-router'
import { useTable } from 'react-table'
import cx from 'classnames'
import styles from './Dashboard.module.scss'

const CustomerTable = ({ columns, data, noRedirect }) => {
  const { pathname } = useLocation();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  const handleRowClick = row => {
    if (noRedirect) return;
    const base = window.location.href.split(pathname)[0];
    window.open(`${base}/customers/${row.values.email}/prescriptions${row.original.hasPrescriptions ? '' : '/add'}`, '_blank');
  }

  return (
    <div className={cx(styles['table-wrapper'])}>
      <table {...getTableProps()} className={cx(styles.table)}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()} className={cx(styles['table-header'])}>
              {headerGroup.headers.map(column => {
                return <th {...column.getHeaderProps([{ className: cx(styles[column.className], styles.th) }])}>{column.render('Header')}</th>
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className={cx(styles.row)} onClick={handleRowClick.bind(null, row)}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps([ { className: cx(styles.cell, styles[cell.column.className]) }])}>
                    {cell.render('Cell')}
                  </td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  )
}

CustomerTable.propTypes = {}

export default CustomerTable
