import React, { useContext } from 'react';
import cx from 'classnames';
import styles from './Orders.module.scss';
import useI18n from 'common-hooks/useI18n';
import { Controller, useForm } from 'react-hook-form';
import { Button, Input } from 'components';
import { useFetchInvoiceMutation } from 'app/service';
import { OrderDetailsContext } from './order-details/OrderDetails';

function InvoiceForm() {
  const { i18n } = useI18n();
  const methods = useForm({ mode: 'all' });
  const { formState: { isValid } } = methods;
  const { erpOrderId } = useContext(OrderDetailsContext);
  const [fetch, { isLoading }] = useFetchInvoiceMutation();

  const handleSubmit = async (data, e) => {
    e.preventDefault();

    const res = await fetch(data);
    window.open(res.data.pdf_url, '_blank');
  }

  return (
    <form onSubmit={methods.handleSubmit(handleSubmit)} className={cx(styles['invoice-form'])}>
      <Controller
        name="invoice_number"
        control={methods.control}
        rules={{ required: i18n('Invoice number is required'), }}
        render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
          <Input label={i18n('Invoice number')} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} />
        )}
      />
      <Controller
        name="customer_name"
        control={methods.control}
        rules={{ }}
        render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
          <Input label={i18n('Customer name')} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} />
        )}
      />
      <Controller control={methods.control} name="id" defaultValue={erpOrderId} render={() => <input type="hidden" value={erpOrderId} />} />
      <Button loading={isLoading} type="submit">{i18n('Generate')}</Button>
    </form>
  )
}

export default InvoiceForm;