import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Loader from 'react-loading-skeleton'
import cx from 'classnames'
import styles from './Skeleton.module.scss'

import 'react-loading-skeleton/dist/skeleton.css';

function Skeleton({ type }) {
  const compiledProps = useMemo(() => {
    const props = {};

    if (type === 'input') {
      props.borderRadius = 15
      props.height = 48
      props.style = {fontSize: 30}
    } else if (type === 'button') {
      props.borderRadius = 12
      props.height = 58
      props.width = 390
    } else if (type === 'prescriptions') {
      props.borderRadius = 12
      props.height = 145
      props.width = '33.33%'
      props.count = 3
      props.inline = true
      props.containerClassName = cx(styles.prescriptions)
    } else if (type === 'get-prescriptions') {
      props.borderRadius = 12
      props.height = 335
      props.width = 'calc(33.33% - 13.33px)'
      props.count = 6
      props.inline = true
      props.containerClassName = cx(styles.prescriptions, styles['get-prescriptions'])
    } else if (type === 'slots') {
      props.borderRadius = 12
      props.height = 64
      props.width = 'calc(33.33% - 6.66px)'
      props.count = 11
      props.inline = true
      props.containerClassName = cx(styles.slots)
    } else if (type === 'events') {
      props.borderRadius = 12
      props.height = 112
      props.width = '100%'
      props.count = 3
      props.inline = false
      props.containerClassName = cx(styles.events)
    }

    return props;
  }, [type])

  return (
    <Loader {...compiledProps} />
  )
}

Skeleton.defaultProps = {
  type: 'text'
}

Skeleton.propTypes = {
  type: PropTypes.oneOf(['text', 'input', 'button', 'prescriptions', 'get-prescriptions', 'slots', 'events'])
}

export default Skeleton
