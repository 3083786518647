import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './Message.module.scss'
import errorIcon from './error.png'
import Button from 'components/button/Button'

function Message({ error, message, cta, onClick, icon, info, onCancel, cancel }) {
  return (
    <div className={cx(styles.overlay)}>
      <div className={cx(styles.modal)}>
        {error && <img className={cx(styles.icon)} src={errorIcon} alt="" />}
        {icon && <img className={cx(styles.icon)} src={icon} alt="" />}
        <p className={cx(styles.message)}>{message}</p>
        <p className={cx(styles.info)}>{info}</p>
        <div className={cx(styles.actions)}>
          {!!cta && <Button className={cx(styles.cta)} onClick={onClick}>{cta}</Button>}
          {!!cancel && <Button theme="secondary" className={cx(styles.cancel)} onClick={onCancel}>{cancel}</Button>}
        </div>
      </div>
    </div>
  )
}

Message.propTypes = {
  error: PropTypes.bool,
  message: PropTypes.string,
  cta: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  info: PropTypes.string
}

export default Message
