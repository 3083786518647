import React from 'react'
import PropTypes from 'prop-types'
import SimpleSelect from './SimpleSelect';

export const LANGUAGES = i18n => [
  { label: i18n('English'), value: 'en' },
  { label: i18n('Arabic'), value: 'ar' }
];

function Countries({ i18n, ...rest }) {
  return <SimpleSelect {...rest} label={i18n('Language')} options={LANGUAGES(i18n)} placeholder="" />
}

Countries.defaultProps = {
  i18n: val => val
}

Countries.propTypes = {
  onChange: PropTypes.func,
  error: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  i18n: PropTypes.func,
  loading: PropTypes.bool
}

export default Countries
