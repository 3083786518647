import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './TransferOrderFilterCard.module.scss'
import useI18n from 'common-hooks/useI18n'
import { useDebouncedCallback } from 'use-debounce'
import { useSelector } from 'react-redux'
import { selectStores } from 'appSlice'
import { SimpleSelect, Input, Button } from 'components'
import { TRANSFER_ORDER_STATUSES_NEW } from 'components/order-status/OrderStatus'
import { selectUser } from 'features/login/loginSlice'
import { useSearchParams } from 'react-router-dom'

const SEARCH_ICON = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M1.66602 8.75008C1.66602 4.83806 4.83733 1.66675 8.74935 1.66675C12.6614 1.66675 15.8327 4.83806 15.8327 8.75008C15.8327 12.6621 12.6614 15.8334 8.74935 15.8334C4.83733 15.8334 1.66602 12.6621 1.66602 8.75008ZM8.74935 3.33341C5.75781 3.33341 3.33268 5.75854 3.33268 8.75008C3.33268 11.7416 5.75781 14.1667 8.74935 14.1667C11.7409 14.1667 14.166 11.7416 14.166 8.75008C14.166 5.75854 11.7409 3.33341 8.74935 3.33341Z" fill="#7A8AB2" /> <path fillRule="evenodd" clipRule="evenodd" d="M12.5781 12.5773C12.9035 12.2519 13.4311 12.2519 13.7566 12.5773L18.0899 16.9107C18.4153 17.2361 18.4153 17.7637 18.0899 18.0892C17.7645 18.4146 17.2368 18.4146 16.9114 18.0892L12.5781 13.7558C12.2526 13.4304 12.2526 12.9028 12.5781 12.5773Z" fill="#7A8AB2" /> </svg>
function TransferOrderFilterCardStaff({
  value = {},
  onChange,
  onReset,
  heading,
  useSearchOnly,
  searchPlaceholder: _searchPlaceholder = 'Search using  QR Code RR',atuses,
  showSearchByCustomerId,
}) {
  let [searchParams, setSearchParams] = useSearchParams()
  const { i18n } = useI18n();
  const searchRef = useRef();
  const customerIdRef = useRef();
  const [useFilterSummary, setUseFilterSummary] = useState(false);
  const allStores = useSelector(selectStores);
  const stores = useMemo(() => [{ label: i18n('All Stores'), value: '' },  ...allStores.map(x => ({ label: x.name, value: x.storeCode }))], [allStores]);
  const searchPlaceholder = useFilterSummary ? 'Search' : _searchPlaceholder;
  const orderStatuses = TRANSFER_ORDER_STATUSES_NEW
  const { current: statuses } = useRef([{ label: i18n('All'), value: '' },...orderStatuses.map(x => ({ value: x, label: x.split('_').join(' ') }))])
  const showStatus = useMemo(() => value.status !== undefined, [value.status]);
  const handleSearch = useDebouncedCallback(e => {
    handleChange('term', e.target.value);
  }, 300);

  const handleCustomerIdChange = e => {
    // if (e.target.value.length === 7) {
      let param = {
        customerId: e.target.value
      }
      setSearchParams(param)
    // }
    if (e.target.value.length === 0) {
      setSearchParams()
    }
  };

  const handleChange = (name, newValue) => {
    onChange({ ...value, [name]: newValue })
  }

  const handleReset = () => {
    searchRef.current.value = '';
    setSearchParams()
    onReset();
  }

  const handleScroll = useCallback(() => {
    setUseFilterSummary(window.scrollY > 313);
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, []);

  const renderPill = (label, value) => value ? (
    <li className={cx(styles.pill)}>{label}: <strong className={cx(styles['pill-value'])}>{value}</strong></li>
  ) : null;

  

  return (
    <div className={cx(styles.wrapper, { [cx(styles.summary)]: useFilterSummary })}>
      <div className={cx(styles.container)}>
        {!heading ? <h1 className={cx(styles.title)}>{i18n(' Search')}</h1> : heading === '' ? null : <h1 className={cx(styles.title)}>{heading}</h1>}
        <div className={styles.searchContainer}>
          <div className={cx(styles.search, styles.flex1)}>
            {SEARCH_ICON}
            <input ref={searchRef} onChange={handleSearch} className={cx(styles['search-field'])} type="search" placeholder={i18n(searchPlaceholder)} />
          </div>
          {showSearchByCustomerId &&
            <div className={cx(styles.search)}>
              {SEARCH_ICON}
              <input ref={customerIdRef} onChange={handleCustomerIdChange} className={cx(styles['search-field'])} type="search" placeholder={'Search by QR/Bar Code  mobile no'} />
            </div>
          }
        </div>
        
      </div>
    </div>
  )
}

TransferOrderFilterCardStaff.propTypes = {
  value: PropTypes.shape({
    term: PropTypes.string,
    storeName: PropTypes.string,
    createdAtFrom: PropTypes.string,
    createdAtTo: PropTypes.string
  }),
  onChange: PropTypes.func,
  useSearchOnly: PropTypes.bool,
  heading: PropTypes.string,
  showAssemblyFilter: PropTypes.bool,
  newOrderStatuses: PropTypes.bool,
  showSearchByCustomerId: PropTypes.bool,
}

export default TransferOrderFilterCardStaff
