import React from 'react'
import PropTypes from 'prop-types'
import SimpleSelect from './SimpleSelect';

export const GENDERS = i18n => [
  { label: i18n('Male'), value: 'male' },
  { label: i18n('Female'), value: 'female' }
];

function Genders({ i18n, ...rest }) {
  return <SimpleSelect {...rest} label={i18n('Gender')} options={GENDERS(i18n)} placeholder="" />
}

Genders.defaultProps = {
  i18n: val => val
}

Genders.propTypes = {
  i18n: PropTypes.func,
}

export default Genders
