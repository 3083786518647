import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './SkuForm.module.scss'
import cx from 'classnames'
import useI18n from 'common-hooks/useI18n';
import useFields, { FIELDS } from 'common-hooks/useFields';
import { Button, InfoCard, Prescription, SimpleSelect, UploadPrescription } from 'components';
import { DEFAULT_VALUES } from 'components/prescription/prescription-constants';
import GetPrescription from '../get-prescription/GetPrescription';
import { Controller, useWatch } from 'react-hook-form';

function SkuFormFields({ onSubmit, loading, email, parentProducts, hasParent, defaultParent, orderId, phone, customerId }) {
  const { i18n } = useI18n();
  const { renderField } = useFields({ orderId });
  const parentProductsOptions = useMemo(() => parentProducts.length ? [
    { label: i18n('Select parent sku') },
    ...parentProducts.map(x => ({ label: `${x.name} - ${x.id}`, value: x.id }))
  ] : [], [parentProducts]);
  const [readonly, setReadonly] = useState(false);
  const [noValidate, setNoValidate] = useState(false);
  const [addOn6] = useWatch({ name: ['addOn6'] });

  const _onSubmit = e => {
    e.preventDefault();
    onSubmit(e);
  }

  return (
    <form onSubmit={_onSubmit} className={cx(styles.form)}>
      <InfoCard title={i18n('Prescription Detail')}>
        <div className={cx(styles['get-upload'])}>
          <GetPrescription email={email} phone={phone} customerId={customerId} onSelect={() => { setReadonly(false); setNoValidate(true) }} onModalClose={() => setReadonly(false)} onModalOpen={() => setNoValidate(false)} />
          {i18n('Or')}
          <UploadPrescription />
        </div>
        <div className={cx(styles.fields, styles['external-source'])}>
          {renderField(FIELDS.EXTERNAL, cx(styles.field, styles['external-field']))}
          {renderField(FIELDS.EXTERNAL_SOURCE, cx(styles.field, styles['external-source-field']))}
        </div>
        {hasParent && !!parentProducts.length && <Controller
          name="parentItemId"
          defaultValue={defaultParent}
          render={({ field: { value, onChange, name } }) => (
            <SimpleSelect className={cx(styles['parent-select'])} options={parentProductsOptions} name={name} label={i18n('Parent Sku')} value={value} onChange={onChange} />
          )}
        />}
        {renderField(FIELDS.LENS_TYPE)}
        <Prescription label={i18n('Prescription')} value={DEFAULT_VALUES} disabled={readonly} addOn6={addOn6} noValidate={noValidate} />
      </InfoCard>
      <InfoCard title={i18n('Glass Properties')}>
        <div className={cx(styles.fields)}>
          {[FIELDS.INDEX, FIELDS.CORRIDOR, FIELDS.LENS_DIAMETER, FIELDS.LENS_SHAPE, FIELDS.BASE_CURVE, FIELDS.ADDON_PACKAGE].map(field => (
            renderField(field, cx(styles.field))
          ))}
        </div>
      </InfoCard>
      <InfoCard title={i18n('Addon')}>
        <div className={cx(styles.fields)}>
          {[FIELDS.ADDON_1, FIELDS.ADDON_2, FIELDS.ADDON_3, FIELDS.ADDON_5, FIELDS.ADDON_6, FIELDS.ADDON_7].map(field => (
            renderField(field, cx(styles.field))
          ))}
        </div>
        <div className={cx(styles.fields)}>
          {renderField(FIELDS.ADDON_COLOR, cx(styles.field))}
        </div>
      </InfoCard>
      {renderField(FIELDS.COMMENT)}
    </form>
  )
}

SkuFormFields.propTypes = {
  onSubmit: PropTypes.func
}

export default SkuFormFields
