import React, { useEffect, useMemo, useState } from 'react'
import HomeCard from './HomeCard'
import { BoxLink, Button } from 'components'
import orderIcon from './icons/order.svg'
import add from './icons/add.svg'
import useI18n from 'common-hooks/useI18n'
import cx from 'classnames'
import styles from './Home.module.scss'
import TabularSummary from 'components/tabular-summary/TabularSummary'
import { useSelector } from 'react-redux'
import { selectToken } from 'features/login/loginSlice'
import defaultAvatar from './icons/dashboard.svg'
import { selectDefaults } from 'appSlice'
import { useGetPerformanceMatrixQuery, useLazyGetPerformanceMetricsQuery } from 'app/service'
import dayjs from 'dayjs'

function PerformanceCard() {
  const { i18n } = useI18n();
  const token = useSelector(selectToken)
  const { storeName, country } = useSelector(selectDefaults);
  const [focused, setFocused] = useState(false);
  const handleFocus = () => setFocused(true);
  const handleBlur = () => setFocused(false);
  const [isTotalView, setIsTotalView] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [getPerformanceMatrix, {isLoading, isError, error, data: performanceMatrixData = {store: []}}] = useLazyGetPerformanceMetricsQuery()

  useEffect(() => {
    if(token) {
      const today = dayjs()
      const oneWeekAgo = today.subtract(7, 'day')
      getPerformanceMatrix({country, endDate: today.format('DD-MM-YYYY'), startDate: oneWeekAgo.format('DD-MM-YYYY')})
    }
  }, [token])

  const handleOnScroll = (e) => {
    e.preventDefault()
    setHasScrolled(true)
  }

  let data = useMemo(() => {
    let dataToShow = isTotalView ? performanceMatrixData?.currentPeriodData : performanceMatrixData?.currentPeriodData?.store?.[0] ?? {}
    return [dataToShow]
  }, [isTotalView, storeName, performanceMatrixData])

  let tableItems = (store) => {
    return isTotalView ? [
      { term: i18n('Accepted'), def: store.acceptance },
      { term: i18n('Canceled'), def: store.cancellation },
      // { term: i18n('Missed'), def: store.missed },
      // { term: i18n('Peak hour'), def: store.peak_hour_performance },
      { term: i18n('No. of Eyetest'), def: store.total_eye_test },
      // { term: i18n('No. of Lenses'), def: store.lenses_sold },
      // { term: i18n('No. of PG Lens'), def: store.pg_lenses_sold },
      // { term: i18n('AOV'), def: store.average_order_value, wide: true},
      // { term: i18n('No. of Orders'), def: store.no_of_orders },
      // { term: i18n('Total sales'), def: store.total_sales, wide: true },
      // { term: i18n('Overall eye tests'), def: isTotalView ? store.overall_eye_test : 'NA'},
      // { term: i18n('Conversion'), def: store.conversion + '%', wide: true },
    ] : 
    [
      { term: i18n('Accepted'), def: store.acceptance },
      { term: i18n('Canceled'), def: store.cancellation },
      // { term: i18n('Missed'), def: store.missed },
      // { term: i18n('Peak hour'), def: store.peak_hour_performance },
      { term: i18n('No. of Eyetest'), def: store.total_eye_test },
      // { term: i18n('No. of Lenses'), def: store.lenses_sold },
      // { term: i18n('No. of PG Lens'), def: store.pg_lenses_sold },
      // { term: i18n('AOV'), def: store.average_order_value, wide: true},
      // { term: i18n('No. of Orders'), def: store.no_of_orders },
      // { term: i18n('Total sales'), def: store.total_sales, wide: true },
      // { term: i18n('Conversion'), def: store.conversion + '%', widest: true },
    ]
  }

  return (
    <HomeCard to={'performance-details'} focused={focused} handleContentScroll={handleOnScroll}
      icon={defaultAvatar} title={i18n('My performance')}
      description={i18n('Weekly performance metrics for eyewa core business. Tracking eye tests, conversions and orders.')}>
      <div>
        {/* <Button onClick={() => setIsTotalView(prev => !prev)} className={styles.viewToggle}>{isTotalView ? 'View store' : 'View total'}</Button> */}
        <div className={styles.tabularSummaryWrapper}>
          {data.map((store, index) => (
            <TabularSummary
              key={index}
              title={store.store_code}
              items={tableItems(store)}
              isMetricsView
            />
          ))}
          {/* <div className={cx(styles['down-arrow'], { [styles.fade]: hasScrolled } )} /> */}
        </div>
      </div>
    </HomeCard>
  )
}

PerformanceCard.propTypes = {}

export default PerformanceCard
