import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './UpcomingAppointments.module.scss'
import Events from 'components/events/Events'
import useI18n from 'common-hooks/useI18n'
import { Link } from 'react-router-dom'
import availabilityIcon from './availability.png'
import useStore from 'common-hooks/useStore'
import Skeleton from 'components/skeleton/Skeleton'

const ARROW_RIGHT = <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M0.779297 10.7402L5.75977 5.75977L0.779297 0.779297" stroke="#31395F" strokeOpacity="0.5" strokeWidth="1.40625" strokeLinecap="round" strokeLinejoin="round"/> </svg>

const mockEvents = [
  {
    day: 'Wednesday, 16 November 2022',
    events: [
      {
        "fromDate": "09:00",
        "toDate": "09:30",
        "status": "BOOKED",
        "appointmentId": 28,
        "storeId": 2,
        "createdDate": "14 November 2022",
        "note": "tesing2",
        "isPast": false,
        "customer": {
            "email": "Bader.ksa.51-test@abcxyz123-gmail.com",
            "name": "بدر التميمي",
            "gender": 'Female',
            "dob": '12 December 1987'
        }
      },
      {
          "fromDate": "10:00",
          "toDate": "10:30",
          "status": "BOOKED",
          "appointmentId": 27,
          "storeId": 2,
          "createdDate": "14 November 2022",
          "note": "tesing",
          "isPast": true,
          "customer": {
              "email": "alkhemeiri-test@abcxyz123-outlook.com",
              "name": "Mohamed Alkhemeiri",
              "gender": null,
              "dob": null
          }
      }
    ]
  }
]

function UpcomingAppointments() {
  const { i18n } = useI18n();
  const {label: store} = useStore();
  const events = mockEvents;

  return (
    <div className={cx(styles.wrapper)}>
      {!!events.length && (
        <>
          <div className={cx(styles.header)}>
            <h2 className={cx(styles.title)}>{i18n('Upcoming Appointments')}</h2>
            <Link to="/scheduled-appointments" className={cx(styles.cta)}>{i18n('See All')}{ARROW_RIGHT}</Link>
          </div>
          <Events events={events} />
        </>
      )}
      <Link to="/availability/add" className={cx(styles.footer)}>
        <img className={cx(styles.image)} src={availabilityIcon} alt="" />
        <span className={cx(styles.message)}>
          <span className={cx(styles['to-store-message'])}>{i18n('Set new availability your current store')}</span>
          <span className={cx(styles.store)}>{store || <Skeleton />}</span>
        </span>
        {ARROW_RIGHT}
      </Link>
    </div>
  )
}

UpcomingAppointments.propTypes = {}

export default UpcomingAppointments
