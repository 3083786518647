import React from 'react'
import styles from './Warehouses.module.scss'
import cx from 'classnames'
import useI18n from 'common-hooks/useI18n'
import useWarehouses from './useWarehouses'
import { useTable } from 'react-table'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { FilterCard } from 'components'
import FilterBar from 'components/filter-card/FilterBar'
import { useSelector } from 'react-redux'
import { selectUser } from 'features/login/loginSlice'
import { ROLE_TYPES } from 'pages/users/Roles'

export default function Warehouses() {
  const { i18n } = useI18n();
  const { items, columns, filters, setFilters } = useWarehouses();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: items });
  const navigate = useNavigate();
  const { roleList } = useSelector(selectUser);
  const showCreateStoreButton = roleList?.[0]?.roleName?.includes(ROLE_TYPES.ADMIN);

  const redirectToWarehouse = (id) => {
    navigate(`${id}`);
  }

  return (
    <div className={cx(styles.wrapper)}>
      <div className={styles.heading}>
        <h1>{i18n('Stores')}</h1>
        {showCreateStoreButton && <Link to={'new'} className={cx(styles.link)}>{i18n('Create store')}</Link>}
      </div>
      <FilterBar heading={i18n('Search')} onChange={setFilters} searchPlaceholder={i18n('Code, name, phone, address, city, site, country, area')} useSearchOnly />
      <div className={styles.tableHeadFixed}>
        <table {...getTableProps()}>
          <thead className={styles.tableHeaderRow}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className={styles.tableRow} onClick={() => redirectToWarehouse(row.original.id)}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}> {cell.render('Cell')} </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
