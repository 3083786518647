import React, { useContext } from 'react'
import { OrderDetailsContext } from './order-details/OrderDetails'
import useI18n from 'common-hooks/useI18n';
import cx from 'classnames'
import styles from './order-details/OrderDetails.module.scss'
import NoDataAvailable from './NoDataAvailable';
import { InfoCard } from 'components';
import { convertDataForInfoCard } from './utils';

function RefundDetails() {
  const { i18n } = useI18n();
  const { refundDetails = {}, isLoading } = useContext(OrderDetailsContext);

  if (isLoading) return
  if (!refundDetails.data && !isLoading) return <NoDataAvailable />
  if (refundDetails.data && refundDetails.data.length <= 0 && !isLoading) return <NoDataAvailable />
  return (
    <div className={cx(styles.section)}>
      <h4>{i18n('Refund details')}</h4>
      {refundDetails?.data?.map(rd => {
        return (
          <div key={rd.id}>
            <InfoCard data={convertDataForInfoCard(rd)} />
          </div>
        )
      })}
    </div>
  );
}

export default RefundDetails
