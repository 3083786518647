import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './FormElements.module.scss'
import { useId } from 'react'
import Skeleton from '../skeleton/Skeleton'
import EyeClosed from './../../icons/Eye_Closed.svg'
import EyeOpen from './../../icons/Eye_Open.svg'

function PasswordInput({ label, name, className, onChange, min, max, value, onBlur, onFocus, error, required, autoFocus, loading, step, placeholder, disabled }) {
  const id = useId();
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordVisible = () => {
    setShowPassword(!showPassword);
  }

  return (
    <div className={cx(styles.field, className, { [styles.error]: error, [styles.disabled]: disabled })}>
      <label className={cx(styles.label)} htmlFor={id}>{label}</label>
      {loading ? <Skeleton type="input" /> : (
        <div className={styles.inputWrapper}>
          <input min={min} max={max} step={step} autoFocus={autoFocus} required={required} onChange={onChange} value={value} onBlur={onBlur} onFocus={onFocus} className={cx(styles.input)} id={id} name={name} type={showPassword ? "text" : "password"} placeholder={placeholder} disabled={disabled} />
          <img src={showPassword ? EyeClosed : EyeOpen} className={cx(styles.icon)} onClick={handlePasswordVisible} />
        </div>
      )}
      {error && <label htmlFor={id} className={cx(styles.message)}>{error}</label>}
    </div>
  )
}

PasswordInput.defaultProps = {
  type: 'text',
  value: ''
}

PasswordInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'email', 'tel', 'date']),
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  loading: PropTypes.bool,
  step: PropTypes.string,
  placeholder: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
}

export default PasswordInput
