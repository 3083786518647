import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './TabularSummary.module.scss'
import { Link } from 'react-router-dom'
import GreenArrow from './green-arrow.png'
import RedArrow from './red-arrow.png'

function TabularSummary({ title, items, className, to, toState, footer, isMetricsView, grid2 }) {
  const content = !isMetricsView ? (
    <>
      <h2 className={cx(styles.title)}>{title}</h2>
      <ul className={cx(styles.list)}>
        {items.map(item => (
          <li className={cx(styles.item)} key={item.term}>
            <strong className={cx(styles.term)}>{item.term}</strong>
            <span className={cx(styles.def, { [styles.highlight]: item.highlight })}>{item.def}</span>
          </li>
        ))}
      </ul>
      {footer}
    </>
  ) : (
    <div className={grid2 ? styles.grid2 : styles.grid}>
      {items.map(item => (
        <div className={cx(grid2 ? styles.gridItem2 : styles.gridItem, { [styles.wide]: item.wide }, { [styles.widest]: item.widest })} key={item.term}>
          <div>
            <h2 className={cx(styles.title)}>{item.term}</h2>
            <div className={styles.metric}>
              {/* up arrow or down arrow */}
              {item?.change && <img src={item?.change?.growth === 'positive' ? GreenArrow : RedArrow} alt='Growth' className={styles.growthArrow} />}
              <div>{item.def}</div>
            </div>
          </div>
          {item?.change &&
            <div className={styles.changeLabel}>
              {item?.change?.percent}{item?.change?.label}
            </div>
          }
        </div>
      ))}
    </div>
  )
  return to ? (
    <Link className={cx(styles.wrapper, className)} to={to} state={toState}>
      {content}
    </Link>
  ) : (
    <div className={cx(styles.wrapper, className, { [styles['no-bg']]: isMetricsView })}>
      {content}
    </div>
  )
}

TabularSummary.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    term: PropTypes.string,
    def: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.string]),
    highlight: PropTypes.bool
  })),
  toState: PropTypes.object,
  isMetricsView: PropTypes.bool,
}

export default TabularSummary
