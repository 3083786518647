import React, { useState } from 'react';
import { Button, FormSectionTitle, Input, SimpleSelect } from 'components';
import { useOutletContext } from 'react-router-dom';
import styles from './../Questionnaire.module.scss';
import { validate } from './validations';

const Step1 = () => {
  const [formData, handleNext] = useOutletContext();
  const [stepData, setStepData] = useState(formData.step1);
  const [validation, setValidation] = useState({});

  const handleChange = (e) => {
    setStepData({ ...stepData, [e.target.name]: e.target.value });
    setValidation(prev => ({...prev, [e.target.name]: ''}))
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let validation = validate(stepData, 1)
    if(validation) {
      setValidation(validation)
      return
    }
    handleNext(stepData, 1);
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormSectionTitle>{'Current Status: '}</FormSectionTitle>
      <div className={styles.questionContainer}>
        <SimpleSelect options={[
          { label: 'Glasses', value: 'Glasses'},
          { label: 'Contact Lenses', value: 'Contact Lenses'},
          { label: 'Both', value: 'Both'},
          { label: 'None', value: 'None'},
        ]} 
        label={'Currently using glasses or contact lens?'} 
        onChange={val => handleChange({
          target: {
            name: 'useGlasses',
            value: val
          }
        })} 
        value={stepData['useGlasses']}
        error={validation['useGlasses']}
        />
      </div>
      <Button type='submit'>{'Next'}</Button>
    </form>
  );
};

export default Step1;